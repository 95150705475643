import styled, { css } from "styled-components";
import { Color, getTxtColorByBkg, device, deviceMax } from "../utils/variables";

const defaultPadding = 20;

const rdmColors = [
  "blue",
  "gold",
  "green",
  "magenta",
  "red",
  "red",
  "yellow",
  "purple",
  "teal",
  "navy",
];

const setPadding = (padding) =>
  padding.toString().length && padding !== true ? padding : defaultPadding;

export const Common = styled.div`
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
      width: 100%;
    `};
  position: ${(props) =>
    props.fixed
      ? "fixed"
      : props.absolute
      ? "absolute"
      : props.relative
      ? "relative"
      : "static"};
  display: inline-flex;
  color: ${(props) =>
    props.color ? Color(props.color) : getTxtColorByBkg(props.bkg, props.fade)};
  ${(props) =>
    props.bkg &&
    css`
      background-color: ${Color(props.bkg, props.fade)};
    `};
  ${(props) =>
    props.randomBkg &&
    css`
      background-color: ${Color(
        rdmColors[Math.floor(Math.random() * rdmColors.length)],
        props.fade
      )};
    `};
  ${(props) =>
    props.border &&
    css`
      border: ${props.borderSize || 1}px solid
        ${Color(
          props.border,
          props.borderFade
            ? props.borderFade
            : props.fade
            ? props.fade
            : "default"
        )};
    `};
  ${(props) =>
    props.radius &&
    css`
      border-radius: ${props.radius}px;
      overflow: hidden;
    `};
  ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap}px;
    `}
  ${(props) =>
    props.paddingAll &&
    css`
      padding: ${setPadding(props.paddingAll)}px;
    `}
  ${(props) =>
    props.paddingTop &&
    css`
      padding-top: ${setPadding(props.paddingTop)}px;
    `};
  ${(props) =>
    props.paddingLeft &&
    css`
      padding-left: ${setPadding(props.paddingLeft)}px;
    `};
  ${(props) =>
    props.paddingRight &&
    css`
      padding-right: ${setPadding(props.paddingRight)}px;
    `};
  ${(props) =>
    props.paddingBottom &&
    css`
      padding-bottom: ${setPadding(props.paddingBottom)}px;
    `};
  ${(props) =>
    props.paddingX &&
    css`
      padding-left: ${setPadding(props.paddingX)}px;
      padding-right: ${setPadding(props.paddingX)}px;
    `};
  ${(props) =>
    props.paddingY &&
    css`
      padding-top: ${setPadding(props.paddingY)}px;
      padding-bottom: ${setPadding(props.paddingY)}px;
    `};
  ${(props) =>
    props.marginAll &&
    css`
      margin: ${setPadding(props.marginAll)}px;
    `};
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${setPadding(props.marginTop)}px;
    `};
  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${setPadding(props.marginLeft)}px;
    `};
  ${(props) =>
    props.marginRight &&
    css`
      margin-right: ${setPadding(props.marginRight)}px;
    `};
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${setPadding(props.marginBottom)}px;
    `};
  ${(props) =>
    props.marginX &&
    css`
      margin-left: ${setPadding(props.marginX)}px;
      margin-right: ${setPadding(props.marginX)}px;
    `};
  ${(props) =>
    props.marginY &&
    css`
      margin-top: ${setPadding(props.marginY)}px;
      margin-bottom: ${setPadding(props.marginY)}px;
    `};
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
  ${(props) =>
    props.flexWrap &&
    css`
      flex-wrap: wrap;
    `};

  ${(props) =>
    props.hide &&
    css`
      @media ${device[props.hide]} {
        display: none;
      }
    `};

  ${(props) =>
    props.show &&
    css`
      display: none;
      @media ${device[props.show]} {
        display: flex;
      }
    `};

  ${(props) =>
    props.bkgImage &&
    css`
      background-size: cover;
      background-image: url(${props.bkgImage});
    `};

  &.hover {
    cursor: pointer;
  }
`;

export const Row = styled(Common)`
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  ${({ onClick }) => onClick && `cursor: pointer;`};
  ${({ expand }) => expand && `width: 100%;`};
  ${({ fit }) => fit && `flex: 1 1 auto;`};
  ${({ noFlex }) => noFlex && `flex: 0 1 auto;`};
  ${({ noShrink }) => noShrink && `flex-shrink: 0;`};
  ${({ top }) => top && "align-items: flex-start"};
  ${({ middle }) => middle && "align-items: center"};
  ${({ bottom }) => bottom && "align-items: flex-end"};
  ${({ left }) => left && "justify-content: flex-start"};
  ${({ center }) => center && "justify-content: center"};
  ${({ right }) => right && "justify-content: flex-end"};
  ${({ spaceBetween }) => spaceBetween && "justify-content: space-between"};
  ${({ truncate }) =>
    truncate &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `};

  ${({ toColumn }) =>
    toColumn &&
    css`
      @media ${deviceMax[toColumn]} {
        flex-direction: column;
      }
    `};
`;

export const Column = styled(Common)`
  flex-direction: column;
  ${({ fit }) => fit && "flex: 1 1 auto;"};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
  ${({ maxHeight }) => maxHeight && "height: 100%"};
  ${({ expand }) => expand && "min-height: 100vh"};
  ${({ top }) => top && "justify-content: flex-start"};
  ${({ middle }) => middle && "justify-content: center"};
  ${({ bottom }) => bottom && "justify-content: flex-end"};
  ${({ spaceBetween }) => spaceBetween && "justify-content: space-between"};
  ${({ left }) => left && "align-items: flex-start"};
  ${({ center }) => center && "align-items: center"};
  ${({ right }) => right && "align-items: flex-end"};
  ${({ stretch }) => stretch && "width: 100%"};
  ${({ scroll }) => scroll && "overflow-y: auto"};
  ${({ noScroll }) => noScroll && "overflow: hidden"};
  ${({ shadow }) => shadow && "box-shadow: 0px 2px 2px #CCCDCF"};
  ${({ radiusBottom }) =>
    radiusBottom && `border-radius: 0 0 ${radiusBottom}px ${radiusBottom}px`};
`;

export const Circle = styled(Column)`
  display: inline-flex;
  flex-direction: column;
  height: ${(props) =>
    props.radius &&
    css`
      ${Number(props.radius) * 2}px
    `};
  width: ${(props) =>
    props.radius &&
    css`
      ${Number(props.radius) * 2}px
    `};
  border-radius: ${(props) =>
    props.radius &&
    css`
      ${props.radius}px
    `};
  border: ${(props) =>
    props.border && props.fade
      ? `1px solid ${Color(props.border, props.fade)}`
      : "none"};
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  ${(props) =>
    props.radius &&
    css`
      overflow: hidden;
    `};
  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    `};
`;

export const AppWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export const MainWrapper = styled.div`
  height: 100vh;
  width: 100%;
  min-width: 375px;
  display: grid;
  grid-template-rows: 63px 1fr;

  ${(props) =>
    props.drawer
      ? `
      grid-template-areas:
        "header"
        "content"
        "footer";
        grid-template-columns: 1fr;

      @media ${device.tabletL} {
        grid-template-areas:
        "header header"
        "drawer content"
        "footer footer";
        grid-template-columns: 300px 1fr;
      }
    `
      : `
      grid-template-areas:
      "header"
      "content"
      "footer";
      grid-template-columns: 1fr;
    `};

  ${(props) =>
    props.editMode &&
    `
      grid-template-areas:
        "content";
      grid-template-rows: 1fr;
      height: auto;

      ${Content} {
        padding: 0 20px;
        overflow: hidden;

        & > div {
          height: unset
        }
      }
  `};

  ${({ noHeader }) =>
    noHeader &&
    css`
      display: flex;

      ${Content} {
        width: 100%;
      }
    `};

  ${({ footer }) =>
    footer &&
    css`
      ${Content} {
        overflow: visible;
      }
    `};
`;

export const Card = styled(Column)`
  background: white;
  overflow: ${({ noOverflow }) => (noOverflow ? "unset" : "hidden")};
  width: 100%;
  border-radius: ${({ radius }) => (radius ? `${radius}px ` : "10px")};
  padding: ${(props) => props.paddingAll || "20px"};
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${Color("gray", "4")};
    `};
  ${(props) =>
    props.medium
      ? `max-width: 600px`
      : props.small
      ? `max-width: 350px`
      : `max-width: 100%`};

  &.clickable {
    &:hover {
      background: ${Color("gray", "0.5")};
      border-color: ${Color("gray", "7")};
    }

    &:focus {
      background: ${Color("green", "1")};
      border-color: ${Color("gray", "7")};
    }
  }

  & + & {
    margin-top: 15px;
  }
`;

export const CardBody = styled(Column)`
  padding: 20px 0 40px;

  @media ${device["mobileXL"]} {
    padding: 20px 100px 40px;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  gap: 20px;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;

  /* @media ${device.tabletL} {
    max-width: 100%;
  }
  @media ${device.laptopL} {
    max-width: 1140px;
  } */

  /* ${(props) =>
    props.tabs &&
    `
    margin-top: 25px;
    max-width: 1000px;
  `}; */

  /* &.one-column {
    max-width: 1010px;
  } */
`;

export const Content = styled.div`
  grid-area: content;
  overflow: auto;
  padding: ${({ paddingAll }) =>
    paddingAll ? `${paddingAll}px` : "20px 20px 40px"};
  top: 20px;
  ${(props) =>
    props.paddingTop &&
    css`
      padding-top: ${props.paddingTop}px;
    `};
  ${(props) =>
    props.paddingBottom &&
    css`
      padding-bottom: ${props.paddingBottom}px;
    `};
  ${(props) =>
    props.tabs
      ? `padding-top: 140px`
      : props.large
      ? `padding-top: 210px`
      : ""};
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.noScroll &&
    `
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
  `};
  ${(props) => props.center && "align-items: center"};
  ${(props) => props.middle && "justify-content: center"};
  ${({ bkg, fade }) =>
    bkg &&
    css`
      background-color: ${Color(bkg, fade)};
    `};

  & > div {
    width: 100%;
    max-width: ${(props) => props.maxWidth || "980px"};
    margin: 0 auto;
    flex: 1;
  }
`;

export const ContentGrid = styled.div`
  gap: 40px;
  display: flex;
  flex-direction: column;

  @media ${device.laptopL} {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }

  ${(props) =>
    props.reponsiveMargin &&
    `
      & > *:last-child {
        margin: ${props.reponsiveMargin};
        @media ${device.laptopL} {
          margin: 0;
        }
      }
    `};
`;

export const RowToColumn = styled(Column)`
  width: 100%;
  ${(props) =>
    props.columnGap &&
    css`
      gap: ${props.columnGap}px;
    `}
  ${(props) =>
    `@media ${device[props.device || "tabletL"]} {
      flex-direction: row;
      ${props.middle && "align-items: center"};
      ${props.left && "justify-content: flex-start"};
      ${props.top && "align-items: flex-start"};
      ${props.gap && `gap: ${props.gap}px;`}
  `}
`;

export const Grid = styled(Column)`
  display: grid;
  width: 100%;
  max-width: 1010px;
  grid-template-columns: 1fr;
  gap: ${({ gap }) => (gap ? `${gap}px` : "10px")};
  ${({ middle }) => middle && "align-items: center"};

  ${({ columns }) =>
    (!columns || columns > 1) &&
    css`
      @media ${device.tablet} {
        grid-template-columns: 1fr 1fr;
      }
    `}
  ${({ columns, jump3 }) =>
    (!columns || columns > 2) &&
    css`
      @media ${device[jump3] || device.laptop} {
        grid-template-columns: 1fr 1fr 1fr;
      }
    `}
  ${({ columns }) =>
    (!columns || columns > 3) &&
    css`
      @media ${device.laptopL} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    `}

  ${({ columns, always }) =>
    always &&
    columns === 2 &&
    css`
      grid-template-columns: 1fr 1fr;
      @media ${device.tablet}, ${device.laptop}, ${device.laptopL} {
        grid-template-columns: 1fr 1fr;
      }
    `}

  ${({ columns, always }) =>
    always &&
    columns === 3 &&
    css`
      grid-template-columns: 1fr 1fr 1fr;
      @media ${device.tablet}, ${device.laptop}, ${device.laptopL} {
        grid-template-columns: 1fr 1fr 1fr;
      }
    `}

  ${({ threeToOne }) =>
    threeToOne &&
    css`
      @media ${device.tablet} {
        grid-template-columns: 1fr 1fr 1fr;
      }
    `}
  
  ${({ fourToTwo }) =>
    fourToTwo &&
    css`
      @media ${device.laptop} {
        grid-template-columns: 1fr 1fr;
      }
      @media ${device.laptopL} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    `}
`;

export const HR = styled.hr`
  border-color: ${(props) =>
    props.bkg
      ? Color(props.bkg, props.fade ? props.fade : "default")
      : Color("gray", "4")};
  border-width: 0;
  border-bottom-width: 1px;
  width: 100%;
  margin: 0;

  ${(props) =>
    props.divider &&
    `
      margin: 30px -30px;
      width: calc(100% + 50px);
      border: 5px solid ${Color("gray", 0.2)};
  `}
`;
