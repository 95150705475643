import { useEffect } from "react";
import { useSelector } from "react-redux";
import { view } from "./InsightsView";
import { logAnalyticEvent, getUrlParams } from "../../utils";
import { useDiseaseSummaries } from "../../store/queries/influencers";

export const DiseaseInsights = ({ signUpClick }) => {
  const queryParams = getUrlParams(location.search);

  const auth = useSelector((state) => state.auth.data);

  const {
    data: summaries,
    isLoading: loadingSummaries,
    isSuccess,
  } = useDiseaseSummaries();

  useEffect(() => {
    logAnalyticEvent(
      "Landing - Insights",
      {
        page: "Listing",
      },
      auth?.id || queryParams?.u || null,
      auth?.displayName || queryParams?.n || null,
      auth?.email || queryParams?.e || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue ||
        queryParams?.t ||
        "MEDICAL",
      null
    );
  }, []);

  return view({
    signUpClick,
    summaries: summaries?.data?.sort((a, b) => a.name.localeCompare(b.name)),
    loadingSummaries,
    isSuccess,
  });
};
