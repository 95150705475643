import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";
import { objectToUrlEncoded } from "../../utils/constants";
import { MAX_RESULTS } from "../../utils/variables";

export function getConference(id, nocache) {
  if (!id) {
    return Promise.reject(new Error("id is required"));
  }
  const url = `${getApiPath(false)}/conference/${id}?${Date.now()}${
    nocache ? "&nocache=true" : ""
  }`;
  return axios.get(url, {});
}

export function useGetConference(id, nocache) {
  return useQuery([`conference-${id}`], () => getConference(id, nocache), {
    refetchOnMount: false,
    cacheTime: 300000,
  });
}

export function getConferenceDols(data) {
  const {
    id,
    userId,
    startDate,
    endDate,
    accountType,
    accountSubType,
    regionName,
    addTagIds,
  } = data;

  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  const params = objectToUrlEncoded({
    userId,
    startDate,
    endDate,
    accountType: accountType || "PERSON",
    accountSubType: accountSubType,
  });

  const url = `${getApiPath(
    false
  )}/conference/${id}/dols?${tagIdList}${regionList}${params}`;
  return axios.get(url, {});
}

export function useGetConferenceDols(data) {
  return useQuery(
    [`conference-dols-${data.id}`],
    () => getConferenceDols(data),
    {
      refetchOnMount: false,
      cacheTime: 300000,
    }
  );
}
