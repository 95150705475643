import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty, cloneDeep } from "lodash";
import moment from "moment";

import useDebounce from "../../../utils/useDebounce";
import {
  conferenceActions,
  tagActions,
  userActions,
} from "../../../store/actions";
import { view } from "./EditConferenceView";
import { MAX_RESULTS } from "../../../utils/variables";
import { getISOFormattedDateTimeString } from "../../../utils";

const errorsMessage = {
  name: "Required field",
};

export const EditConference = () => {
  const dispatch = useDispatch();
  const { id, params, parentId } = useParams();

  const conference = useSelector((state) => state.conference.one.data);
  const conferences = useSelector((state) => state.conference.all.data);
  const initialTags = useSelector((state) => state.tag.all); // all tags in system
  // initial tags which are whitelisted
  const whitelist = useSelector((state) => state.tag.whitelist.data);
  const usersSubscribed = useSelector((state) => state.user.all.data);

  const [isSavePressed, setIsSavePressed] = useState(false);
  const [errors, setErrors] = useState({});
  const [deleteModal, toggleDeleteModal] = useState(false);

  // tailored list of mergable tags
  const [mergableTags, setMergableTags] = useState([]);
  // merged tags
  const [mergedTags, setMergedTags] = useState([]);
  const [searchMergableTag, setSearchMergableTag] = useState("");
  const debounceMergableTag = useDebounce(searchMergableTag, 700);

  const [pageTags, setPageTags] = useState(0);

  const [data, setData] = useState({
    primaryName: "",
    tag: {
      alwaysMatch: true,
      alwaysIgnore: false,
    },
    description: null,
    url: null,
    parentId: null,
    meaning: "CONFERENCE",
    visible: false,
  });

  // get tags paginated
  const requestTags = (pageNum) => {
    dispatch(
      tagActions.request({
        meaning: null,
        ignore: false,
        match: null,
        pageNum: pageNum || 0,
        preferred: true,
        maxResult: MAX_RESULTS,
        includeProducts: false,
        includeDiseases: false,
        includeConferences: false,
        ...(searchMergableTag !== "" && { term: searchMergableTag }),
      })
    );
  };

  const requestUsersSubscribed = (id) => {
    if (id) {
      dispatch(
        userActions.request({
          monitorObjectId: id,
          maxResult: -1,
        })
      );
    }
  };

  useEffect(() => {
    if (conference && id) {
      setData({
        id: conference?.id,
        primaryName: conference?.primaryName,
        description: conference?.description,
        url: conference?.url,
        parentId: conference?.parentId,
        meaning: conference?.meaning,
        startDate: moment(conference?.startDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(conference?.endDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        monitorStartTime: moment(conference?.monitorStartTime)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        monitorEndTime: moment(conference?.monitorEndTime)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        tag: conference?.tag,
        visible: conference?.visible,
      });
    }
  }, [conference]);

  useEffect(() => {
    requestTags(0);
  }, [debounceMergableTag]);

  useEffect(() => {
    if (!data.startDate) {
      setData((oldData) => ({
        ...oldData,
        parentId,
        startDate: getISOFormattedDateTimeString(
          moment().isoWeekday(1).startOf("day")
        ),
        endDate: getISOFormattedDateTimeString(
          moment().isoWeekday(1).add(6, "days").endOf("day")
        ),
        monitorStartTime: getISOFormattedDateTimeString(
          moment().isoWeekday(1).add(-1, "days").startOf("day")
        ),
        monitorEndTime: getISOFormattedDateTimeString(
          moment().isoWeekday(1).add(7, "days").endOf("day")
        ),
      }));
    }
  }, [parentId]);

  useEffect(() => {
    return () => {
      dispatch(conferenceActions.clear());
      dispatch(conferenceActions.clearTags());
      dispatch(tagActions.clearTags());
      dispatch(userActions.clearAll());
    };
  }, []);

  useEffect(() => {
    if (id) {
      // get the conference
      dispatch(conferenceActions.oneRequest(id, true));
    }
  }, [dispatch]);

  useEffect(() => {
    if (initialTags?.data?.length > 0) {
      let newTags = initialTags.data.map((item) => ({
        ...item,
        label: item.name,
      }));
      // exclude self
      newTags = newTags.filter((k) => k.id !== data.tag?.id);

      // list for mergable drop down
      let finalTags = cloneDeep(newTags);
      // remove blacklisted
      // finalTags = finalTags.filter((k) => !k.alwaysIgnore);
      // exclude tags which are substitutes to others
      finalTags = finalTags.filter((k) => !k.substitutedIds);
      // exclude tags which are whitelisted
      finalTags = finalTags.filter((k) => !k.preferredTagId);
      // exclude tags which are products
      finalTags = finalTags.filter((k) => !k.productId);
      // exclude tags which are diseases
      finalTags = finalTags.filter((k) => !k.diseaseId);
      // exclude tags which are conferences
      finalTags = finalTags.filter((k) => !k.conferenceId);
      // console.log("after => ", finalTags)
      setMergableTags(finalTags);
    } else {
      setMergableTags([]);
    }
  }, [initialTags]);

  useEffect(() => {
    if (whitelist?.length > 0) {
      const whitelistedTags = whitelist.map((tag) => ({
        ...tag,
        label: tag.name,
      }));
      setMergedTags(whitelistedTags);
    }
  }, [whitelist]);

  useEffect(() => {
    checkErrors();
  }, [data]);

  useEffect(() => {
    if (data.tag?.id) {
      updateTags();
    }
  }, [data.tag?.id]);

  useEffect(() => {
    // check for associated conferences
    if (!_.isEmpty(conference) && conference?.associatedConferences) {
      // fetch all associated
      dispatch(
        conferenceActions.request({
          parentId: conference.id,
          maxResult: -1,
          nocache: true,
        })
      );
      // if conference found, fetch users who have subscribed for
      requestUsersSubscribed(conference.id);
    }
  }, [conference]);

  const checkErrors = () => {
    let newErrors = {};
    if (data.primaryName === "") {
      newErrors.name = errorsMessage.name;
    }
    setErrors(newErrors);
  };

  // add merged (aka substituted) tags
  const addMergedTags = (item) => {
    const clonedTags = cloneDeep(mergedTags);
    setMergedTags([...clonedTags, item]);
    dispatch(
      tagActions.whitelistUpdate({ id: item.id, preferredTagId: data.tag.id })
    );

    setData((oldData) => ({
      ...oldData,
      alwaysMatch: true,
    }));
  };

  // remove merged (aka substituted) tags
  const removeMergedTags = (item) => {
    const clonedTags = cloneDeep(mergedTags);
    setMergedTags(clonedTags.filter((i) => i.id !== item.id));

    dispatch(tagActions.whitelistUpdate({ id: item.id, preferredTagId: null }));
  };

  const updateTags = () => {
    // get all tags which are merged or substituted
    dispatch(
      tagActions.whitelistRequest({
        ids: [data.tag.id],
        nocache: true,
      })
    );
  };

  const createMergedTag = () => {
    console.log(searchMergableTag);
    // create merged tag
    dispatch(
      tagActions.create(
        {
          name: searchMergableTag,
          meaning: "CONFERENCE",
          preferredTagId: data.tag.id, // substiute tag id
          alwaysMatch: true,
          alwaysIgnore: false,
        },
        false
      )
    );
    // refresh list
    setTimeout(() => {
      updateTags();
    }, 1000);
  };

  const handleChange = ({ value, field }) => {
    setData((oldData) => ({
      ...oldData,
      [field]: value,
    }));

    setErrors((oldError) => ({
      ...oldError,
      [field]: value === "",
    }));
  };

  // create
  const handleSave = () => {
    setIsSavePressed(true);
    if (isEmpty(errors)) {
      dispatch(
        conferenceActions.create(
          data,
          parentId ? `/editConference/${parentId}` : `/admin/conferences`
        )
      );
    }
  };

  // delete
  const handleDelete = (conferenceId) => {
    dispatch(
      tagActions.delete({
        id: conferenceId || data?.tag.id,
        type: "conferences",
      })
    );
  };

  // update on name blur
  const handleNameOnBlur = () => {
    if (id && isEmpty(errors)) {
      dispatch(conferenceActions.update(data));
    }
  };
  // update on description blur
  const handleDescriptionOnBlur = () => {
    if (id && isEmpty(errors)) {
      dispatch(conferenceActions.update(data));
    }
  };
  // update on name blur
  const handleUrlOnBlur = () => {
    if (id && isEmpty(errors)) {
      dispatch(conferenceActions.update(data));
    }
  };

  // update status
  const handleSwitchChange = ({ value, field }) => {
    const newData = {
      ...data,
      tag: {
        ...data.tag,
        [field]: value,
      },
    };
    setData(newData);
    id && dispatch(conferenceActions.update(newData));
  };

  const handleDateChange = (e) => {
    if (id) {
      if (isEmpty(errors)) {
        const newData = {
          ...data,
          startDate: getISOFormattedDateTimeString(e.startDate),
          endDate: getISOFormattedDateTimeString(e.endDate),
        };
        setData(newData);
        dispatch(conferenceActions.update(newData));
      }
    } else {
      const newData = {
        ...data,
        startDate: getISOFormattedDateTimeString(e.startDate),
        endDate: getISOFormattedDateTimeString(e.endDate),
      };
      setData(newData);
    }
  };

  const handleMonitorDateChange = (e) => {
    if (id) {
      if (isEmpty(errors)) {
        const newData = {
          ...data,
          monitorStartTime: getISOFormattedDateTimeString(e.startDate),
          monitorEndTime: getISOFormattedDateTimeString(e.endDate),
        };
        setData(newData);
        dispatch(conferenceActions.update(newData));
      }
    } else {
      const newData = {
        ...data,
        monitorStartTime: getISOFormattedDateTimeString(e.startDate),
        monitorEndTime: getISOFormattedDateTimeString(e.endDate),
      };
      setData(newData);
    }
  };

  const handleSearchMergableTag = (e) => {
    setSearchMergableTag(e);
  };

  const handleMoreTags = () => {
    if (!initialTags.fetching) {
      const tagsNewPage = pageTags + 1;
      setPageTags(tagsNewPage);
      requestTags(tagsNewPage);
    }
  };

  const handleVisibleChange = (e) => {
    const newData = {
      ...data,
      visible: e,
    };
    setData(newData);
    id && dispatch(conferenceActions.update(newData));
  };

  return view({
    editMode: params === "editMode",
    parentId,
    errors,
    handleSave,
    data,
    handleChange,
    handleSwitchChange,
    isSavePressed,
    isNew: !id,
    deleteModal,
    toggleDeleteModal,
    handleDelete,
    handleNameOnBlur,
    handleDescriptionOnBlur,
    handleUrlOnBlur,
    handleDateChange,
    handleMonitorDateChange,

    childConferences: conferences?.filter((k) => k.parentId) || [], // will include child conferences

    usersSubscribed,

    mergableTags, // tags which can be merged
    mergedTags, // tags which are already merged
    addMergedTags,
    removeMergedTags,
    searchMergableTag,
    handleSearchMergableTag,
    tagsInfinteScroll: {
      loading: initialTags.fetching,
      hasNextPage: !initialTags.isLastPage,
      onLoadMore: handleMoreTags,
    },
    createMergedTag,
    handleVisibleChange,
  });
};
