import React from "react";
import {
  Row,
  Column,
  MainWrapper,
  Header,
  Content,
  Card,
  Label,
  Filters,
  Input,
  Dropdown,
  List,
  ListItem,
  Grid,
  Spinner,
  Anchor,
} from "../../../components";

import { Menu } from "../Menu";

const Group = ({ label, value, link }) => (
  <>
    {value && (
      <Column gap="2" marginBottom="5">
        <Row>
          <Label small color="gray" fade="7">
            {label}
          </Label>
        </Row>
        {link ? (
          <Anchor
            label={value}
            // color="blue"
            blank
            to={link}
          />
        ) : (
          <Label small bold>
            {value}
          </Label>
        )}
      </Column>
    )}
  </>
);

export const view = ({
  studies,

  loading,
  activeFilters,
  condition,
  setCondition,

  overallStatuses,
  overallStatus,
  handleOverallStatusChange,

  phases,
  phase,
  handlePhaseChange,

  country,
  setCountry,
}) => {
  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
          </>
        }
      />
      <Menu active="studies" />

      <Content>
        <Column>
          <Column marginBottom>
            <Filters {...{ activeFilters }}>
              <Grid columns={3}>
                <Column gap="10">
                  <Label bold>Condition</Label>
                  <Input
                    small
                    placeholder="Search"
                    icon="search"
                    value={condition}
                    onChange={(e) => setCondition(e.target.value)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Overall Status</Label>
                  <Dropdown
                    expand
                    select
                    value={overallStatus}
                    items={overallStatuses}
                    onChange={(e) => handleOverallStatusChange(e.id)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Phase</Label>
                  <Dropdown
                    expand
                    select
                    value={phase}
                    items={phases}
                    onChange={(e) => handlePhaseChange(e.id)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Country</Label>
                  <Input
                    small
                    placeholder="e.g., United States"
                    icon="search"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </Column>
              </Grid>
            </Filters>
          </Column>

          <Column paddingBottom="40" maxWidth="990">
            {studies.map((study, i) => (
              <Card border key={study.nctId}>
                <Row middle gap="20" marginBottom="10" marginTop="-5">
                  <Row gap="10">
                    <Group label="briefSummary" value={study.briefSummary} />
                  </Row>
                </Row>
                <Row middle gap="20" marginBottom="10" marginTop="-5">
                  <Row gap="10">
                    <Group label="detailedDescription" value={`${study.detailedDescription || ''}`} />
                  </Row>
                </Row>
                <Row middle gap="20" marginBottom="10" marginTop="-5">
                  <Row gap="10">
                    <Group label="officialTitle" value={study.officialTitle} />
                  </Row>
                </Row>

                <Row marginTop>
                  <Label bold>Identification</Label>
                </Row>
                <hr/>
                <Grid columns={3} marginTop>
                  <Group label="Id" value={study.nctId} link={`https://clinicaltrials.gov/study/${study.nctId}`} />
                  <Group label="acronym" value={study.acronym || "-"} />
                  <Group label="organizationFullName" value={study.organizationFullName || "-"} />
                  <Group label="organizationClass" value={study.organizationClass || "-"} />
                </Grid>

                <Row marginTop>
                  <Label bold>Status</Label>
                </Row>
                <hr/>
                <Grid columns={3} marginTop>
                  {/* <Group
                    label="statusVerifiedDate"
                    value={study.statusVerifiedDate}
                  /> */}
                  <Group
                    label="overallStatus"
                    value={study.overallStatus}
                  />
                  <Group
                    label="startDate"
                    value={study.startDate}
                  />
                  <Group
                    label="completionDate"
                    value={study.completionDate}
                  />
                  <Group
                    label="lastUpdateSubmitDate"
                    value={study.lastUpdateSubmitDate}
                  />
                </Grid>

                <Row marginTop>
                  <Label bold>Sponsor & Collaborators</Label>
                </Row>
                <hr/>
                <Grid columns={3} marginTop>
                  <Group label="responsiblePartyType" value={study.responsiblePartyType || "-"} />
                  <Group label="investigatorFullName" value={study.investigatorFullName || "-"} />
                  <Group label="investigatorTitle" value={study.investigatorTitle || "-"} />
                  <Group label="investigatorAffiliation" value={study.investigatorAffiliation || "-"} />
                  <Group label="leadSponsorName" value={study.leadSponsorName || "-"} />
                  <Group label="leadSponsorClass" value={study.leadSponsorClass || "-"} />
                </Grid>

                <Row marginTop>
                  <Label bold>Conditions & Keywords</Label>
                </Row>
                <hr/>
                <Grid columns={1} marginTop>
                  <Group label="conditions" value={study.conditions || "-"} />
                </Grid>
                <Grid columns={1} marginTop>
                  <Group label="keywords" value={study.keywords || "-"} />
                </Grid>

                <Row marginTop>
                  <Label bold>Phases</Label>
                </Row>
                <hr/>
                <Grid columns={3} marginTop>
                  <Group label="phases" value={study.phases || "-"} />
                  <Group label="studyType" value={study.studyType || "-"} />
                </Grid>

                {study.primaryOutcomes && (
                  <>
                    <Row marginTop>
                      <Label bold>Primary Outcomes</Label>
                    </Row>
                    <hr/>
                    {study.primaryOutcomes.map((outcome, i) => (
                      <Grid columns={3} marginTop key={`z-${i}`}>
                        <Group label="measure" value={outcome.measure || "-"} />
                        <Group label="description" value={outcome.description || "-"} />
                        <Group label="timeFrame" value={outcome.timeFrame || "-"} />
                      </Grid>
                    ))}
                    <br/>
                    {study.secondaryOutcomes && study.secondaryOutcomes.length > 1 && (
                      <Label small bold color="blue">Additional {study.secondaryOutcomes.length} secondary outcomes.</Label>
                    )}
                  </>
                )}
                {study.contacts && (
                  <>
                    <Row marginTop>
                      <Label bold>Contacts</Label>
                    </Row>
                    <hr/>
                    <Grid columns={3} marginTop>
                      <Group label="affiliation" value={study.contacts[0].affiliation || "-"} />
                      <Group label="name" value={study.contacts[0].name || "-"} />
                      <Group label="role" value={study.contacts[0].role || "-"} />
                    </Grid>
                    <br/>
                    {study.contacts.length > 1 && (
                      <Label small bold color="red">And {study.contacts.length-1} more contacts.</Label>
                    )}
                  </>
                )}

                {study.locations && (
                  <>
                    <Row marginTop>
                      <Label bold>Locations</Label>
                    </Row>
                    <hr/>
                    <Grid columns={4} marginTop>
                      <Group label="facility" value={study.locations[0].facility || "-"} />
                      <Group label="city" value={study.locations[0].city || "-"} />
                      <Group label="state" value={study.locations[0].state || "-"} />
                      <Group label="country" value={study.locations[0].country || "-"} />
                    </Grid>
                    {study.locations.length > 1 && (
                      <Grid columns={4} marginTop>
                        <Group label="facility" value={study.locations[1].facility || "-"} />
                        <Group label="city" value={study.locations[1].city || "-"} />
                        <Group label="state" value={study.locations[1].state || "-"} />
                        <Group label="country" value={study.locations[1].country || "-"} />
                      </Grid>
                    )}
                    {study.locations.length > 2 && (
                      <Grid columns={4} marginTop>
                        <Group label="facility" value={study.locations[2].facility || "-"} />
                        <Group label="city" value={study.locations[2].city || "-"} />
                        <Group label="state" value={study.locations[2].state || "-"} />
                        <Group label="country" value={study.locations[2].country || "-"} />
                      </Grid>
                    )}
                    <br/>
                    {study.locations.length > 1 && (
                      <Label small bold color="red">Total {study.locations.length} locations.</Label>
                    )}
                  </>
                )}
              </Card>
            ))}
          </Column>
          {loading ? (
            <Row expand paddingAll="40" center>
              <Spinner />
            </Row>
          ) : (
            <>
              {studies?.length === 0 && (
                <List center>
                  <ListItem>No Studies found</ListItem>
                </List>
              )}
            </>
          )}
        </Column>
      </Content>
    </MainWrapper>
  );
};
