import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

import useKeyPress from "../utils/useKeyPress";
import { navActions } from "../store/actions";
import { Button, Anchor } from "./Button";
import { Icon } from "./Icon";
import { Row, Column, Grid } from "./Layout";
import { H3, H5, Label } from "./Typography";
import { Dropdown } from "./Dropdown";
import { Input } from "./Input";
import { useGetSearch, useGetRelatedSearch } from "../store/queries/search";
import { Color, device, deviceMax, MAX_RESULTS } from "../utils/variables";
import { logAnalyticEvent } from "../utils";
import useDebounce from "../utils/useDebounce";
import { Avatar } from "./Avatar";
import { Spinner } from "./Spinner";
import { Helmet } from "react-helmet-async";

const HeaderWrapper = styled.header`
  position: fixed;
  grid-area: header;
  display: grid;
  ${({ drawer, logo, menu }) =>
    drawer || logo || menu
      ? css`
          grid-template-areas: "headerDrawer headerContent";
          grid-template-columns: auto 1fr;
        `
      : css`
          grid-template-areas: "headerContent";
          grid-template-columns: 1fr;
        `};
  width: 100%;
  top: 0px;
  z-index: 100;
  min-width: 375px;
  ${(props) =>
    !props.noBorder && `border-bottom: 1px solid ${Color("gray", "4")}`};
  background-color: white;
  min-height: 63px;

  @media ${device.tabletL} {
    ${({ drawer, logo, menu }) =>
      (drawer || logo || menu) &&
      css`
        grid-template-columns: 300px 1fr;
      `};
  }
`;

const HeaderContainer = styled.div`
  grid-area: headerContent;
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1020px;
  width: 100%;

  .visible {
    z-index: 70;
  }
`;

const HeaderLeftContainer = styled.div`
  @media ${deviceMax.mobileXL} {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 20px;
  }
`;

const HeaderDrawer = styled.div`
  grid-area: headerDrawer;
  display: flex;
  align-items: center;
  color: ${Color("green")};

  @media ${device.tabletL} {
    ${(props) =>
      !props.white &&
      css`
        background: ${Color("gray", "1")};
        border-right: 1px solid ${Color("gray", "4")};
      `};
    justify-content: center;
  }
`;

const HeaderLogo = styled.div`
  display: flex;
  grid-area: headerDrawer;
  padding: 0 20px;
  align-items: center;
  color: ${Color("green")};

  @media ${device.tabletL} {
  }
`;

const HeaderLeft = styled.div`
  grid-area: headerLeft;
  display: flex;
  align-items: center;
  padding: 0 40px;
`;

const DrawerIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-left: 20px;

  @media ${device.tabletL} {
    ${({ menu }) =>
      !menu &&
      css`
        display: none;
      `}
  }

  svg {
    cursor: pointer;
  }
`;

const NavItemStyles = css`
  padding: 10px 14px;
  border-radius: 10px;
  margin: 0 2px;

  &.active {
    background: ${Color("green", "1")};
  }
  &:hover {
    background: ${Color("gray", "0.5")};
    color: ${Color("green", "7")};
  }
`;

const NavItemLink = styled(Link)`
  ${NavItemStyles}
`;

const NavItemA = styled.a`
  ${NavItemStyles}
`;

const NavItem = ({ to, ...props }) => {
  const Component = to ? NavItemLink : NavItemA;
  return <Component to={to} {...props} />;
};

const CurrentItem = styled.div`
  padding: 10px 14px;
  border-radius: 10px;
  margin: 0 2px;
  background: ${Color("green", "1")};
`;

const highlightItems = [
  {
    id: "highlights",
    label: "Highlights",
    to: "/admin/highlights",
  },
];

const commonItems = [
  {
    id: "preferences",
    label: "Manage preferences",
    to: "/preferences",
  },
  {
    id: "logout",
    label: "Log out",
    to: "/logout",
  },
];

export const TextLogo = ({ large }) => (
  <Link id="header-home" to="/">
    <img
      id="mw-logo"
      width={large ? "309px" : "200px"}
      src={large ? "/images/home/medicalwatch_large.png" : "/medicalwatch.png"}
      alt="Medical.watch"
    />
  </Link>
);

export const Logo = ({ width = "96px" }) => (
  <img {...{ width }} src="/images/logos/mw.svg" alt="Medical.watch" />
);

const Title = ({ isHome }) => (
  <>
    {isHome ? (
      <Row left>
        <TextLogo />
      </Row>
    ) : (
      <>
        <Row show="mobileL" left>
          <Link id="header-home" to="/">
            <TextLogo />
          </Link>
        </Row>
        <Row hide="mobileL">
          <Link id="header-home" to="/">
            <Logo width="48px" />
          </Link>
        </Row>
      </>
    )}
  </>
);

const ResponsiveButton = ({
  id,
  label,
  icon,
  secondary,
  onClick,
  to,
  line,
}) => (
  <>
    <Row show="mobileL" marginLeft>
      <Button {...{ id, label, icon, secondary, onClick, to }} />
    </Row>
    <Row hide="mobileL" marginLeft>
      <Anchor {...{ id, label, icon, secondary, onClick, to, line }} bold />
    </Row>
  </>
);

const SearchResultsWrapper = styled.div`
  position: absolute;
  top: 44px;
  left: 20px;
  right: 20px;
  border-radius: 20px;
  background: white;
  border: 1px solid ${Color("gray", "1")};
  box-shadow: 0px 10px 10px rgba(54, 65, 241, 0.1),
    0px 5px 5px rgba(0, 0, 0, 0.15);
  max-height: 80vh;
  max-width: 700px;
  min-height: 60px;

  overflow-y: auto;

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .grid {
    padding: 10px;
    border-top: 1px solid ${Color("gray", "4")};
    grid-template-columns: 1fr;
    @media ${device.laptop} {
      grid-template-columns: 2fr 1.5fr 1fr;
    }

    &:hover {
      background: ${Color("gray", "0.5")};
    }

    &.first {
      border-top: none;
    }
  }

  .row {
    cursor: pointer;
  }

  .blured .row + .row + .row + .row {
    cursor: default;
    pointer-events: none;

    .grid {
      filter: blur(4px);
    }
  }
`;

const SearchResults = ({
  data,
  loadingRef,
  isLoading,
  hasNextPage,
  isActive,
  relatedData,
}) => {
  const filteredRelatedData = relatedData?.filter(
    (item) => !data?.find((d) => d.id === item.id)
  );
  if (filteredRelatedData?.length > 5) {
    filteredRelatedData.length = 5;
  }

  return (
    <SearchResultsWrapper>
      <Column stretch className={!isActive ? "blured" : ""}>
        {data?.map((item, i) => (
          <Row
            expand
            paddingX="10"
            className="row"
            onClick={() =>
              (window.location.href = `/account/${item.id}?source=suggestions`)
            }
            key={item.id}
          >
            <Grid gap="5" className={i === 0 ? "grid first" : "grid"} middle>
              <Row gap="10" middle>
                <Avatar
                  imageUrl={item.imageurl || item.imageURL}
                  name={item.name}
                />
                <Label bold>{item.name}</Label>
              </Row>
              <Label>{item.institname}</Label>
              <Label>
                {item.country === "United States of America"
                  ? "United States"
                  : item.country === "Undetermined"
                  ? ""
                  : item.country}
              </Label>
            </Grid>
          </Row>
        ))}
      </Column>
      {(isLoading || hasNextPage) && (
        <Row expand paddingAll="20" center ref={loadingRef}>
          <Spinner small />
        </Row>
      )}
      {data?.length === 0 && (
        <Row expand paddingX="20" paddingBottom>
          <Label>No results found</Label>
        </Row>
      )}

      {isActive && <AlsoSearched data={filteredRelatedData} />}
    </SearchResultsWrapper>
  );
};

const RecentlyViewed = ({ isActive, relatedData }) => {
  let data = useSelector(
    (state) => state.settings.account?.recentlyViewed || []
  );
  data = Object.values(data);
  data.sort((a, b) => b.timestamp - a.timestamp);

  const filteredRelatedData = relatedData?.filter(
    (item) => !data?.find((d) => d.id === item.id)
  );
  if (filteredRelatedData?.length > 5) {
    filteredRelatedData.length = 5;
  }

  return (
    <SearchResultsWrapper>
      {data?.length > 0 && (
        <Column stretch className={!isActive ? "blured" : ""}>
          <Row paddingX paddingTop paddingBottom="10">
            <Label bold color="gray" fade="7">
              Recently viewed
            </Label>
          </Row>
          {data?.map((item, i) => (
            <Row
              key={item.id}
              expand
              paddingX="10"
              className="row"
              onClick={() =>
                (window.location.href = `/account/${item.id}?source=suggestions`)
              }
            >
              <Grid gap="5" className={i === 0 ? "grid first" : "grid"} middle>
                <Row gap="10" middle>
                  <Avatar
                    imageUrl={item.imageurl || item.imageURL}
                    name={item.name}
                  />
                  <Label bold>{item.name}</Label>
                </Row>
                <Label>{item.institname}</Label>
                <Label>
                  {item.country === "United States of America"
                    ? "United States"
                    : item.country === "Undetermined"
                    ? ""
                    : item.country}
                </Label>
              </Grid>
            </Row>
          ))}
        </Column>
      )}
      {isActive && <AlsoSearched data={filteredRelatedData} />}
    </SearchResultsWrapper>
  );
};

const AlsoSearched = ({ data }) => {
  return (
    <Column stretch>
      {data?.length > 0 && (
        <Column stretch>
          <Row paddingX paddingTop paddingBottom="10">
            <Label bold color="gray" fade="7">
              People also searched for
            </Label>
          </Row>
          {data?.map((item, i) => (
            <Row
              key={item.id}
              expand
              paddingX="10"
              className="row"
              onClick={() =>
                (window.location.href = `/account/${item.id}?source=suggestions`)
              }
            >
              <Grid gap="5" className={i === 0 ? "grid first" : "grid"} middle>
                <Row gap="10" middle>
                  <Avatar
                    imageUrl={item.imageurl || item.imageURL}
                    name={item.name}
                  />
                  <Label bold>{item.name}</Label>
                </Row>
                <Label>{item.institname}</Label>
                <Label>
                  {item.country === "United States of America"
                    ? "United States"
                    : item.country === "Undetermined"
                    ? ""
                    : item.country}
                </Label>
              </Grid>
            </Row>
          ))}
        </Column>
      )}
    </Column>
  );
};

export const Header = ({
  leftContent,
  rightContent,
  centerContent,
  large,
  back,
  close,
  title,
  relative,
  user,
  noBorder,
  bkg,
  drawer,
  login,
  signin,
  isHome,
  backTo,
  backReloads,
  linkHome,
  signUpClick,
  logo,
  menu,
  white,
  pricing,
  search,
  source,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.data);
  const adminPage = useSelector(
    (state) => state.settings?.adminPage || "conferences"
  );

  const { showDrawer, prev, current } = useSelector((state) => state.nav);
  const [searchResultsVisible, setSearchResultsVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debounceSearch = useDebounce(searchValue, 700);
  const [searchPage, setSearchPage] = useState(0);
  const [searchResults, setSearchResults] = useState({});
  const [hasNextPage, setHasNextPage] = useState(true);

  const { data, refetch, isLoading } = search
    ? useGetSearch({
        params: {
          pattern: searchValue,
          page: searchPage,
          size: MAX_RESULTS,
          ...(auth?.id && { userId: auth.id }),
          ...(auth?.mwRole && { userType: auth.mwRole }),
        },
      })
    : {};
  const { data: relatedData } =
    search && auth.active ? useGetRelatedSearch({}) : {};

  const adminItems = [
    {
      id: "admin",
      label: "Admin settings",
      to: `/admin/${adminPage}`,
    },
  ];

  const cookies = useSelector((state) => state.settings.cookies);

  const handleMoreItems = () => {
    if (!isLoading) {
      setSearchPage(searchResults?.nextPage);
    }
  };

  const enterPress = useKeyPress("Enter");

  useEffect(() => {
    if (search && enterPress) {
      let url = `/search?pattern=${searchValue}`;
      if (source) {
        url += `&source=${source}`;
      }
      location.href = url;
    }
  }, [enterPress]);

  useEffect(() => {
    refetch?.();

    if (searchPage === 0 && searchValue !== "") {
      // check for cookies first before publishing event
      if (!auth?.active && cookies?.seen && !cookies?.accepted) {
        return;
      }
      // amplitude event
      logAnalyticEvent(
        "Search Suggestions",
        {
          term: searchValue,
          source: source,
        },
        auth?.id || null,
        auth?.displayName || null,
        auth?.email || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || "MEDICAL",
        null
      );
    }
  }, [searchPage, debounceSearch]);

  useEffect(() => {
    if (data?.data) {
      setHasNextPage(data.data.hits === MAX_RESULTS);
      setSearchResults((oldResults) => ({
        ...data.data,
        items:
          oldResults?.items && searchPage > 0
            ? [...oldResults.items, ...data.data.items]
            : data.data.items,
      }));
    }
  }, [data]);

  const [loadingRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: handleMoreItems,
  });

  const toggleDrawer = () => {
    dispatch(navActions.set({ showDrawer: !showDrawer }));
  };

  const updateSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchPage(0);
  };

  const handleSearchBlur = () => {
    setSearchResultsVisible(false);
  };

  return (
    <HeaderWrapper
      {...{ large, relative, noBorder, bkg, drawer, isHome, logo, menu }}
    >
      {drawer && (
        <HeaderDrawer {...{ white }}>
          <DrawerIcon onClick={() => toggleDrawer()}>
            <Icon
              name={showDrawer ? "close" : "drawer"}
              color="green"
              fade="6"
            />
          </DrawerIcon>
          <Title {...{ isHome }} />
        </HeaderDrawer>
      )}

      {logo && (
        <HeaderLogo>
          <Title {...{ isHome }} />
        </HeaderLogo>
      )}

      {menu && (
        <HeaderLogo>
          <Row hide="laptop" middle marginLeft="-20">
            <DrawerIcon {...{ menu }} onClick={() => toggleDrawer()}>
              <Icon
                name={showDrawer ? "close" : "drawer"}
                color="green"
                fade="6"
              />
            </DrawerIcon>
            <Title {...{ isHome }} />
          </Row>
          <Row show="laptop">
            <Title {...{ isHome }} />
          </Row>
        </HeaderLogo>
      )}
      <HeaderContainer>
        {menu && (
          <>
            <Row show="laptop" marginLeft="-40">
              {menu
                .filter((item) => item.dropdown)
                .map((item) => (
                  <Row key={item.id}>
                    {item.to ? (
                      <NavItem
                        className={item.active ? "active" : ""}
                        to={item.to}
                        key={item.id}
                        target={item.blank ? "_blank" : undefined}
                      >
                        <H5 bold>{item.label}</H5>
                      </NavItem>
                    ) : (
                      <CurrentItem>
                        <H5 bold>{item.label}</H5>
                      </CurrentItem>
                    )}
                  </Row>
                ))}
            </Row>
            <Row>
              {menu
                .filter((item) => !item.dropdown)
                .map((item) => (
                  <Row key={item.id}>
                    {item.to ? (
                      <NavItem
                        to={item.to}
                        key={item.id}
                        target={item.blank ? "_blank" : undefined}
                      >
                        <H5 bold>{item.label}</H5>
                      </NavItem>
                    ) : (
                      <CurrentItem>
                        <H5 bold>{item.label}</H5>
                      </CurrentItem>
                    )}
                  </Row>
                ))}
            </Row>
          </>
        )}
        {auth.active && (
          <HeaderLeftContainer>
            {linkHome && (
              <HeaderLeft>
                <Title {...{ isHome }} />
              </HeaderLeft>
            )}
            {leftContent && <HeaderLeft>{leftContent}</HeaderLeft>}
            {back && (
              <>
                {/* we are redirection to the home screen in two situations: 
                1. there is no history
                2. we are coming from the login screen
              */}

                {history.length > 1 ? (
                  <Row show="mobileL" marginLeft>
                    <Button
                      id="header-back"
                      label="Back"
                      icon="arrowLeft"
                      secondary
                      onClick={(e) => {
                        e.preventDefault();
                        history.goBack();
                      }}
                    />
                  </Row>
                ) : (
                  <>
                    {auth.active && (
                      <Row show="mobileL" marginLeft>
                        <Button
                          label="Home"
                          icon="arrowLeft"
                          secondary
                          to="/"
                        />
                      </Row>
                    )}
                  </>
                )}
              </>
            )}
            {backTo && (
              <ResponsiveButton
                id="header-back"
                label="Back"
                icon="arrowLeft"
                secondary
                onClick={(e) => {
                  e.preventDefault();
                  history.push(backTo);
                }}
              />
            )}
            {backReloads && (
              <ResponsiveButton
                id="header-back"
                label="Back"
                icon="arrowLeft"
                secondary
                onClick={(e) => {
                  e.preventDefault();
                  location.reload();
                }}
              />
            )}
          </HeaderLeftContainer>
        )}
        {centerContent ? (
          <Column fit paddingX relative>
            {centerContent}
          </Column>
        ) : (
          <>
            {search ? (
              <Row fit>
                <Column
                  show="tablet"
                  fit
                  paddingX
                  relative
                  onMouseLeave={handleSearchBlur}
                >
                  <Input
                    maxWidth="700px"
                    small
                    value={searchValue}
                    onChange={updateSearch}
                    icon="search"
                    placeholder="Search physicians and Institutions"
                    onClick={() => setSearchResultsVisible(true)}
                  />

                  {searchResultsVisible && (
                    <>
                      {searchValue !== "" ? (
                        <SearchResults
                          data={searchResults?.items}
                          isActive={auth.active}
                          relatedData={relatedData?.data || []}
                          {...{
                            loadingRef,
                            isLoading,
                            hasNextPage,
                          }}
                        />
                      ) : (
                        <RecentlyViewed
                          isActive={auth.active}
                          relatedData={relatedData?.data || []}
                        />
                      )}
                    </>
                  )}
                </Column>
                <Row hide="tablet" fit />
              </Row>
            ) : (
              <Row fit>
                <Row fit />
                {title && (
                  <H3 bold color="green" fade="6">
                    {title}
                  </H3>
                )}
                <Row fit />
              </Row>
            )}
          </>
        )}
        {rightContent}
        {close && (
          <Row marginLeft>
            <Anchor
              label="Cancel"
              secondary
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            />
          </Row>
        )}
        {auth.active ? (
          <>
            {user && (
              <Row show="mobileXL">
                <Dropdown
                  id="dropdown-user"
                  right
                  small
                  label={auth?.firstName ? `Hi, ${auth.firstName}` : "Hi!"}
                  labelOnly
                  items={
                    auth?.mwRole === "ADMIN"
                      ? adminItems.concat(commonItems)
                      : auth?.mwRole === "HIGHLIGHTSADMIN"
                      ? highlightItems.concat(commonItems)
                      : commonItems
                  }
                />
              </Row>
            )}
          </>
        ) : (
          <>
            {login && (
              <Row marginLeft gap="20" show="mobileXL">
                <Button
                  id="button-login"
                  secondary
                  label="Sign in"
                  to="/login"
                  line
                  noShrink
                />
                <Button
                  id="button-create-account"
                  label="Sign up"
                  to="/"
                  onClick={() => {
                    if (signUpClick) signUpClick();
                  }}
                  line
                  noShrink
                />
              </Row>
            )}
            {pricing && (
              <Row marginLeft gap="20" show="mobileXL">
                <Button
                  id="button-login"
                  secondary
                  label="Sign in"
                  to="/login"
                  line
                  noShrink
                />
                <Button
                  id="button-create-account"
                  label="Sign up"
                  to="/pricing"
                  onClick={() => {
                    if (signUpClick) signUpClick();
                  }}
                  line
                  noShrink
                />
              </Row>
            )}
            {signin && (
              <Row>
                <Button
                  id="button-login"
                  secondary
                  label="Sign in"
                  to="/login"
                  line
                  noShrink
                />
              </Row>
            )}
          </>
        )}
      </HeaderContainer>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  large: PropTypes.bool,
  title: PropTypes.string,
  bkg: PropTypes.string,
  back: PropTypes.bool,
  close: PropTypes.bool,
  relative: PropTypes.bool,
  user: PropTypes.bool,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  centerContent: PropTypes.node,
  bottomContent: PropTypes.node,
  noBorder: PropTypes.bool,
  auth: PropTypes.object,
};

export const Metadata = ({ description, image, title, url }) => (
  <Helmet prioritizeSeoTags>
    <title>
      {title ||
        "Medical.watch - See what physicians are saying about your brand on social media"}
    </title>
    <meta
      property="og:title"
      content={
        title ||
        "Medical.watch - See what physicians are saying about your brand on social media"
      }
    />
    <meta property="og:url" content={url || "https://my.medical.watch"} />
    <meta
      property="og:description"
      content={
        description ||
        "Get brand activity reports in your inbox. Find doctors for partnership opportunities. Discover what physicians say about your brand. Optimize your message journeys. Find your advocates and detractors. Spot the online conversation leaders. Get daily reports for medical congresses. Never miss practice changing news! See who's driving online discussions. Reach out for scientific collaborations."
      }
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@MedicalwatchFTW" />
    <meta name="twitter:title" content={title || "Medical.watch"} />
    <meta
      name="twitter:description"
      content={
        description ||
        "See what physicians are saying about your brand on social media"
      }
    />
    <meta
      name="twitter:image"
      content={image || "https://my.medical.watch/medicalwatch.png"}
    />
  </Helmet>
);
