import React from "react";
import { Link } from "react-router-dom";

import {
  Row,
  Column,
  // H4,
  MainWrapper,
  Header,
  Content,
  Label,
  Anchor,
  ResponsiveButton,
  Filters,
  Input,
  List,
  ListItem,
  Dropdown,
  Spinner,
  Switch,
  Icon,
  // AutoComplete,
  Grid,
} from "../../../components";

import { Menu } from "../Menu";

const getColor = (tag) => {
  return tag.preferredTagId || tag.alwaysIgnore ? "gray" : "black";
};

const getTagLink = (tag, products, diseases, conferences) => {
  // if product tag
  if (products?.length > 0) {
    const product = products.filter((p) => p.tagId === tag.id);
    if (product.length > 0) {
      return `/editProduct/${product[0].id}`;
    }
  }
  // if disease tag
  if (diseases?.length > 0) {
    const disease = diseases.filter((d) => d.tagId === tag.id);
    if (disease.length > 0) {
      return `/editDisease/${disease[0].id}`;
    }
  }
  // if conference tag
  if (conferences?.length > 0) {
    const conference = conferences.filter((c) => c.tagId === tag.id);
    if (conference.length > 0) {
      return `/editConference/${conference[0].id}`;
    }
  }
  // all others tag
  return `/editTag/${tag.id}`;
};

const getPreferredTagLink = (tag, products, diseases, conferences) => {
  // if product tag
  if (products?.length > 0) {
    const product = products.filter((p) => p.tagId === tag.preferredTagId);
    if (product.length > 0) {
      return (
        <Anchor
          color={getColor(tag)}
          small
          line
          label={`(${product[0].primaryName})`}
          to={`/editProduct/${product[0].id}`}
        />
      );
    }
  }
  // if disease tag
  if (diseases?.length > 0) {
    const disease = diseases.filter((d) => d.tagId === tag.preferredTagId);
    if (disease.length > 0) {
      return (
        <Anchor
          color={getColor(tag)}
          small
          line
          label={`(${disease[0].primaryName})`}
          to={`/editDisease/${disease[0].id}`}
        />
      );
    }
  }
  // if conference tag
  if (conferences?.length > 0) {
    const conference = conferences.filter(
      (c) => c.tagId === tag.preferredTagId
    );
    if (conference.length > 0) {
      return (
        <Anchor
          color={getColor(tag)}
          small
          line
          label={`(${conference[0].primaryName})`}
          to={`/editConference/${conference[0].id}`}
        />
      );
    }
  }
  // all others tag
  return (
    <Anchor
      color={getColor(tag)}
      small
      line
      label={`(${tag.preferredTagName})`}
      to={`/editTag/${tag.preferredTagId}`}
    />
  );
};

export const view = ({
  loading,
  tags,
  tagsCount,
  activeFilters,

  searchTerm,
  setSearchTerm,

  meaningItems,
  selectedMeaningId,
  handleMeaningChange,

  selectedStatusId,
  handleStatusChange,
  statusItems,

  includeStats,
  setIncludeStats,

  diseases,
  products,
  conferences,
}) => {
  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
            <ResponsiveButton label="Add Tag" to="/newTag" />
          </>
        }
      />
      <Menu active="tags" />

      <Content>
        <Column>
          <Filters counter={`${tagsCount} Tags`} {...{ activeFilters }}>
            <Grid>
              <Column gap="10">
                <Label bold>Name</Label>
                <Input
                  small
                  placeholder="Search"
                  icon="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Column>
              <Column gap="10">
                <Label bold>Status</Label>
                <Dropdown
                  expand
                  right
                  select
                  value={selectedStatusId}
                  onChange={handleStatusChange}
                  items={statusItems}
                />
              </Column>
              <Column gap="10">
                <Label bold>Meaning</Label>
                <Dropdown
                  expand
                  right
                  select
                  value={selectedMeaningId}
                  onChange={handleMeaningChange}
                  items={meaningItems}
                />
              </Column>
              <Column marginTop="10">
                <Label bold>Include posts count</Label>
                <Switch checked={includeStats} onChange={setIncludeStats} />
              </Column>
            </Grid>
          </Filters>

          <Column marginTop="10">
            {!loading && tags.length > 0 ? (
              <List>
                <ListItem>
                  <Row fit>
                    <Label color="gray" fade="3" bold>
                      Name
                    </Label>
                  </Row>
                  <Row paddingLeft center style={{ width: "180px" }}>
                    <Label color="gray" fade="3" bold>
                      Meaning
                    </Label>
                  </Row>
                  <Row paddingLeft center style={{ width: "200px" }}>
                    <Label color="gray" fade="3" bold>
                      Created At
                    </Label>
                  </Row>
                  <Row paddingLeft center style={{ width: "80px" }}>
                    <Label color="gray" fade="3" bold>
                      Reviewed
                    </Label>
                  </Row>
                  <Row paddingLeft center style={{ width: "80px" }}>
                    <Label color="gray" fade="3" bold>
                      Match
                    </Label>
                  </Row>
                  {includeStats && (
                    <Row paddingLeft center style={{ width: "80px" }}>
                      <Label color="gray" fade="3" bold>
                        Count
                      </Label>
                    </Row>
                  )}
                </ListItem>
                {tags.map((t) => (
                  <Link
                    key={t.tag.id}
                    to={getTagLink(t.tag, products, diseases, conferences)}
                  >
                    <ListItem>
                      <Row fit>
                        <Label color={getColor(t.tag)}>{t.tag.name}</Label>
                        <Row fit paddingRight right>
                          {t.tag.preferredTagId &&
                            getPreferredTagLink(
                              t.tag,
                              products,
                              diseases,
                              conferences
                            )}
                        </Row>
                      </Row>
                      <Row paddingLeft center style={{ width: "180px" }}>
                        <Label color={getColor(t.tag)}>
                          {
                            meaningItems.find(
                              (item) => item.id === t.tag.meaning
                            )?.label
                          }
                        </Label>
                      </Row>
                      <Row paddingLeft center style={{ width: "200px" }}>
                        <Label color="gray" fade="3">
                          {new Date(t.tag.createdAt).toLocaleString(
                            "en-US",
                            { timeZone: "UTC" } // remove this if we want in local timezone
                          )}
                        </Label>
                      </Row>
                      <Row paddingLeft center style={{ width: "80px" }}>
                        {t.tag.reviewed ? (
                          <Icon size="30" name="check" color="green" />
                        ) : (
                          <Icon size="20" name="close" color="red" />
                        )}
                      </Row>
                      <Row paddingLeft center style={{ width: "80px" }}>
                        {t.tag.alwaysMatch ? (
                          <Icon size="30" name="check" color="green" />
                        ) : (
                          <Icon size="20" name="close" color="red" />
                        )}
                      </Row>
                      {t.count !== -1 && (
                        <Row paddingLeft center style={{ width: "80px" }}>
                          <Label color={getColor(t.tag)}>{t.count.toLocaleString()}</Label>
                        </Row>
                      )}
                    </ListItem>
                  </Link>
                ))}
                {loading && (
                  <Row expand paddingAll center>
                    <Spinner />
                  </Row>
                )}
              </List>
            ) : (
              <>
                {loading ? (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                ) : (
                  <List center>
                    <ListItem>No Tags found</ListItem>
                  </List>
                )}
              </>
            )}
          </Column>

          {/* <Modal visible={tagModal} toggleVisibility={toggleTagModal} close>
        <Row marginBottom middle>
          <Row marginRight>
            <Label>Tag</Label>
          </Row>
          <Row expand>
            <Input
              placeholder="Tag Name"
              value={newTag.name}
              onChange={handleChangeTag}
            />
          </Row>
        </Row>
        <ModalFooter right>
          <Button secondary label="Save" onClick={() => handleSaveTag()} />
        </ModalFooter>
      </Modal> */}
        </Column>
      </Content>
    </MainWrapper>
  );
};
