import React, { useState } from "react";
import styled from "styled-components";

import { Label } from "./Typography";
import { Column, Row, RowToColumn } from "./Layout";
import { Anchor } from "./Button";
import { Tooltip } from "./Tooltip";
import { Color, device } from "../utils/variables";

export const DrawerOverlay = styled.div`
  background: ${Color("gray", "8")};
  position: fixed;
  top: 63px;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s;
  transition: z-index 0s;
  z-index: -1;
  cursor: pointer;

  ${(props) =>
    props.showDrawer &&
    `
    z-index: 70;
    opacity: 0.6;
  `};

  @media ${device.tabletL} {
    display: none;
  }
`;

export const Collapsible = styled(Column)`
  height: 0;
  margin-top: -5px;
  overflow: hidden;
`;

export const Header = styled.div`
  padding-bottom: 5px;
`;

export const HeaderRow = styled(RowToColumn)`
  padding: 10px 20px;
`;

export const Footer = styled.div`
  margin: 10px -20px -10px;
  border-top: 1px solid ${Color("gray", "4")};
`;

export const FiltersWrapper = styled(Column)`
  background: ${Color("gray", "1")};
  border-radius: 10px;
  min-height: 44px;

  &.open {
    ${Collapsible} {
      overflow: visible;
      padding: 10px 20px;
      transition: height 0.3s;
      height: auto;
    }

    ${Header} {
      overflow: hidden;
    }

    ${HeaderRow} {
      box-shadow: 0px 1px 5px rgba(34, 34, 35, 0.3),
        inset 0px -1px 0px ${Color("gray", "4")};
    }
  }
`;

export const Filters = ({
  noToggle,
  children,
  counter,
  activeFilters,
  onClearFilters,
  datePicker,
  footer,
  counterTooltip,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <FiltersWrapper className={open ? "open" : ""} middle>
      <Header>
        <HeaderRow device="mobileXL" gap="10">
          {counter && (
            <Row middle>
              {counterTooltip ? (
                <Tooltip
                  content={<Label color="white">{counterTooltip}</Label>}
                >
                  <Label noShrink bold>
                    {counter}
                  </Label>
                </Tooltip>
              ) : (
                <Label noShrink bold>
                  {counter}
                </Label>
              )}
            </Row>
          )}
          <Row middle>{datePicker}</Row>
          {!noToggle && (
            <Row>
              <Tooltip
                hide={open || !activeFilters || activeFilters?.length === 0}
                content={
                  <Column gap="10" paddingY="5" paddingX="10">
                    {!open &&
                      activeFilters?.map((a) => (
                        <Label key={a} color="white">
                          {a}
                        </Label>
                      ))}
                  </Column>
                }
              >
                <Anchor
                  bold
                  reverse
                  label={`Show filters${
                    activeFilters?.length > 0
                      ? ` (${activeFilters.length})`
                      : ""
                  }`}
                  icon={open ? "chevronUp" : "chevronDown"}
                  onClick={() => setOpen(!open)}
                />
              </Tooltip>
            </Row>
          )}
        </HeaderRow>
      </Header>
      <Collapsible>
        {children}
        {onClearFilters && (
          <Footer>
            <Row paddingLeft top>
              <Row paddingY="13" noShrink>
                <Anchor
                  bold
                  label="Clear filters"
                  onClick={() => onClearFilters()}
                />
              </Row>
              {footer}
            </Row>
          </Footer>
        )}
      </Collapsible>
    </FiltersWrapper>
  );
};
