import React from "react";
import {
  Column,
  Row,
  MainWrapper,
  Header,
  Content,
  H2,
  Label,
  Input,
  Button,
  Anchor,
  Modal,
  ModalFooter,
  Switch,
  HR,
  AutoCompleteWithResponses,
  Dropdown,
  Pill,
  Collapsible,
} from "../../../components";
import { Menu } from "../Menu";

export const Group = ({ children, label }) => (
  <Column marginTop="30">
    <Row marginBottom="10">
      <Label bold>{label}</Label>
    </Row>
    <Row marginTop="5">{children}</Row>
  </Column>
);

export const view = ({
  errors,
  handleSave,
  data,
  handleChange,
  isSavePressed,
  isNew,
  deleteModal,
  toggleDeleteModal,
  handleDelete,
  handleSwitchChange,

  tags,
  selectedTags,
  addSelectedTags,
  removeSelectedTags,
  meaningItems,
  searchTerm,
  handleSearchTag,
  tagsInfinteScroll,

  handleNameOnBlur,
  handleMeaningOnBlur,
}) => (
  <MainWrapper drawer>
    <Header
      drawer
      close={isNew}
      back={!isNew}
      bkg="white"
      rightContent={
        <Row>
          {isNew && <Button label={"Create"} onClick={() => handleSave()} />}
          {!isNew && (
            <Row marginX>
              <Anchor
                label="Delete"
                color="red"
                onClick={() => toggleDeleteModal(true)}
              />
            </Row>
          )}
        </Row>
      }
    ></Header>
    <Menu active="tags" />

    <Content>
      <Column center>
        <Column maxWidth="700" marginBottom>
          <Row paddingTop="20">
            <H2 bold>{isNew ? "Add a new Tag" : "Edit Tag"}</H2>
          </Row>
          <Column>
            <Column maxWidth="400">
              <Group label="Preferred Tag name">
                <Input
                  small
                  placeholder="Tag name"
                  value={data.name}
                  errorMessage={isSavePressed && errors.name}
                  onChange={(e) =>
                    handleChange({ value: e.target?.value, field: "name" })
                  }
                  onBlur={handleNameOnBlur}
                />
              </Group>
              {/* {isNew && ( */}
              <Group label="Meaning">
                <Dropdown
                  expand
                  value={data.meaning}
                  items={meaningItems}
                  onChange={(e) =>
                    handleChange({ value: e.id, field: "meaning" })
                  }
                  onBlur={handleMeaningOnBlur}
                />
              </Group>
              {/* )} */}
              <Column marginTop>
                <Switch
                  label="Active"
                  checked={!data.alwaysIgnore}
                  disabled={!data.alwaysIgnore && selectedTags.length > 0}
                  onChange={(e) =>
                    handleSwitchChange({ value: !e, field: "alwaysIgnore" })
                  }
                />
                <Row marginTop="-10" marginLeft="50">
                  <Label color="gray" fade="6">
                    This Tag is active and visible.
                    {!data.alwaysIgnore &&
                      selectedTags.length > 0 &&
                      ` Cannot be deactivated as there are merged tags`}
                  </Label>
                </Row>
              </Column>
            </Column>
            <Column marginTop>
              <Switch
                label="Exact Match"
                checked={data.alwaysMatch}
                onChange={(e) =>
                  handleSwitchChange({ value: e, field: "alwaysMatch" })
                }
              />
              <Row marginTop="-10" marginLeft="50">
                <Label color="gray" fade="6">
                  Set exact match toggle ON for important tags you always want
                  to assign when the exact tag text is found in free text.
                </Label>
              </Row>
            </Column>

            {!isNew && !data.preferredTagId && (
              <Column marginTop="40">
                <Collapsible open label="Merged Tags">
                  <Column paddingAll gap="10">
                    <Row marginTop="10">
                      <Label>
                        Search the list of tags to merge selected tags into the
                        preferred tag shown above. Only the preferred tag will
                        be shown in reports and will be applied to free text
                        even if the merged tag is originally detected.
                      </Label>
                    </Row>
                    <Row marginTop="20" fit>
                      <AutoCompleteWithResponses
                        showOnEmptyTerm
                        searchResults={tags}
                        placeholder="Add Tags"
                        displayTextFromSelected
                        setSearchTerm={(e) => handleSearchTag(e)}
                        searchTerm={searchTerm}
                        selectedItems={selectedTags}
                        addItem={addSelectedTags}
                        removeItem={removeSelectedTags}
                        clearSearchResults={() => {}}
                        searchLoading={false}
                        hasClearButton
                        infiniteScroll={tagsInfinteScroll}
                        noResultsMessage={
                          searchTerm.length < 2
                            ? "Enter at least two characters"
                            : ""
                        }
                      />
                    </Row>
                  </Column>
                </Collapsible>
              </Column>
            )}

            {!isNew && data.preferredTagId && (
              <>
                <Row marginTop="40" marginX="-20">
                  <HR />
                </Row>
                <Column marginTop="40" marginBottom>
                  <Label bold large>
                    Preferred Tag
                  </Label>
                  <Row marginTop>
                    <Pill
                      label={data.preferredTagName}
                      close
                      onClose={(e) => {
                        handleChange({
                          value: undefined,
                          field: "preferredTagId",
                        });
                        removeSelectedTags({ id: data.id });
                      }}
                    />
                  </Row>
                </Column>
              </>
            )}
          </Column>
        </Column>

        <Modal
          visible={deleteModal}
          toggleVisibility={toggleDeleteModal}
          title="Delete Tag"
          close
        >
          <Column marginBottom gap="20" center>
            <Label>
              <Label large bold>
                {data.name}
              </Label>
              <Label large> will be deleted</Label>
            </Label>
            <Label large>
              Are you sure you want to continue? This action cannot be undone.
            </Label>
          </Column>
          <ModalFooter right>
            <Button secondary label="Delete" onClick={() => handleDelete()} />
          </ModalFooter>
        </Modal>
      </Column>
    </Content>
  </MainWrapper>
);
