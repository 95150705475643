import React, { useEffect, useState } from "react";

import {
  Row,
  Column,
  List,
  Anchor,
  Spinner,
  Dropdown,
  NoContent,
  ExportModal,
  Card,
  RowToColumn,
  P,
  Modal,
  ModalFooter,
  Button,
  Checkbox,
  Radios,
  Blured,
  Label,
  TweetCard,
} from "../../components";
import { Link } from "react-router-dom";

const ExportPptModal = ({ visible, toggleVisibility, action }) => {
  const initialItems = [
    { id: "all", label: "All", value: 1, checked: true },
    { id: "non-us", label: "Non-US", value: 2, checked: false },
  ];

  const [anonymizeChecked, setAnonymizeChecked] = useState(false);
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    if (visible) {
      setAnonymizeChecked(false);
      setItems(initialItems);
    }
  }, [visible]);

  return (
    <Modal
      maxWidth="500"
      visible={visible}
      toggleVisibility={toggleVisibility}
      close
      noMargin
    >
      <P>Options:</P>
      <Checkbox
        label="Anonymize HCP names"
        checked={anonymizeChecked}
        onChange={() => setAnonymizeChecked(!anonymizeChecked)}
      />
      {anonymizeChecked && (
        <Row gap="40" center expand>
          <Radios items={items} onChange={setItems} />
        </Row>
      )}
      <ModalFooter>
        <Button
          label={"Export to PowerPoint"}
          onClick={() =>
            action({
              maskAllHCPNames: anonymizeChecked && items[0].checked,
              maskNonUSHCPNames: anonymizeChecked && items[1].checked,
            })
          }
        />
      </ModalFooter>
    </Modal>
  );
};

export const TopTweets = ({
  source,
  tweets,
  tweetsRef,
  hasNextPage,
  loading,
  isReady,
  keywords,
  sortBy,
  handleSortByChange,
  showExport,
  exportCSVForTweets,
  onDateClick,
  onClearFilters,
  isRegistered,
  pptInProgress,
  exportPptReport,
}) => {
  const [expotPptVisibility, toggleExportPptVisibility] = useState(false);
  const [expotVisibility, toggleExportVisibility] = useState(false);

  return (
    <Column maxWidth="700">
      <RowToColumn marginBottom gap="30" columnGap="20" device="tablet">
        <Dropdown
          small
          expand
          select
          value={sortBy}
          label="Sort by"
          items={[
            { id: "score", label: "Most Influential" },
            { id: "date", label: "Newest" },
          ]}
          onChange={(e) => handleSortByChange(e)}
        />
        {isRegistered && showExport ? (
          <Row gap="10" middle>
            <Dropdown
              small
              width="200"
              trigger={<Anchor label="Export" icon="download" />}
              items={[
                { id: "xlsx", label: "Excel" },
                { id: "pptx", label: "PowerPoint" },
              ]}
              onChange={(e) => {
                if (e.id === "xlsx") {
                  exportCSVForTweets(true);
                }
                if (e.id === "pptx") {
                  toggleExportPptVisibility(true);
                }
              }}
            />
            {pptInProgress && <Spinner small />}
          </Row>
        ) : (
          <>
            {showExport && (
              <Row gap="10" middle>
                <Anchor
                  label="Export"
                  icon="download"
                  onClick={() => toggleExportVisibility(true)}
                />
              </Row>
            )}
          </>
        )}
      </RowToColumn>
      <ExportModal
        visible={expotVisibility}
        toggleVisibility={() => toggleExportVisibility(!expotVisibility)}
      />
      <ExportPptModal
        visible={expotPptVisibility}
        toggleVisibility={() => toggleExportPptVisibility(!expotPptVisibility)}
        action={(params) => {
          exportPptReport(params);
          toggleExportPptVisibility(false);
        }}
      />
      <List>
        {tweets.map((t, index) => {
          const hasQuote = t?.quoted?.length > 0;

          return (
            <Column
              marginBottom
              id={`tweet-${t.externalId}`}
              key={t.externalId}
            >
              <Blured
                enabled={isRegistered}
                text={
                  index === 0 && (
                    <Label>
                      <Anchor bold label="Sign in" to="/login" />
                      <Row marginX="8">
                        <Label bold>or</Label>
                      </Row>
                      <Row marginRight="8">
                        <Anchor bold label="Sign up" to="/" />
                      </Row>
                      <Label bold>to see posts</Label>
                    </Label>
                  )
                }
              >
                <Link
                  to={`/post/${t.externalId}${
                    source ? "?source=" + source : ""
                  }`}
                >
                  <Card border={hasQuote} paddingAll={!hasQuote ? "0" : "20"}>
                    <TweetCard
                      source={source}
                      key={t.externalId}
                      data={t}
                      {...{ hasQuote, keywords }}
                    />
                    {hasQuote && (
                      <Column marginTop="30">
                        <TweetCard
                          source={source}
                          key={t.quoted[0].externalId}
                          data={t.quoted[0]}
                        />
                      </Column>
                    )}
                  </Card>
                </Link>
              </Blured>
            </Column>
          );
        })}
      </List>
      {(loading || hasNextPage) && (
        <Row expand paddingAll="40" center ref={tweetsRef}>
          <Spinner />
        </Row>
      )}
      {isReady && tweets.length === 0 && (
        <NoContent {...{ onDateClick, onClearFilters }} />
      )}
    </Column>
  );
};
