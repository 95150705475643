import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { view } from "./InsightView";
import { logAnalyticEvent, getUrlParams } from "../../utils";
import {
  useGetInsight,
  useGetInsights,
  useRegenerate,
  useUpdateSummaryTitle,
} from "../../store/queries/insights";

export const Insight = ({ signUpClick }) => {
  const queryParams = getUrlParams(location.search);
  const { objectId, id } = queryParams;

  const auth = useSelector((state) => state.auth.data);

  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedInsight, setSelectedInsight] = useState(null);

  const { data: insight, isLoading } = useGetInsight({
    data: { objectId, summaryId: id },
  });
  const { data: moreInsights, isLoading: isLoadingMoreInsights } =
    useGetInsights({
      data: selectedInsight,
      enabled: !!selectedInsight,
    });
  const {
    mutateAsync: regenerateInsight,
    isLoading: isRegenerating,
    error: regenerateError,
  } = useRegenerate();
  const {
    mutateAsync: updateSummaryTitle,
    isLoading: isAdminLoadingMediaSummary,
    isSuccess: isAdminMediaSummarySuccess,
  } = useUpdateSummaryTitle({ id });

  useEffect(() => {
    if (insight?.data) {
      setSelectedInsight(insight?.data[0]);
    }
  }, [insight?.data]);

  useEffect(() => {
    scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (insight?.data.length > 0) {
      logAnalyticEvent(
        "Landing - Insights",
        {
          page: "Insight",
          name: insight?.data?.[0]?.name,
          title: insight?.data?.[0]?.title,
        },
        auth?.id || queryParams?.u || null,
        auth?.displayName || queryParams?.n || null,
        auth?.email || queryParams?.e || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue ||
          queryParams?.t ||
          "MEDICAL",
        null
      );
    }
  }, [insight?.data]);

  useEffect(() => {
    if (regenerateError?.message) {
      setError(regenerateError.message);
    }
  }, [regenerateError]);

  const handleRegenerate = async (id) => {
    if (id) {
      await regenerateInsight(id);
    }
  };

  const handleUpdateTitle = async (id) => {
    try {
      const resp = await updateSummaryTitle({
        id,
        title,
      });
      if (resp.data) {
        setEditMode(false);
      }
    } catch (error) {
      console.error("Error updating title:", error);
    }
  };

  const handleCancelEditing = () => {
    setEditMode(false);
  };

  console.log("data :>> ", insight);
  return view({
    isAdmin: auth?.mwRole === "ADMIN",
    signUpClick,
    insight: insight?.data?.[0],
    isLoading,
    moreInsights: moreInsights?.data?.filter((i) => i.id !== id)?.slice(0, 4),
    isLoadingMoreInsights,
    editMode,
    setEditMode,
    title,
    setTitle,
    handleCancelEditing,
    isRegenerating,
    handleRegenerate,
    handleUpdateTitle,
  });
};
