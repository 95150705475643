import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";

export function getStudies({
  condition,
  overallStatus,
  phase,
  country,
}) {
  const params = objectToUrlEncoded({
    condition,
    overallStatus,
    phase,
    country,
  });
  return axios.get(`${getApiPath()}/studies?${params}`, {headers: authHeader()});
};
