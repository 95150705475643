import React from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";

import {
  Column,
  Row,
  Label,
  H2,
  H4,
  Footer,
  MainWrapper,
  Metadata,
  Spinner,
  Anchor,
  Pill,
  TextArea,
  Button,
  TweetCard,
  Icon,
} from "../../components";
import { Color } from "../../utils/variables";
import { formatShortDate, insightHighlight } from "../../utils";
import {
  HeaderAndDrawer,
  Share,
  InsightsCard,
  ImageWithError,
} from "./components";

const HTML = styled.div`
  .highlights {
    min-width: 100%;
    display: block;

    br + br {
      margin-top: 0;
    }
  }
`;

const QuotedTweet = styled.div`
  margin: 10px 0;
  border-left: 5px solid ${Color("gray", "4")};
  padding-left: 10px;
`;

export const view = ({
  isAdmin,
  signUpClick,
  insight,
  isLoading,
  moreInsights,
  isLoadingMoreInsights,
  editMode,
  setEditMode,
  title,
  setTitle,
  handleUpdateTitle,
  handleCancelEditing,
  handleRegenerate,
  isRegenerating,
  error,
}) => {
  const history = useHistory();

  return (
    <MainWrapper className="dashboard">
      <Metadata />
      <HeaderAndDrawer {...{ signUpClick, activeUrl: "disease_insights" }} />
      <Column marginTop="100">
        <Column paddingBottom="100">
          <Column center>
            <Column maxWidth="700" paddingX>
              <Row>
                <Anchor
                  bold
                  onClick={() => history.goBack()}
                  icon="arrowLeft"
                  label={`Back`}
                />
              </Row>

              {isLoading ? (
                <Row paddingAll="80" expand center>
                  <Spinner />
                </Row>
              ) : (
                <>
                  {insight?.post?.tAccount && (
                    <Column width="350" marginTop="30">
                      <Row marginRight marginY={10}>
                        <ImageWithError
                          data={insight.post}
                          width={350}
                          height={350}
                          border
                        />
                      </Row>
                      <Column gap="5">
                        <H4 bold>{insight.post.tAccount?.name}</H4>
                        <Label color="gray" fade="7">
                          {insight.post.tAccount?.location}
                        </Label>
                      </Column>
                    </Column>
                  )}
                  {!editMode ? (
                    <Column marginTop="30">
                      <H2 regular bold>
                        <title>{insight?.title}</title>
                      </H2>
                    </Column>
                  ) : (
                    <Column marginTop="20" stretch gap="10">
                      <TextArea
                        expand
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      <Row expand gap="10" right>
                        <Button
                          secondary
                          minWidth={100}
                          small
                          label="Cancel"
                          onClick={handleCancelEditing}
                        />
                        <Button
                          minWidth={100}
                          small
                          label="Save"
                          onClick={() => handleUpdateTitle(insight.id)}
                        />
                      </Row>
                    </Column>
                  )}
                  {isAdmin && !editMode && (
                    <Row marginTop="20">
                      {isRegenerating ? (
                        <Row expand center>
                          <Spinner />
                        </Row>
                      ) : (
                        <Column gap="20">
                          <Row gap="20">
                            <Anchor
                              bold
                              line
                              icon="reload"
                              label="Regenerate Insight"
                              onClick={() => handleRegenerate(insight.id)}
                            />
                            <Anchor
                              bold
                              line
                              icon={insight.title ? "edit" : "add"}
                              label={insight.title ? "Edit Title" : "Add title"}
                              onClick={() => {
                                setTitle(insight.title);
                                setEditMode(true);
                              }}
                            />
                          </Row>
                          {error && (
                            <Column gap="5">
                              <Label color="red">{error}</Label>
                              <Label color="red">Please, try again.</Label>
                            </Column>
                          )}
                        </Column>
                      )}
                    </Row>
                  )}

                  {insight?.createdAt && (
                    <Column marginTop="30">
                      <HTML>
                        {insightHighlight({
                          text: insight?.content,
                          excludeBreaks: true,
                        })}
                      </HTML>
                    </Column>
                  )}

                  {insight?.post && (
                    <Link to={`/post/${insight.post.externalId}`}>
                      <QuotedTweet>
                        <Row marginLeft="10">
                          <Icon name="quote" color="gray" fade="4" />
                        </Row>
                        <TweetCard data={insight.post} insightTweet />
                      </QuotedTweet>
                    </Link>
                  )}

                  {insight?.createdAt && (
                    <Column marginTop="10">
                      <Label small color="gray" fade="7">
                        {formatShortDate(insight.createdAt)}
                      </Label>
                    </Column>
                  )}
                  {insight?.tags && (
                    <Row flexWrap marginTop="30">
                      {insight.tags.split(", ").map((tag) => (
                        <Pill key={tag} bkg="green" fade="2" label={tag} />
                      ))}
                    </Row>
                  )}
                  {insight?.title && (
                    <Column marginTop="30">
                      <Share title={insight.title} />
                    </Column>
                  )}
                </>
              )}

              {isLoadingMoreInsights ? (
                <Row paddingAll="80" expand center>
                  <Spinner />
                </Row>
              ) : (
                <>
                  {moreInsights?.length > 0 && (
                    <Column gap="20" marginTop="40">
                      <H4 bold>Read also</H4>
                      {moreInsights.map((insight) => (
                        <Column key={insight.id}>
                          <InsightsCard data={insight} />
                        </Column>
                      ))}
                    </Column>
                  )}
                </>
              )}
            </Column>
          </Column>
        </Column>
        <Footer />
      </Column>
    </MainWrapper>
  );
};
