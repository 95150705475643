import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import useDebounce from "../../../utils/useDebounce";

import useKeyPress from "../../../utils/useKeyPress";
import {
  aiActions,
  diseaseActions,
  productActions,
  accountActions,
} from "../../../store/actions";
import { view } from "./AIView.js";
import { getISOFormattedDateTimeString } from "../../../utils";
import { MAX_RESULTS } from "../../../utils/variables";
import {
  useGetDiseasePrompt,
  useGetProductPrompt,
  useGetAccountPrompt,
  useGetPrompt,
  useGetModels,
} from "../../../store/queries/ai";
import {
  getAISummaryGptQuery,
  getEmailSuggestionsGptQuery,
} from "../../../store/sagas/summary";
import { getGPTParameters } from "../../../store/sagas/aiSagas";

// Example Prompt:
// Write a brief article using bullet points on 'What are healthcare providers discussing in [monitor]' right now?

export const AdminAI = () => {
  const dispatch = useDispatch();
  const enterPress = useKeyPress("Enter");
  const { data: initialModels } = useGetModels();

  // --> mapStateToProp
  const aiUpdate = useSelector((state) => state.ai.update);
  const initialProducts = useSelector((state) => state.product.all);
  const initialDiseases = useSelector((state) => state.disease.all);
  const initialAccounts = useSelector((state) => state.account.all);
  const diseaseResponse = useSelector((state) => state.disease.gpt);
  const productResponse = useSelector((state) => state.product.gpt);
  const accountResponse = useSelector((state) => state.account.gpt);
  // <-- mapStateToProp

  // --> STATE
  const parameterNames = [
    { id: "GPTSummaryQuery", label: "GPTSummaryQuery" },
    { id: "GPTSummaryRole", label: "GPTSummaryRole" },
    { id: "GPTInsightSummaryQuery", label: "GPTInsightSummaryQuery" },
    { id: "GPTInsightTitleQuery", label: "GPTInsightTitleQuery" },
    { id: "GPTMailSummaryQuery", label: "GPTMailSummaryQuery" },
    { id: "GPTMailSummaryRole", label: "GPTMailSummaryRole" },
    { id: "GPTTranscriptSummaryQuery", label: "GPTTranscriptSummaryQuery" },
    { id: "GPTTranscriptSummaryRole", label: "GPTTranscriptSummaryRole" },
    { id: "GPTLanguageDetectionRole", label: "GPTLanguageDetectionRole" },
    { id: "GPTChatRole", label: "GPTChatRole" },
    { id: "SentimentGPTRole", label: "SentimentGPTRole" },
    { id: "GPTRagChatRole_SMA", label: "GPTRagChatRole_SMA" },
    { id: "GPTRagChatRole_HP", label: "GPTRagChatRole_HP" },
    { id: "GPTRagChatRole_CWH", label: "GPTRagChatRole_CWH" },
    { id: "GPTRagChatRole_HPA", label: "GPTRagChatRole_HPA" },
  ];

  const objectTypes = [
    { id: "DISEASE", label: "Disease" },
    { id: "PRODUCT", label: "Product" },
    { id: "ACCOUNT", label: "Account" },
  ];

  // when we show these object types, we do not have to save it
  // 'objectType' is heavily used for saving the prompt
  // hence this new array for prompts that do not require 'objectType' to be saved
  const monitorTypes = [
    { id: "DISEASE", label: "Disease" },
    { id: "PRODUCT", label: "Product" },
  ];

  // user types
  const [userTypes] = useState([
    { id: "DEFAULT", label: "Default" },
    { id: "HCP", label: "Physician" },
    { id: "MARKETING", label: "Marketing" },
    { id: "MEDICAL", label: "Medical Affairs" },
    { id: "SALES", label: "Sales" },
    { id: "OTHER", label: "Other" },
  ]);

  const [response, setResponse] = useState("");
  const [requestTokens, setRequestTokens] = useState(0);
  const [responseTokens, setResponseTokens] = useState(0);
  const [keywords, setKeywords] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [backendError, setBackendError] = useState("");

  const [products, setProducts] = useState([]);
  const [pageProducts, setPageProducts] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const debounceSearchProduct = useDebounce(searchProduct, 700);

  const [diseases, setDiseases] = useState([]);
  const [pageDiseases, setPageDiseases] = useState(0);
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [searchDisease, setSearchDisease] = useState("");
  const debounceSearchDisease = useDebounce(searchDisease, 700);

  const [accounts, setAccounts] = useState([]);
  const [pageAccounts, setPageAccounts] = useState(0);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [searchAccount, setSearchAccount] = useState("");
  const debounceSearchAccount = useDebounce(searchAccount, 700);

  const [isSavePressed, setIsSavePressed] = useState(false);
  const [errors, setErrors] = useState({});
  const [objectType, setObjectType] = useState("DISEASE"); // only for 'GPTSummaryQuery' and 'GPTSummaryRole', SHOULD BE NULL for other parameters
  const [monitorType, setMonitorType] = useState("DISEASE");
  const [userType, setUserType] = useState("DEFAULT");

  const [parameterName, setParameterName] = useState("GPTSummaryQuery");
  const [sortBy, setSortBy] = useState("score");
  const [maxPosts, setMaxPosts] = useState(20);
  const [source, setSource] = useState("X");
  const [models, setModels] = useState([]);
  const [model, setModel] = useState(null);
  const [promptValue, setPromptValue] = useState("");
  const [temperature, setTemperature] = useState(0.5);
  const [topP, setTopP] = useState(1);
  const [frequencyPenalty, setFrequencyPenalty] = useState("0");
  const [presencePenalty, setPresencePenalty] = useState("0");

  const [date, setDate] = useState({
    start: getISOFormattedDateTimeString(moment().subtract(1, "month")._d),
    end: getISOFormattedDateTimeString(moment().endOf("day")._d),
  });

  const [gptParameterVariants, setGptParameterVariants] = useState([]);
  // <-- STATE

  const { data: diseasePrompt, refetch: refetchDiseasePrompt } =
    useGetDiseasePrompt({
      name: parameterName,
      // objectId: selectedDiseases?.[0]?.id,
      category1: model,
      ...(userType !== "DEFAULT" && {category3: userType}),
      enabled: objectType === "DISEASE",
    });
  const { data: productPrompt, refetch: refetchProductPrompt } =
    useGetProductPrompt({
      name: parameterName,
      // objectId: selectedProducts?.[0]?.id,
      category1: model,
      ...(userType !== "DEFAULT" && {category3: userType}),
      enabled: objectType === "PRODUCT",
    });
  const { data: accountPrompt, refetch: refetchAccountPrompt } =
    useGetAccountPrompt({
      name: parameterName,
      // objectId: selectedAccounts?.[0]?.id,
      category1: model,
      ...(userType !== "DEFAULT" && {category3: userType}),
      enabled: objectType === "ACCOUNT",
    });
  const { data: gptPrompt, refetch: refetchGPTPrompt } = useGetPrompt({
    name: parameterName,
    category1: model,
    enabled:
      parameterName !== "GPTSummaryQuery" && parameterName !== "GPTSummaryRole",
  });

  // regenerate result from the new parameter values
  const requestGptQueryResult = async () => {
    setRequestTokens(0);
    setResponseTokens(0);
    setResponse("");
    switch (objectType) {
      case "PRODUCT":
        const monitorProd = selectedProducts[0]?.label;
        if (monitorProd && promptValue) {
          const q = promptValue?.replace("[monitor]", monitorProd);
          if (q) {
            dispatch(
              productActions.gtpRequest({
                id: selectedProducts[0].id,
                startDate: date.start,
                endDate: date.end,
                pageNum: 0,
                sortBy: sortBy,
                maxResult: maxPosts,
                model,
                q,
                ...(source !== "ALL" && { source }),
                ...(userType !== "DEFAULT" && {userType: userType}),
                temperature,
                topP,
                frequencyPenalty,
                presencePenalty,
                timeout: 120,
                nocache: true,
              })
            );
          }
        }
        break;
      case "DISEASE":
        const monitorDis = selectedDiseases[0]?.label;
        if (monitorDis && promptValue) {
          const q = promptValue?.replace("[monitor]", monitorDis);
          if (q) {
            dispatch(
              diseaseActions.gtpRequest({
                id: selectedDiseases[0].id,
                startDate: date.start,
                endDate: date.end,
                pageNum: 0,
                sortBy,
                maxResult: maxPosts,
                model,
                q,
                ...(source !== "ALL" && { source }),
                ...(userType !== "DEFAULT" && {userType: userType}),
                temperature,
                topP,
                frequencyPenalty,
                presencePenalty,
                timeout: 120,
                nocache: true,
              })
            );
          }
        }
        break;
      case "ACCOUNT":
        const accountName = selectedAccounts[0]?.label;
        if (accountName && promptValue) {
          const q = promptValue?.replace("[account]", accountName);
          if (q) {
            dispatch(
              accountActions.gtpRequest({
                id: selectedAccounts[0].id,
                startDate: date.start,
                endDate: date.end,
                pageNum: 0,
                sortBy,
                maxResult: maxPosts,
                originalOnly: false,
                includeReplies: true,
                model,
                q,
                ...(source !== "ALL" && { source }),
                ...(userType !== "DEFAULT" && {userType: userType}),
                temperature,
                topP,
                frequencyPenalty,
                presencePenalty,
                timeout: 120,
                nocache: true,
              })
            );
          }
        }
        break;
    }

    // no objectType for following parmaeters
    if (objectType === null && parameterName === "GPTInsightSummaryQuery") {
      const monitorDis = selectedDiseases[0]?.label;
      if (monitorDis && promptValue) {
        const q = promptValue?.replaceAll("[disease]", monitorDis);
        if (q) {
          const gptResponse = await getAISummaryGptQuery({
            id: selectedDiseases[0].id,
            startDate: date.start,
            endDate: date.end,
            sortBy,
            pageNum: 0,
            maxResult: maxPosts,
            model,
            q,
            ...(source !== "ALL" && { source }),
            temperature,
            topP,
            frequencyPenalty,
            presencePenalty,
            // timeout: 120,
          });
          if (gptResponse?.data?.response) {
            setResponse(gptResponse.data.response);
            setRequestTokens(gptResponse.data.requestTokens);
            setResponseTokens(gptResponse.data.responseTokens);
            const tempKeywords = gptResponse.data.tweetTagMap;
            if (tempKeywords?.length > 0) {
              let responseTags = [];
              for (const key in tempKeywords) {
                const kws = tempKeywords[key]?.map((t) => ({
                  id: t.id,
                  name: t.name,
                }));
                responseTags.push(kws);
              }
              setKeywords(responseTags.flat());
            }
          }
        }
      }
    }

    if (objectType === null && parameterName === "GPTMailSummaryQuery") {
      let monitorName = "";
      let monitorId = "";
      if (monitorType === "DISEASE") {
        monitorName = selectedDiseases[0]?.label;
        monitorId = selectedDiseases[0]?.id;
      } else if (monitorType === "PRODUCT") {
        monitorName = selectedProducts[0]?.label;
        monitorId = selectedProducts[0]?.id;
      }
      if (monitorName !== "" && promptValue) {
        let q = promptValue?.replaceAll("[monitor]", monitorName);
        if (q) {
          const gptResponse = await getEmailSuggestionsGptQuery({
            objectId: monitorId,
            objectType: monitorType,
            ...(userType !== "DEFAULT" && {userType: userType}),
            startDate: date.start,
            endDate: date.end,
            sortBy,
            pageNum: 0,
            maxResult: maxPosts,
            model,
            q,
            ...(source !== "ALL" && { source }),
            temperature,
            topP,
            frequencyPenalty,
            presencePenalty,
            // timeout: 120,
          });
          if (gptResponse?.data?.response) {
            setResponse(gptResponse.data.response);
            setRequestTokens(gptResponse.data.requestTokens);
            setResponseTokens(gptResponse.data.responseTokens);
            const tempKeywords = gptResponse.data.tweetTagMap;
            if (tempKeywords?.length > 0) {
              let responseTags = [];
              for (const key in tempKeywords) {
                const kws = tempKeywords[key]?.map((t) => ({
                  id: t.id,
                  name: t.name,
                }));
                responseTags.push(kws);
              }
              setKeywords(responseTags.flat());
            }
          }
        }
      }
    }
  };

  // // change in disease selection, refresh prompt
  // useEffect(() => {
  //   if (objectType === "DISEASE") refetchDiseasePrompt();
  // }, [selectedDiseases]);

  // // change in product selection, refresh prompt
  // useEffect(() => {
  //   if (objectType === "PRODUCT") refetchProductPrompt();
  // }, [selectedProducts]);

  useEffect(() => {
    if (initialModels?.data?.length > 0) {
      const tempModels = initialModels.data.map((item) => ({
        id: item.category1,
        label: item.category1,
        default: item.defaultModel,
      }));
      setModels(tempModels);
      setModel(tempModels.find((m) => m.default)?.id || tempModels[0].id);
    }
  }, [initialModels]);

  useEffect(() => {
    // clear fields
    setPromptValue("");
    setResponse("");
    setRequestTokens(0);
    setResponseTokens(0);
    // if 'GPTSummaryQuery' || 'GPTSummaryRole', objectType cannot be null
    if (
      parameterName === "GPTSummaryQuery" ||
      parameterName === "GPTSummaryRole"
    ) {
      if (parameterName === "GPTSummaryRole") {
        clearSelectedProducts();
        clearSelectedDiseases();
        clearSelectedAccounts();
      }
      if (objectType === "PRODUCT") {
        refetchProductPrompt();
      } else if (objectType === "DISEASE") {
        refetchDiseasePrompt();
      } else if (objectType === "ACCOUNT") {
        refetchAccountPrompt();
      } else if (objectType === null) {
        setObjectType("DISEASE");
        refetchDiseasePrompt();
      }
    } else {
      // else objectType is to be ignored
      setObjectType(null);
      clearSelectedProducts();
      clearSelectedDiseases();
      clearSelectedAccounts();
      refetchGPTPrompt();
    }
  }, [parameterName]);

  useEffect(() => {
    if (parameterName === "GPTSummaryQuery" || parameterName === "GPTSummaryRole") {
      if (objectType === "PRODUCT") {
        refetchProductPrompt();
      } else if (objectType === "DISEASE") {
        refetchDiseasePrompt();
      } else if (objectType === "ACCOUNT") {
        refetchAccountPrompt();
      } else if (objectType === null) {
        setObjectType("DISEASE");
        refetchDiseasePrompt();
      }
    }
  }, [userType]);

  // any change in model, refresh prompt
  useEffect(() => {
    if (model === "") return;
    if (objectType === "PRODUCT") {
      refetchProductPrompt();
    } else if (objectType === "DISEASE") {
      refetchDiseasePrompt();
    } else if (objectType === "ACCOUNT") {
      refetchAccountPrompt();
    } else {
      refetchGPTPrompt();
    }
  }, [model]);

  useEffect(() => {
    setPageProducts(0);
    requestProducts(0);
  }, [debounceSearchProduct]);

  useEffect(() => {
    if (initialProducts.data.length > 0) {
      let newProducts = initialProducts.data.map((item) => ({
        id: item.id,
        label: item.primaryName,
      }));
      setProducts(newProducts);
    }
  }, [initialProducts.data]);

  // useEffect(() => {
  //   if (aiUpdate.success) {
  //     setShowToast(true);
  //   }
  // }, [aiUpdate]);

  useEffect(() => {
    setPageDiseases(0);
    requestDiseases(0);
  }, [debounceSearchDisease]);

  useEffect(() => {
    if (initialDiseases.data.length > 0) {
      let newDiseases = initialDiseases.data.map((item) => ({
        id: item.id,
        label: item.primaryName,
      }));
      setDiseases(newDiseases);
    }
  }, [initialDiseases.data]);

  useEffect(() => {
    setPageAccounts(0);
    requestAccounts(0);
  }, [debounceSearchAccount]);

  useEffect(() => {
    if (initialAccounts.data.length > 0) {
      let newAccounts = initialAccounts.data.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      setAccounts(newAccounts);
    }
  }, [initialAccounts.data]);

  useEffect(() => {
    if (gptPrompt?.data?.value) {
      setPromptValue(gptPrompt.data.value);
    }
  }, [gptPrompt]);

  useEffect(() => {
    if (diseasePrompt?.data?.value) {
      setPromptValue(diseasePrompt.data.value);
    } else {
      setPromptValue("");
    }
  }, [diseasePrompt]);

  useEffect(() => {
    if (productPrompt?.data?.value) {
      setPromptValue(productPrompt.data.value);
    } else {
      setPromptValue("");
    }
  }, [productPrompt]);

  useEffect(() => {
    if (accountPrompt?.data?.value) {
      setPromptValue(accountPrompt.data.value);
    } else {
      setPromptValue("");
    }
  }, [accountPrompt]);

  useEffect(() => {
    if (objectType === "DISEASE") {
      // clear selected products, if any
      if (selectedProducts.length > 0) {
        clearSelectedProducts();
      }
      if (selectedAccounts.length > 0) {
        clearSelectedAccounts();
      }
      // refresh prompt
      refetchDiseasePrompt();
    } else if (objectType === "PRODUCT") {
      // clear selected diseases, if any
      if (selectedDiseases.length > 0) {
        clearSelectedDiseases();
      }
      if (selectedAccounts.length > 0) {
        clearSelectedAccounts();
      }
      // refresh prompt
      refetchProductPrompt();
    } else if (objectType === "ACCOUNT") {
      clearSelectedProducts();
      clearSelectedDiseases();
      // refresh prompt
      refetchAccountPrompt();
    }
  }, [objectType]);

  useEffect(() => {
    clearSelectedProducts();
    clearSelectedDiseases();
  }, [monitorType]);

  const handleSave = () => {
    setIsSavePressed(true);
    if (isEmpty(errors)) {
      switch (objectType) {
        case "PRODUCT":
          dispatch(
            aiActions.update({
              name: parameterName,
              objectType: "PRODUCT",
              objectId: null,
              // ...(selectedProducts[0]?.id && {
              //   objectId: selectedProducts[0].id,
              // }),
              category1: model,
              ...(userType !== "DEFAULT" && {category3: userType}),
              value: promptValue,
            })
          );
          break;
        case "DISEASE":
          dispatch(
            aiActions.update({
              name: parameterName,
              objectType: "DISEASE",
              objectId: null,
              // ...(selectedDiseases[0]?.id && {
              //   objectId: selectedDiseases[0].id,
              // }),
              category1: model,
              ...(userType !== "DEFAULT" && {category3: userType}),
              value: promptValue,
            })
          );
          break;
        case "ACCOUNT":
          dispatch(
            aiActions.update({
              name: parameterName,
              objectType: "ACCOUNT",
              objectId: null,
              category1: model,
              ...(userType !== "DEFAULT" && {category3: userType}),
              value: promptValue,
            })
          );
          break;
        default:
          dispatch(
            aiActions.update({
              name: parameterName,
              objectType: null,
              objectId: null,
              category1: model,
              category3: null,
              value: promptValue,
            })
          );
          break;
      }

      // show toast, if successful
      setTimeout(() => {
        if (aiUpdate.success) {
          setShowToast(true);
        }
      }, 1000);
    }
    setIsSavePressed(false);
  };

  // regenerate result
  const handleRegenerate = async () => {
    setShowErrorToast(false);
    const hasId =
      selectedDiseases[0]?.id ||
      selectedProducts[0]?.id ||
      selectedAccounts[0]?.id;
    if (hasId && isEmpty(errors)) {
      requestGptQueryResult();
    }
  };

  useEffect(() => {
    if (enterPress) handleSave();
  }, [enterPress]);

  const requestProducts = (pageNum) => {
    dispatch(
      productActions.request({
        ignore: false,
        ...(searchProduct !== "" && { term: searchProduct }),
        maxResult: MAX_RESULTS,
        pageNum,
      })
    );
  };

  const handleMoreProducts = () => {
    if (!initialProducts.fetching) {
      const productsNewPage = pageProducts + 1;
      setPageProducts(productsNewPage);
      requestProducts(productsNewPage);
    }
  };

  const addSelectedProducts = (item) => {
    setSelectedProducts([
      {
        id: item.id,
        label: item.label,
      },
    ]);
  };

  const clearSelectedProducts = () => {
    setSelectedProducts([]);
    setSearchProduct("");
  };

  const requestDiseases = (pageNum) => {
    dispatch(
      diseaseActions.request({
        ignore: false,
        ...(searchDisease !== "" && { term: searchDisease }),
        maxResult: MAX_RESULTS,
        pageNum,
      })
    );
  };

  const handleMoreDiseases = () => {
    if (!initialDiseases.fetching) {
      const diseasesNewPage = pageDiseases + 1;
      setPageDiseases(diseasesNewPage);
      requestDiseases(diseasesNewPage);
    }
  };

  const addSelectedDiseases = (item) => {
    setSelectedDiseases([
      {
        id: item.id,
        label: item.label,
      },
    ]);
  };

  const clearSelectedDiseases = () => {
    setSelectedDiseases([]);
    setSearchDisease("");
  };

  const requestAccounts = (pageNum) => {
    dispatch(
      accountActions.request({
        forIngestion: "true",
        maxResult: MAX_RESULTS,
        ...(searchAccount !== "" && { term: searchAccount }),
        pageNum,
      })
    ); // HCPs & Institutions
  };

  const handleMoreAccounts = () => {
    if (!initialAccounts.fetching) {
      const accountsNewPage = pageAccounts + 1;
      setPageAccounts(accountsNewPage);
      requestAccounts(accountsNewPage);
    }
  };

  const addSelectedAccounts = (item) => {
    setSelectedAccounts([
      {
        id: item.id,
        label: item.label,
      },
    ]);
  };

  const clearSelectedAccounts = () => {
    setSelectedAccounts([]);
    setSearchAccount("");
  };

  const handleDateChange = (e) => {
    setDate({
      start: getISOFormattedDateTimeString(e.startDate),
      end: getISOFormattedDateTimeString(e.endDate),
    });
  };

  const handleAiRefresh = () => {
    requestGptQueryResult();
  };

  const handleSortByChange = (item) => {
    setSortBy(item.id);
  };

  const handleModelChange = (item) => {
    setModel(item.id);
  };

  const handleParameterNameChange = (item) => {
    setParameterName(item.id);
    setGptParameterVariants([]); // clear previous variants
  };

  const handleUserTypeChange = (item) => {
    setUserType(item.id);
  };

  const handleTemperatureChange = (item) => {
    setTemperature(item.id);
  };

  const handleMaxPostsChange = (item) => {
    setMaxPosts(item.id);
  };

  const handleSourceChange = (item) => {
    setSource(item.id);
  };

  const handleTopPChange = (item) => {
    setTopP(item.id);
  };

  const handleFrequencyPenaltyChange = (item) => {
    setFrequencyPenalty(item.id);
  };

  const handlePresencePenaltyChange = (item) => {
    setPresencePenalty(item.id);
  };

  const getGPTParameterVariants = async (isDefault) => {
    const response = await getGPTParameters({name: parameterName, ...(!isDefault && {category1: "all"})});
    if (response?.data) {
      setGptParameterVariants(response.data);
    }
  };

  // only for 'GPTSummaryQuery'
  useEffect(() => {
    let tempKeywords = [];
    if (diseaseResponse?.data?.response && objectType === "DISEASE") {
      setResponse(diseaseResponse.data.response);
      setRequestTokens(diseaseResponse.data.requestTokens);
      setResponseTokens(diseaseResponse.data.responseTokens);
      tempKeywords = diseaseResponse.data.tweetTagMap;
    } else if (productResponse?.data?.response && objectType === "PRODUCT") {
      setResponse(productResponse.data.response);
      setRequestTokens(productResponse.data.requestTokens);
      setResponseTokens(productResponse.data.responseTokens);
      tempKeywords = productResponse.data.tweetTagMap;
    } else if (accountResponse?.data?.response && objectType === "ACCOUNT") {
      setResponse(accountResponse.data.response);
      setRequestTokens(accountResponse.data.requestTokens);
      setResponseTokens(accountResponse.data.responseTokens);
      tempKeywords = accountResponse.data.tweetTagMap;
    }
    if (tempKeywords.length > 0) {
      let responseTags = [];

      for (const key in tempKeywords) {
        const kws = tempKeywords[key]?.map((t) => ({
          id: t.id,
          name: t.name,
        }));
        responseTags.push(kws);
      }
      setKeywords(responseTags.flat());
    }
  }, [diseaseResponse?.data, productResponse?.data, accountResponse?.data]);

  // only for 'GPTSummaryQuery'
  useEffect(() => {
    if (
      diseaseResponse?.error ||
      productResponse?.error ||
      accountResponse?.error
    ) {
      setBackendError(
        diseaseResponse?.error?.response?.data?.message ||
          productResponse?.error?.response?.data?.message ||
          accountResponse?.error?.response?.data?.message ||
          "Error while generating summary"
      );
      setShowErrorToast(true);
    }
  }, [diseaseResponse?.error, productResponse?.error, accountResponse?.error]);

  return view({
    parameterNames,
    objectTypes,

    errors,
    isSavePressed,
    handleSave,

    parameterName,
    handleParameterNameChange,

    promptValue,
    setPromptValue,

    objectType,
    setObjectType,

    monitorTypes,
    monitorType,
    setMonitorType,

    userTypes,
    userType,
    handleUserTypeChange,

    handleRegenerate,
    showRegenerate:
      selectedDiseases[0]?.id ||
      selectedProducts[0]?.id ||
      selectedAccounts[0]?.id,
    showToast,
    setShowToast,

    backendError,
    showErrorToast,
    setShowErrorToast,

    model,
    models,
    handleModelChange,

    diseases,
    setSearchDisease,
    searchDisease,
    selectedDiseases,
    addSelectedDiseases,
    clearSelectedDiseases,
    diseasesInfiniteScroll: {
      loading: initialDiseases.fetching,
      hasNextPage: !initialDiseases.isLastPage,
      onLoadMore: handleMoreDiseases,
    },

    products,
    setSearchProduct,
    searchProduct,
    selectedProducts,
    addSelectedProducts,
    clearSelectedProducts,
    productsInfiniteScroll: {
      loading: initialProducts.fetching,
      hasNextPage: !initialProducts.isLastPage,
      onLoadMore: handleMoreProducts,
    },

    accounts,
    setSearchAccount,
    searchAccount,
    selectedAccounts,
    addSelectedAccounts,
    clearSelectedAccounts,
    accountsInfiniteScroll: {
      loading: initialAccounts.fetching,
      hasNextPage: !initialAccounts.isLastPage,
      onLoadMore: handleMoreAccounts,
    },

    date,
    handleDateChange,

    sortBy,
    handleSortByChange,

    temperature,
    handleTemperatureChange,

    maxPosts,
    handleMaxPostsChange,

    source,
    handleSourceChange,

    topP,
    handleTopPChange,

    frequencyPenalty,
    handleFrequencyPenaltyChange,

    presencePenalty,
    handlePresencePenaltyChange,

    gptParameterVariants,
    getGPTParameterVariants,

    handleAiRefresh,
    response,
    requestTokens,
    responseTokens,
    keywords,
    fetchingResponse:
      diseaseResponse?.fetching ||
      productResponse?.fetching ||
      accountResponse?.fetching,
  });
};
