import React from "react";
import { Link } from "react-router-dom";

import {
  Row,
  Column,
  Avatar,
  Anchor,
  MainWrapper,
  Header,
  Content,
  Label,
  ResponsiveButton,
  Filters,
  Input,
  List,
  ListItem,
  Dropdown,
  Icon,
  P,
  Grid,
  Card,
  Spinner,
} from "../../../components";

import { Menu } from "../Menu";
import { LineChart } from "../../../components/LineChart";

export const view = ({
  accounts,
  accountsCount,
  accountsRef,
  loading,
  searchTerm,
  handleSearchTermChange,
  activeFilters,

  typeOptions,
  type,
  handleTypeChange,

  subtypeOptions,
  subtype,
  handleSubtypeChange,

  sortOrderOptions,
  sortOrder,
  handleSortOrderChange,

  forIngestionOptions,
  forIngestion,
  handleForIngestionChange,

  externalId,
  handleExternalIdChange,

  npiId,
  handleNpiIdChange,

  countryCode,
  handleCountryCodeChange,

  chartLoading,
  timeSegmentedSummary,
}) => {
  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
            <ResponsiveButton label="Add Account" to="/newAccount" />
          </>
        }
      />
      <Menu active="accounts" />

      <Content>
        <Column>
          <Filters counter={`${accountsCount} Accounts`} {...{ activeFilters }}>
            <Grid columns={4}>
              <Column gap="10">
                <Label bold>Account Type</Label>
                <Dropdown
                  expand
                  select
                  value={type}
                  items={typeOptions}
                  onChange={(e) => handleTypeChange(e)}
                />
              </Column>
              <Column gap="10">
                <Label bold>Name</Label>
                <Input
                  small
                  placeholder="Search"
                  icon="search"
                  value={searchTerm}
                  onChange={(e) => handleSearchTermChange(e.target.value)}
                />
              </Column>
              <Column gap="10">
                <Label bold>Account Subtype</Label>
                <Dropdown
                  expand
                  select
                  value={subtype}
                  items={subtypeOptions}
                  onChange={(e) => handleSubtypeChange(e)}
                />
              </Column>
              <Column gap="10">
                <Label bold>Sort Order</Label>
                <Dropdown
                  expand
                  select
                  value={sortOrder}
                  items={sortOrderOptions}
                  onChange={(e) => handleSortOrderChange(e)}
                />
              </Column>
              <Column gap="10">
                <Label bold>For Ingestion</Label>
                <Dropdown
                  expand
                  select
                  value={forIngestion}
                  items={forIngestionOptions}
                  onChange={(e) => handleForIngestionChange(e)}
                />
              </Column>
              <Column gap="10">
                <Label bold>External Id</Label>
                <Input
                  small
                  placeholder="External Id"
                  icon="search"
                  value={externalId}
                  onChange={(e) => handleExternalIdChange(e.target.value)}
                />
              </Column>
              <Column gap="10">
                <Label bold>NPI Id</Label>
                <Input
                  small
                  placeholder="NPI Id"
                  icon="search"
                  value={npiId}
                  onChange={(e) => handleNpiIdChange(e.target.value)}
                />
              </Column>
              <Column gap="10">
                <Label bold>Country Code</Label>
                <Input
                  small
                  placeholder="e.g., us"
                  icon="search"
                  value={countryCode}
                  onChange={(e) => handleCountryCodeChange(e.target.value)}
                />
              </Column>
            </Grid>
          </Filters>

          <Column marginBottom marginTop>
            {timeSegmentedSummary.length > 0 ? (
              <Card border>
                <LineChart
                  id={`lineChart`}
                  data={timeSegmentedSummary}
                  handleNameClick={() => {}}
                />
              </Card>
            ) : (
              <>
                {chartLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column>

          {accounts.length > 0 ? (
            <Column marginTop ref={accountsRef} maxWidth="700">
              {accounts.map((account, i) => (
                <Card border key={account.id}>
                  <Row gap="10" middle marginBottom>
                    <Row fit>
                      <Link to={`/account/${account.id}?source=list`}>
                        <Row gap="10" middle fit>
                          <Avatar
                            imageUrl={account.imageURL || null}
                            name={account.name}
                          />
                          <Label clickable large bold>
                            <Link to={`/account/${account.id}?source=list`}>
                              {account.name}
                            </Link>
                          </Label>
                        </Row>
                      </Link>
                    </Row>
                    <Anchor
                      bold
                      label="Edit"
                      to={`/editAccount/${account.id}`}
                    />
                  </Row>
                  <Row gap="6" middle marginBottom="10">
                    <Icon size="20" name="twitter" color="twitter" />
                    <Anchor
                      color="twitter"
                      label={`@${account.username}`}
                      to={`https://twitter.com/${account.username}`}
                      blank
                    />
                  </Row>
                  {account.description && <P>{account.description}</P>}
                  {account.institution?.name && (
                    <>
                      <hr />
                      <Row marginTop="10">
                        <Label>Institution:&nbsp;</Label>
                        <Label bold>{account.institution?.name}</Label>
                      </Row>
                      <Row marginTop="10" gap="30">
                        {account.institution?.location && (
                          <Row>
                            <Label>Location:&nbsp;</Label>
                            <Label bold>{account.institution?.location}</Label>
                          </Row>
                        )}
                        {account.institution?.city && (
                          <Row>
                            <Label>City:&nbsp;</Label>
                            <Label bold>{account.institution?.city}</Label>
                          </Row>
                        )}
                        {account.institution?.county && (
                          <Row>
                            <Label>County:&nbsp;</Label>
                            <Label bold>{account.institution?.county}</Label>
                          </Row>
                        )}
                      </Row>
                      <Row marginTop="10" marginBottom="10" gap="30">
                        {account.institution?.stateProvince && (
                          <Row>
                            <Label>State:&nbsp;</Label>
                            <Label bold>
                              {account.institution?.stateProvince}
                            </Label>
                          </Row>
                        )}
                        {account.institution?.country && (
                          <Row>
                            <Label>Country:&nbsp;</Label>
                            <Label bold>{account.institution?.country}</Label>
                          </Row>
                        )}
                        {account.institution?.postalCode && (
                          <Row>
                            <Label>Postal Code:&nbsp;</Label>
                            <Label bold>
                              {account.institution?.postalCode}
                            </Label>
                          </Row>
                        )}
                      </Row>
                      <hr />
                    </>
                  )}
                  <Column marginTop="10" stretch>
                    <Row gap="30">
                      {account.location && (
                        <Row>
                          <Label>Location:&nbsp;</Label>
                          <Label bold>{account.location}</Label>
                        </Row>
                      )}
                      {account.city && (
                        <Row>
                          <Label>City:&nbsp;</Label>
                          <Label bold>{account.city}</Label>
                        </Row>
                      )}
                      {account.county && (
                        <Row>
                          <Label>County:&nbsp;</Label>
                          <Label bold>{account.county}</Label>
                        </Row>
                      )}
                      {/* {account.region && (
                        <Row>
                          <Label>Region:&nbsp;</Label>
                          <Label bold>{account.region}</Label>
                        </Row>
                      )} */}
                    </Row>
                    <Row marginTop="10" gap="30">
                      {account.stateProvince && (
                        <Row>
                          <Label>State:&nbsp;</Label>
                          <Label bold>{account.stateProvince}</Label>
                        </Row>
                      )}
                      {account.country && (
                        <Row>
                          <Label>Country:&nbsp;</Label>
                          <Label bold>{account.country}</Label>
                        </Row>
                      )}
                      {account.postalCode && (
                        <Row>
                          <Label>Postal Code:&nbsp;</Label>
                          <Label bold>{account.postalCode}</Label>
                        </Row>
                      )}
                    </Row>
                    <Row marginTop="10" gap="30">
                      {account.numFollowers !== 0 && (
                        <Row>
                          <Label>Followers:&nbsp;</Label>
                          <Label bold>{account.numFollowers}</Label>
                        </Row>
                      )}
                      {account.numFollowing !== 0 && (
                        <Row>
                          <Label>Following:&nbsp;</Label>
                          <Label bold>{account.numFollowing}</Label>
                        </Row>
                      )}
                      {account.numTweets !== 0 && (
                        <Row>
                          <Label>Tweets:&nbsp;</Label>
                          <Label bold>{account.numTweets}</Label>
                        </Row>
                      )}
                      {account.numListed !== 0 && (
                        <Row>
                          <Label>Listed:&nbsp;</Label>
                          <Label bold>{account.numListed}</Label>
                        </Row>
                      )}
                    </Row>
                    <Row marginTop="10" gap="30">
                      <Row>
                        <Label>Account Type:&nbsp;</Label>
                        <Label bold>{account.accountType}</Label>
                      </Row>
                      <Row>
                        <Label>Account Subtype:&nbsp;</Label>
                        <Label bold>{account.accountSubtype}</Label>
                      </Row>
                    </Row>
                    <Row marginTop="10" gap="30">
                      <Row middle>
                        <Label>For Ingestion:&nbsp;</Label>
                        <Icon
                          size={account.forIngestion ? 34 : 20}
                          color={account.forIngestion ? "green" : "red"}
                          name={account.forIngestion ? "check" : "close"}
                        />
                      </Row>
                      <Row middle>
                        <Label>Verified:&nbsp;</Label>
                        <Icon
                          size={account.verified ? 34 : 20}
                          color={account.verified ? "green" : "red"}
                          name={account.verified ? "check" : "close"}
                        />
                      </Row>
                    </Row>
                    <Row marginTop="10">
                      {account.url && (
                        <Anchor label="Website" to={`${account.url}`} blank />
                      )}
                    </Row>
                    <Row marginTop="10">
                      {account.lastImported && (
                        <Row middle>
                          <Label color="gray" fade="7" small>
                            Last Imported:&nbsp;
                          </Label>
                          <Label color="gray" fade="7" small bold>
                            {new Date(account.lastImported).toLocaleString(
                              "en-US"
                            )}
                          </Label>
                        </Row>
                      )}
                    </Row>
                  </Column>
                </Card>
              ))}
              {loading && (
                <Row expand paddingAll center>
                  <Spinner />
                </Row>
              )}
            </Column>
          ) : (
            <>
              {loading ? (
                <Row expand paddingAll="80" center>
                  <Spinner />
                </Row>
              ) : (
                <List center>
                  <ListItem>No Accounts found</ListItem>
                </List>
              )}
            </>
          )}
        </Column>
      </Content>
    </MainWrapper>
  );
};
