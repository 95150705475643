// this page should be deprecated

// import { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import moment from "moment";
// import _ from "lodash";

// import {
//   diseaseActions,
//   conferenceActions,
//   tweetActions,
// } from "../../store/actions";
// import {
//   getUrlParams,
//   logAnalyticEvent,
//   getISOFormattedDateTimeString,
//   idToText,
//   textToId,
//   months,
// } from "../../utils";
// import { view } from "./InfluencersView";

export const Influencers = () => {
  window.location.href = "/influencers/disease";
  // const dispatch = useDispatch();
  // const location = useLocation();

  // const { type, name, month, year } = useParams();
  // const date = new Date();
  // const currentMonth = months[date.getMonth() - 1];
  // const currentYear = date.getFullYear();

  // // --> mapStateToProp
  // const auth = useSelector((state) => state.auth.data);
  // const userPlans = useSelector((state) => state.plan.userPlans);
  // const initialTags = useSelector((state) => state.disease.tags);
  // const initialDiseaseDols = useSelector((state) => state.disease.dol);
  // const initialConferenceDols = useSelector((state) => state.conference.dol);
  // const tweet = useSelector((state) => state.tweet.all.data?.[0]);
  // const initialDisease = useSelector((state) => state.disease.one.data);
  // const conferences = useSelector((state) => state.conference.all.data);
  // // <-- mapStateToProp

  // // --> STATE
  // const [queryParams] = useState(getUrlParams(location.search));
  // const [tags, setTags] = useState([]);
  // const [selectedTags, setSelectedTags] = useState([]);
  // const [selectedItem, setSelectedItem] = useState({
  //   type: "disease",
  //   label: "",
  //   name: "",
  //   id: "",
  //   tagId: "",
  //   month: currentMonth,
  //   year: currentYear,
  // });
  // const [hcps, setHcps] = useState([]);
  // const [sortTableBy, setSortTableBy] = useState("mentions");
  // const [sortDirection, setSortDirection] = useState("asc");
  // const [filteredItem, setFilteredItem] = useState("");
  // const [authorId, setAuthorId] = useState("");
  // // <-- STATE

  // useEffect(() => {
  //   requestTags();
  //   requestConferences();
  // }, []);

  // useEffect(() => {
  //   if (name) {
  //     if (type === "disease") {
  //       if (tags?.length > 0) {
  //         const item = tags.find((tag) => idToText(tag.name) === name);
  //         item && dispatch(diseaseActions.oneRequest(item?.diseaseId));
  //       }
  //     } else if (type === "conference") {
  //       const item = conferences.find(
  //         (c) => idToText(c.primaryName) === name.toLowerCase()
  //       );
  //       if (item) {
  //         setSelectedItem({
  //           label: idToText(item.primaryName),
  //           name: item.primaryName,
  //           id: item.id,
  //           tagId: item.tagId,
  //           parentTagId: item.parentTagId,
  //           parentTagName: item.parentTagName,
  //           type: "conference",
  //           month: month || currentMonth,
  //           year: year || currentYear,
  //           startDate: getISOFormattedDateTimeString(item.monitorStartTime),
  //           endDate: getISOFormattedDateTimeString(
  //             moment(item.monitorEndTime).add(1, "weeks")._d
  //           ),
  //         });
  //       }
  //     }
  //   } else {
  //     if (tags?.[0]) {
  //       dispatch(diseaseActions.oneRequest(tags[0].diseaseId));
  //       window.history.replaceState(
  //         null,
  //         "Top Influencers",
  //         `/top_influencers/disease/${idToText(tags[0].name)}/${
  //           month || currentMonth
  //         }/${year || currentYear}`
  //       );
  //     }
  //   }
  // }, [name, tags, conferences]);

  // useEffect(() => {
  //   if (initialDisease?.id) {
  //     const tempData = {
  //       label: idToText(initialDisease.primaryName),
  //       name: initialDisease.primaryName,
  //       id: initialDisease.id,
  //       tagId: initialDisease.tagId,
  //       type: "disease",
  //       month: month || currentMonth,
  //       year: year || currentYear,
  //     };
  //     setSelectedItem(tempData);
  //   }
  // }, [initialDisease]);

  // useEffect(() => {
  //   if (selectedItem.id) {
  //     let endDate = moment(`${selectedItem.month} ${selectedItem.year}`).endOf(
  //       "month"
  //     )._d;
  //     if (moment().diff(endDate, "days") <= 0) {
  //       endDate = moment().subtract(1, "days").endOf("day")._d;
  //     }
  //     // fetch dols
  //     if (selectedItem.type === "disease") {
  //       requestDiseaseDol({
  //         startDate: getISOFormattedDateTimeString(
  //           moment(`${selectedItem.month} ${selectedItem.year}`)._d
  //         ),
  //         endDate: getISOFormattedDateTimeString(endDate),
  //       });
  //     } else {
  //       requestConferenceDol({
  //         startDate: selectedItem.startDate,
  //         endDate: selectedItem.endDate,
  //       });
  //     }

  //     // log events
  //     logAnalyticEvent(
  //       "Landing - Top influencers",
  //       {
  //         objectId: selectedItem.id,
  //         tagId: selectedItem.tagId,
  //         name: selectedItem.name,
  //         type: selectedItem.type,
  //         period: selectedItem.month + " " + selectedItem.year,
  //       },
  //       auth?.id || null,
  //       auth?.displayName || null,
  //       auth?.email || null,
  //       auth?.createdAt || null,
  //       auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
  //         ?.preferenceStringValue || "MEDICAL",
  //       userPlans?.data?.[0]?.plan?.name || null
  //     );
  //   }
  // }, [selectedItem.id]);

  // useEffect(() => {
  //   let newTags = initialTags.data.map((tag) => {
  //     return {
  //       ...tag,
  //       label: tag.preferredTagName || tag.name,
  //       id: tag.diseaseId || tag.preferredTagId || tag.id,
  //       suggestion: !!tag.preferredTagId,
  //     };
  //   });
  //   newTags = newTags.filter((t) => t.diseaseId || t.suggestion);
  //   setTags(newTags);
  // }, [initialTags.data]);

  // const getHcps = () => {
  //   let people =
  //     selectedItem.type === "disease"
  //       ? initialDiseaseDols.data.filter(
  //           (hcp) => (hcp.account.accountType = "PERSON")
  //         )
  //       : initialConferenceDols.data.filter(
  //           (hcp) => (hcp.account.accountType = "PERSON")
  //         );

  //   const tempHcps = people.map((hcp, i) => {
  //     return {
  //       ...hcp.account,
  //       label: hcp.account.name,
  //       mentions: hcp.mentions,
  //       institution: hcp.account.institutionName,
  //     };
  //   });

  //   const sortName = [
  //     (a) =>
  //       typeof a[sortTableBy] === "string"
  //         ? a[sortTableBy]?.toLowerCase()
  //         : a[sortTableBy],
  //   ];
  //   const sortedItems =
  //     sortDirection === "desc"
  //       ? _.sortBy(tempHcps, sortName)
  //       : _.sortBy(tempHcps, sortName).reverse();
  //   const items = sortedItems.slice(0, 10).map((item, i) => {
  //     return {
  //       ...item,
  //       index: i + 1,
  //     };
  //   });
  //   return items;
  // };

  // useEffect(() => {
  //   if (authorId) {
  //     let endDate = moment(`${selectedItem.month} ${selectedItem.year}`).endOf(
  //       "month"
  //     )._d;
  //     if (moment().diff(endDate, "days") <= 0) {
  //       endDate = moment().subtract(1, "days").endOf("day")._d;
  //     }
  //     if (selectedItem.type === "disease") {
  //       requestDiseaseTweets({
  //         startDate: getISOFormattedDateTimeString(
  //           moment(`${selectedItem.month} ${selectedItem.year}`)._d
  //         ),
  //         endDate: getISOFormattedDateTimeString(endDate),
  //       });
  //     } else {
  //       requestConferenceTweets({
  //         startDate: selectedItem.startDate,
  //         endDate: selectedItem.endDate,
  //       });
  //     }
  //   }
  // }, [authorId]);

  // useEffect(() => {
  //   if (
  //     initialDiseaseDols?.data?.length > 0 ||
  //     initialConferenceDols?.data?.length > 0
  //   ) {
  //     setHcps(getHcps());
  //     setAuthorId(getHcps()?.[0]?.externalId);
  //   } else {
  //     setHcps([]);
  //     setAuthorId("");
  //   }
  // }, [initialDiseaseDols?.data, initialConferenceDols?.data]);

  // useEffect(() => {
  //   setHcps(getHcps());
  // }, [sortTableBy, sortDirection]);

  // const requestDiseaseTweets = ({ startDate, endDate }) => {
  //   dispatch(
  //     tweetActions.request({
  //       addTagIds: [selectedItem.tagId],
  //       externalId: authorId,
  //       ...(auth?.id && { userId: auth.id }),
  //       startDate,
  //       endDate,
  //       sortBy: "score",
  //       sortOrder: "desc",
  //       maxResult: 1,
  //       pageNum: 0,
  //     })
  //   );
  // };

  // const requestConferenceTweets = ({ startDate, endDate }) => {
  //   dispatch(
  //     tweetActions.request({
  //       addTagIds: [selectedItem.tagId, selectedItem.parentTagId],
  //       externalId: authorId,
  //       ...(auth?.id && { userId: auth.id }),
  //       originalOnly: false,
  //       includeReplies: true,
  //       startDate,
  //       endDate,
  //       sortBy: "score",
  //       sortOrder: "desc",
  //       maxResult: 1,
  //       pageNum: 0,
  //       includeDiseasesForConference: true,
  //     })
  //   );
  // };

  // const requestDiseaseDol = ({ startDate, endDate }) => {
  //   dispatch(
  //     diseaseActions.dolRequest({
  //       id: selectedItem.id,
  //       startDate,
  //       endDate,
  //     })
  //   );
  // };

  // const requestConferenceDol = ({ startDate, endDate }) => {
  //   dispatch(
  //     conferenceActions.dolRequest({
  //       id: selectedItem.id,
  //       startDate,
  //       endDate,
  //     })
  //   );
  // };

  // const requestTags = () => {
  //   dispatch(
  //     diseaseActions.tagsRequest({
  //       ignore: false,
  //     })
  //   );
  // };

  // const requestConferences = () => {
  //   dispatch(
  //     conferenceActions.selectedRequest({
  //       monitorStartDate: getISOFormattedDateTimeString(
  //         getISOFormattedDateTimeString(moment().subtract(6, "months")._d)
  //       ),
  //     })
  //   );
  // };

  // const handleSignUpClick = () => {
  //   // check for cookies first before publishing event
  //   if (!auth?.active && cookies?.seen && !cookies?.accepted) {
  //     return;
  //   }
  //   // call common helper function
  //   logAnalyticEvent(
  //     "Sign Up",
  //     {
  //       page: "Influencers",
  //       ...(queryParams?.source && { source: queryParams.source }),
  //     },
  //     queryParams?.u || null,
  //     queryParams?.n || null,
  //     queryParams?.e || null,
  //     null,
  //     queryParams?.t || null
  //   );
  // };

  // const handleFilterItems = (e) => {
  //   setFilteredItem(e.target.value);
  // };

  // return view({
  //   handleSignUpClick,
  //   tags,
  //   selectedItem,
  //   sortTableBy,
  //   setSortTableBy,
  //   sortDirection,
  //   setSortDirection,
  //   hcps,
  //   filteredItem,
  //   handleFilterItems,
  //   month: month || currentMonth,
  //   year: year || currentYear,
  //   tweet,
  //   conferences: conferences || [],
  //   isLoading: initialDiseaseDols?.fetching || initialConferenceDols?.fetching,
  // });
  return null;
};
