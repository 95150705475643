import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

import useDebounce from "../../../utils/useDebounce";
import {
  accountActions,
  settingsActions,
  commonActions,
} from "../../../store/actions";
import { cancelAccountRequests } from "../../../store/sagas";
import { getTimeSegmentedSummary } from "../../../store/sagas/accountSagas";
import { view } from "./AccountsView";

export const AdminAccounts = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // --> mapStateToProp
  const accountsSettings = useSelector((state) => state.settings?.accounts);
  const accounts = useSelector((state) => state.account.all);
  const accountsCount = useSelector((state) => state.account.count);
  const accountSubtypeState = useSelector(
    (state) => state.common.accountSubtypes
  );
  // <-- mapStateToProp

  // --> STATE
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState(accountsSettings?.searchTerm);
  const debounceTerm = useDebounce(searchTerm, 700);
  const [typeOptions] = useState([
    { id: "PERSON", label: "Person" },
    { id: "INSTITUTION", label: "Institution" },
    { id: "UNKNOWN,OTHER", label: "Unknown" },
  ]);
  const [type, setType] = useState(accountsSettings?.type || "PERSON"); // default
  const [subtypeOptions, setSubtypeOptions] = useState([
    { id: "ALL", label: "All" },
  ]);
  const [subtype, setSubtype] = useState(accountsSettings?.subtype || "ALL"); // let the default be ALL
  const [sortOrderOptions] = useState([
    { id: "asc", label: "A → Z" },
    { id: "desc", label: "Z → A" },
  ]);
  const [sortOrder, setSortOrder] = useState(
    accountsSettings?.sortOrder || "asc"
  );
  const [forIngestionOptions] = useState([
    { id: "ALL", label: "All" },
    { id: "true", label: "True" },
    { id: "false", label: "False" },
  ]);
  const [forIngestion, setForIngestion] = useState(
    accountsSettings?.forIngestion || "true"
  );
  const [externalId, setExternalId] = useState(accountsSettings?.externalId);
  const debounceExternalId = useDebounce(externalId, 700);
  const [npiId, setNpiId] = useState(accountsSettings?.npiId);
  const debounceNpiId = useDebounce(npiId, 700);
  const [countryCode, setCountryCode] = useState(accountsSettings?.countryCode);
  const debounceCountryCode = useDebounce(countryCode, 700);
  const [chartLoading, setChartLoading] = useState(false);
  const [timeSegmentedSummary, setTimeSegmentedSummary] = useState([]);
  // <-- STATE

  // --> EFFECT
  useEffect(() => {
    return () => {
      dispatch(accountActions.clear());
      cancelAccountRequests.abort();
    };
  }, []);

  useEffect(() => {
    requestAccounts(0);
    setPage(0);
  }, [
    dispatch,
    debounceTerm,
    type,
    subtype,
    forIngestion,
    debounceExternalId,
    debounceNpiId,
    debounceCountryCode,
    sortOrder,
  ]);

  useEffect(() => {
    requestAccountsTimeSegmentedSummary();
  }, [
    dispatch,
    debounceTerm,
    type,
    subtype,
    forIngestion,
    debounceExternalId,
    debounceNpiId,
    debounceCountryCode,
  ]);

  useEffect(() => {
    setLoading(accounts.fetching);
  }, [accounts.fetching]);

  useEffect(() => {
    dispatch(commonActions.requestAccountSubtypes());
  }, []);

  useEffect(() => {
    if (accountSubtypeState?.length > 0) {
      const newAccountsubtypes = [];
      for (const key in accountSubtypeState) {
        newAccountsubtypes.push({
          id: accountSubtypeState[key].enumStringValue,
          label: accountSubtypeState[key].enumValueDisplayName,
        });
      }
      setSubtypeOptions([{ id: "ALL", label: "All" }, ...newAccountsubtypes]);
    }
  }, [accountSubtypeState]);
  // <-- EFFECT

  const requestAccounts = (pageNum) => {
    dispatch(
      accountActions.request({
        pageNum,
        ...(subtype !== "ALL" && { subType: subtype }),
        ...(forIngestion !== "ALL" && { forIngestion: forIngestion }),
        ...(searchTerm !== "" && { term: searchTerm }),
        type: type,
        ...(externalId !== "" && { externalId }),
        ...(npiId !== "" && { npiId }),
        ...(countryCode !== "" && { countryCode }),
        sortOrder,
      })
    );
    if (pageNum === 0) {
      dispatch(
        accountActions.countRequest({
          type: type,
          ...(subtype !== "ALL" && { subType: subtype }),
          ...(forIngestion !== "ALL" && { forIngestion: forIngestion }),
          ...(searchTerm !== "" && { term: searchTerm }),
          ...(externalId !== "" && { externalId }),
          ...(npiId !== "" && { npiId }),
          ...(countryCode !== "" && { countryCode }),
        })
      );
    }
  };

  const requestAccountsTimeSegmentedSummary = async () => {
    setChartLoading(true);
    setTimeSegmentedSummary([]);
    const temp = await getTimeSegmentedSummary({
      type: type,
      ...(subtype !== "ALL" && { subType: subtype }),
      ...(forIngestion !== "ALL" && { forIngestion: forIngestion }),
      ...(searchTerm !== "" && { term: searchTerm }),
      ...(externalId !== "" && { externalId }),
      ...(npiId !== "" && { npiId }),
      ...(countryCode !== "" && { countryCode }),
    });
    // console.log(temp.data);
    // transform
    if (temp.data.length > 0) {
      setTimeSegmentedSummary(
        temp.data.map((row) => ({
          accounts: row.count,
          time: row.timeSegment,
        }))
      );
    }
    setChartLoading(false);
  };

  const handleMoreItems = () => {
    if (!loading) {
      const newPage = page + 1;
      requestAccounts(newPage);
      setPage(newPage);
    }
  };

  const [accountsRef] = useInfiniteScroll({
    loading,
    hasNextPage: !accounts.isLastPage,
    onLoadMore: handleMoreItems,
  });

  const handleTypeChange = (item) => {
    setType(item.id);
    dispatch(
      settingsActions.set({
        accounts: { ...accountsSettings, type: item.id },
      })
    );
  };

  const handleSubtypeChange = (item) => {
    setSubtype(item.id);
    dispatch(
      settingsActions.set({
        accounts: { ...accountsSettings, subtype: item.id },
      })
    );
  };

  const handleSortOrderChange = (item) => {
    setSortOrder(item.id);
    dispatch(
      settingsActions.set({
        accounts: { ...accountsSettings, sortOrder: item.id },
      })
    );
  };

  const handleForIngestionChange = (item) => {
    setForIngestion(item.id);
    dispatch(
      settingsActions.set({
        hcps: { ...accountsSettings, forIngestion: item.id },
      })
    );
  };

  const handleSearchTermChange = (item) => {
    setSearchTerm(item);
    dispatch(
      settingsActions.set({
        accounts: { ...accountsSettings, searchTerm: item },
      })
    );
  };

  const handleExternalIdChange = (item) => {
    setExternalId(item);
    dispatch(
      settingsActions.set({
        hcps: { ...accountsSettings, externalId: item },
      })
    );
  };

  const handleNpiIdChange = (item) => {
    setNpiId(item);
    dispatch(
      settingsActions.set({
        hcps: { ...accountsSettings, npiId: item },
      })
    );
  };

  const handleCountryCodeChange = (item) => {
    setCountryCode(item);
    dispatch(
      settingsActions.set({
        hcps: { ...accountsSettings, countryCode: item },
      })
    );
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (type) {
      activeFilters.push(`Type: ${type}`);
    }
    if (searchTerm) {
      activeFilters.push(`Search: ${searchTerm}`);
    }
    if (subtype !== "ALL") {
      activeFilters.push(
        `Subtype: ${subtypeOptions.find((a) => subtype === a.id)?.label}`
      );
    }
    if (forIngestion) {
      activeFilters.push(`For Ingestion: ${forIngestion}`);
    }
    if (externalId) {
      activeFilters.push(`External Id: ${externalId}`);
    }
    if (npiId) {
      activeFilters.push(`NPI Id: ${npiId}`);
    }
    if (countryCode) {
      activeFilters.push(`Country Code: ${countryCode}`);
    }
    return activeFilters;
  };

  return view({
    history,
    accounts: accounts.data || [],
    accountsCount: accountsCount.data || 0,
    accountsRef,
    accountsNextPage: !accounts.isLastPage,
    loading,
    activeFilters: getActiveFilters(),

    searchTerm,
    handleSearchTermChange,

    typeOptions,
    type,
    handleTypeChange,

    subtypeOptions,
    subtype,
    handleSubtypeChange,

    sortOrderOptions,
    sortOrder,
    handleSortOrderChange,

    forIngestionOptions,
    forIngestion,
    handleForIngestionChange,

    externalId,
    handleExternalIdChange,

    npiId,
    handleNpiIdChange,

    countryCode,
    handleCountryCodeChange,

    chartLoading,
    timeSegmentedSummary,
  });
};
