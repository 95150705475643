import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Anchor, Button, IconButton } from "./Button";
import { Row, Column, RowToColumn } from "./Layout";
import { H4, Label } from "./Typography";
import { settingsActions } from "../store/actions/settingsActions";

const Wrapper = styled(Column)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
`;

export const Cookies = ({}) => {
  const dispatch = useDispatch();

  const cookies = useSelector((state) => state.settings.cookies);
  const [details, setDetails] = useState(false);

  if (cookies?.seen) return null;

  return (
    <Wrapper bkg="gray" fade="1" paddingX="20" paddingY="20">
      <Column>
        <Row gap="20">
          {/* {!details ? ( */}
          <RowToColumn fit gap="20" middle device="mobileXL">
            <Label color="gray" fade="7">
              We use cookies to improve your experience and give you
              personalized content. You can find more information in our{" "}
              <Anchor
                underline
                color="black"
                bold
                blank
                label="cookie policy"
                to="https://my.medical.watch/privacy"
              />
            </Label>
            <Row>
              <Button
                secondary
                noBkg
                label="Understood"
                onClick={() => {
                  dispatch(
                    settingsActions.set({
                      cookies: { ...cookies, seen: true, accepted: true },
                    })
                  );
                  setAccepted(true);
                }}
              />
            </Row>
            {/* <RowToColumn
                  gap="20"
                  columnGap="20"
                  marginTop="10"
                  device="mobileL"
                >
                  <Row noShrink>
                    <Anchor
                      underline
                      color="black"
                      bold
                      blank
                      label="No, take me to settings"
                      onClick={() => setDetails(true)}
                    />
                  </Row>
                  <Row>
                    <Button
                      icon="check"
                      reverse
                      label="Yes, I agree"
                      onClick={() => {
                        dispatch(
                          settingsActions.set({
                            cookies: { ...cookies, seen: true, accepted: true },
                          })
                        );
                        setAccepted(true);
                      }}
                    />
                  </Row>
                </RowToColumn> */}
          </RowToColumn>
          {/* ) : (
            <Column gap="20">
              <Label>
                We use cookies to sign you in to your account, personalize your
                content and analyze our traffic. We do not share cookies or
                information with third parties. See our{" "}
                <Anchor
                  underline
                  color="black"
                  bold
                  blank
                  label="Privacy Policy"
                  to="https://my.medical.watch/privacy"
                />
                .
              </Label>

              <RowToColumn gap="20" columnGap="20" device="mobileL">
                <Button
                  label="I accept cookies"
                  onClick={() => {
                    dispatch(
                      settingsActions.set({
                        cookies: { ...cookies, seen: true, accepted: true },
                      })
                    );
                    setAccepted(true);
                  }}
                />
                <Button
                  label="I do not accept cookies"
                  onClick={() => {
                    dispatch(
                      settingsActions.set({
                        cookies: { ...cookies, seen: true, accepted: false },
                      })
                    );
                    setAccepted(false);
                  }}
                />
              </RowToColumn>
            </Column>
          )} */}
          {/* <Row noShrink marginTop="-7" marginRight="-20">
            <IconButton
              small
              icon="closeLarge"
              onClick={() => {
                dispatch(
                  settingsActions.set({
                    cookies: { ...cookies, seen: true, accepted: true },
                  })
                );
                setAccepted(true);
              }}
            />
          </Row> */}
        </Row>
      </Column>
    </Wrapper>
  );
};
