import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useDebounce from "../../../utils/useDebounce";
import { view } from "./StudiesView";
import { getStudies } from "../../../store/sagas/studySagas";

export const AdminStudies = () => {
  const dispatch = useDispatch();

  const overallStatuses = [
    // { id: "ALL", label: "All" },
    { id: "ACTIVE_NOT_RECRUITING", label: "Active, not recruiting" },
    { id: "COMPLETED", label: "Completed" },
    { id: "ENROLLING_BY_INVITATION", label: "Enrolling by invitation" },
    // { id: "NOT_YET_RECRUITING", label: "Not yet recruiting" },
    { id: "RECRUITING", label: "Recruiting" },
    // { id: "SUSPENDED", label: "Suspended" },
    // { id: "TERMINATED", label: "Terminated" },
    // { id: "WITHDRAWN", label: "Withdrawn" },
    // { id: "AVAILABLE", label: "Available" },
    // { id: "NO_LONGER_AVAILABLE", label: "No longer available" },
    // { id: "TEMPORARILY_NOT_AVAILABLE", label: "TEMPORARILY_NOT_AVAILABLE" },
    { id: "APPROVED_FOR_MARKETING", label: "Approved for marketing" },
    // { id: "WITHHELD", label: "Withheld" },
    // { id: "UNKNOWN", label: "Unknown" },
  ];

  const phases = [
    { id: "2", label: "Phase 2" },
    { id: "3", label: "Phase 3" },
    { id: "4", label: "Phase 4" },
    { id: "2 3", label: "Phase 2 or 3" },
    { id: "3 4", label: "Phase 3 or 4" },
    { id: "2 3 4", label: "Phase 2 or 3 or 4" },
  ];

  // --> mapStateToProp
  // const users = useSelector((state) => state.user.all);
  // <-- mapStateToProp

  // --> STATE
  const [loading, setLoading] = useState(false);
  const [condition, setCondition] = useState("non small cell lung cancer");
  const debounceCondition = useDebounce(condition, 700);

  const [overallStatus, setOverallStatus] = useState("COMPLETED");
  const [phase, setPhase] = useState("2 3 4");
  const [country, setCountry] = useState("");
  const debounceCountry = useDebounce(country, 700);
  const [studies, setStudies] = useState([]);
  // <-- STATE

  const requestStudies = async () => {
    setLoading(true);
    setStudies([]);
    const allStudies = await getStudies({
      condition, 
      overallStatus,
      phase,
      ...(_.trim(country) !== "" && { country }),
    })
      .then((response) => response.data)
      .catch((error) => {console.log(error); return []});
      
    setStudies(allStudies);
    setLoading(false);
  };

  // --> EFFECT
  useEffect(() => {
    // requestStudies();
  }, [dispatch]);

  useEffect(() => {
    requestStudies();
  }, [
    debounceCondition,
    overallStatus,
    phase,
    debounceCountry
  ]);
  // <-- EFFECT

  const handleOverallStatusChange = (item) => {
    setOverallStatus(item);
  };

  const handlePhaseChange = (item) => {
    setPhase(item);
  };

  const getActiveFilters = () => {
    let activeFilters = [];

    return activeFilters;
  };

  return view({
    studies,

    loading,
    activeFilters: getActiveFilters(),
    condition,
    setCondition,

    overallStatuses,
    overallStatus,
    handleOverallStatusChange,

    phases,
    phase,
    handlePhaseChange,

    country,
    setCountry,
  });
};
