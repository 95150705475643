import React, { useState } from "react";
import styled from "styled-components";
import ReactQuill, { Quill } from "react-quill";
import quillEmoji from "quill-emoji";
import "./styles/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import ImageUploader from "quill-image-uploader";
import ImageResize from "quill-image-resize-module-react";
import { authHeader } from "../store/store";

import { Color } from "../utils/variables";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register(
  {
    "modules/imageUploader": ImageUploader,
    "formats/emoji": EmojiBlot,
    "modules/emoji-shortname": ShortNameEmoji,
    "modules/emoji-toolbar": ToolbarEmoji,
    "modules/emoji-textarea": TextAreaEmoji,
    "modules/imageResize": ImageResize,
  },
  true
);

export const Html = ({ string, replace }) => {
  if (replace) {
    string = "<p>" + string;
    string = string
      .replace(/\n\n/g, "</p><p>")
      .replace(/\n/g, "</p><p>")
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(
        /#(([a-zA-Z0-9_ー\-]+))/g,
        "<a target='_blank'  href='https://twitter.com/hashtag/$1/'>#$1</a>"
      )
      .replace(
        /@(([a-zA-Z0-9_ー\-]+))/g,
        "<a target='_blank' href='https://twitter.com/$1/'>@$1</a>"
      )
      .replace(
        /HCPID:\s*([a-f0-9]{32}|[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/gi,
        '<a target="_blank" href="/account/$1">HCP profile</a>'
      )
      .replace(
        /PostID:\s*([a-f0-9-]+)/g,
        '<a target="_blank" href="/post/$1">post</a>'
      )
      .replace(/\[(.*?)\]\((".*?")\)/g, '<a target="_blank" href=$2>$1</a>')
      .replace(/\[(.*?)\]\((.*?)\)/g, '<a target="_blank" href="$2">$1</a>')
      .replace(/\((https?:\/\/[^)]*)\)\.?/g, "$1")
      .replace(
        /(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*?))(?=[).]*\s|$)/g,
        '<a target="_blank" href="$1">$1</a>'
      );

    string = string + "</p>";
  }
  return (
    <div
      className={replace ? "highlights replace" : "highlights"}
      dangerouslySetInnerHTML={{ __html: string }}
    />
  );
};

const EditorWrapper = styled.div`
  width: 100%;

  .ql-editor {
    padding: 15px 20px;
    min-height: 300px;

    &.ql-blank::before {
      left: 20px;
      font-size: 18px;
      line-height: 1.8;
    }

    h1 {
      font-size: 36px;
      font-weight: normal;
    }

    h2 {
      font-size: 24px;
      font-weight: normal;
    }

    p {
      font-size: 18px;
      line-height: 1.8;
    }

    ul,
    ol {
      font-size: 18px;
    }
  }
`;

const modules = {
  toolbar: [
    [
      { header: [1, 2, 3, false] },
      "bold",
      "italic",
      "underline",
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },

      { align: "" },
      { align: "center" },
      { align: "right" },
      "image",
      "link",
      "blockquote",
      {
        color: [
          "black",
          Color("gray", 7),
          Color("gray", 5),
          Color("gray", 2),
          "white",
          Color("green", 7),
          Color("green", 6),
          Color("green", 5),
          Color("green", 2),
          Color("blue", 7),
          Color("blue", 5),
          Color("blue", 2),
          Color("yellow", 7),
          Color("yellow", 5),
          Color("yellow", 2),
          Color("red", 7),
          Color("red", 5),
          Color("red", 2),
          Color("purple", 7),
          Color("purple", 5),
          Color("purple", 2),
          Color("pink", 7),
          Color("pink", 5),
          Color("pink", 2),
        ],
      },
      // { 'background': [] },
      "emoji",
      "clean",
    ],
  ],
  "emoji-toolbar": true,
  "emoji-shortname": true,
  imageUploader: {
    upload: (file) => {
      if (file.type === "image/png") {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("image", file);
          const origin = window.location.origin.includes("localhost")
            ? "https://alpha.medical.watch"
            : window.location.origin;
          fetch(`${origin}/api/v1/storage/uploadImage`, {
            method: "POST",
            body: formData,
            headers: authHeader(),
          })
            // .then((response) => response.json())
            // .then((result) => {
            //   console.log(result);
            //   resolve(result.data.url);
            // })
            .then((response) => response.text())
            .then((text) => {
              console.log(text);
              resolve(text);
            })
            .catch((error) => {
              reject("Upload failed");
              console.error("Error:", error);
            });
        });
      }
    },
  },
  imageResize: {
    modules: ["Resize", "DisplaySize"],
  },
  keyboard: {
    bindings: {
      up: {
        key: 38,
        handler: function (range, context) {
          const quill = this.quill;
          const index = range.index;
          const [line, offset] = quill.getLine(index);
          const lineIndex = quill.getIndex(line);

          // Get previous line
          const [prevLine] = quill.getLines(lineIndex - 1, 1);
          if (prevLine) {
            const prevLineIndex = quill.getIndex(prevLine);
            quill.setSelection(prevLineIndex + offset, 0);
          }
          return false;
        },
      },
      down: {
        key: 40,
        handler: function (range, context) {
          const quill = this.quill;
          const index = range.index;
          const [line, offset] = quill.getLine(index);
          const lineIndex = quill.getIndex(line);

          // Get next line
          const [nextLine] = quill.getLines(lineIndex + line.length() + 1, 1);
          if (nextLine) {
            const nextLineIndex = quill.getIndex(nextLine);
            quill.setSelection(
              nextLineIndex + Math.min(offset, nextLine.length()),
              0
            );
          }
          return false;
        },
      },
    },
  },
};

const formats = [
  "header",
  // "size",
  "bold",
  "italic",
  "underline",
  "blockquote",
  // "code-block",
  "align",
  // "script",
  "list",
  "bullet",
  "indent",
  "color",
  // "background",
  "image",
  "link",
  "emoji",
  "clean",
  "width",
];

export const Editor = ({
  value,

  onChange,
  onBlur,
  placeholder,
}) => {
  const [editorLeave, setEditorLeave] = useState(true);
  const handleOnBlur = () => {
    if (editorLeave) {
      onBlur();
    }
  };

  return (
    <EditorWrapper
      onMouseLeave={() => setEditorLeave(true)}
      onMouseEnter={() => setEditorLeave(false)}
      onBlur={handleOnBlur}
    >
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        scrollingContainer="html"
      />
    </EditorWrapper>
  );
};
