import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { view } from "./DiseaseDetailsView";
import { useParams } from "react-router-dom";
import {
  logAnalyticEvent,
  getISOFormattedDateTimeString,
  getUrlParams,
  months,
} from "../../utils";
import { useGetTweets } from "../../store/queries/tweets";
import { useGetDisease, useGetDiseaseDols } from "../../store/queries/disease";
import { settingsActions } from "../../store/actions";

export const DiseaseDetails = ({ signUpClick }) => {
  const queryParams = getUrlParams(location.search);
  const { id } = useParams();

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.influencers);
  const auth = useSelector((state) => state.auth.data);

  const date = new Date();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const getMonthInfo = (monthOffset) => {
    const targetDate = new Date(currentYear, currentMonth - monthOffset, 1);
    const monthIndex = targetDate.getMonth();
    const year = targetDate.getFullYear();
    return { month: months[monthIndex], year };
  };

  const dateItems = [1, 2, 3].map((offset) => {
    const { month, year } = getMonthInfo(offset);
    return {
      id: `${month}-${year}`,
      label: `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`,
    };
  });

  const [selectedDisease, setSelectedDisease] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    settings?.disease?.date || dateItems[0].id
  );

  const [selectedMonth, selectedYear] = selectedDate.split("-");

  const firstDayOfMonth = new Date(
    selectedYear,
    months.indexOf(selectedMonth),
    1
  );
  const lastDayOfMonth = new Date(
    selectedYear,
    months.indexOf(selectedMonth) + 1,
    0
  );
  lastDayOfMonth.setHours(23, 59, 59, 999);

  const {
    data: dols,
    isLoading: loadingDols,
    isSuccess,
    refetch: refetchDols,
  } = useGetDiseaseDols({
    id,
    startDate: firstDayOfMonth
      ? getISOFormattedDateTimeString(firstDayOfMonth)
      : getISOFormattedDateTimeString(date),
    endDate: lastDayOfMonth
      ? getISOFormattedDateTimeString(lastDayOfMonth)
      : getISOFormattedDateTimeString(date),
    maxResult: 10,
  });

  const { data: disease, isLoading: isLoadingDisease } = useGetDisease(id);
  const {
    data: tweets,
    isLoading: isLoadingTweets,
    refetch: refetchTweets,
  } = useGetTweets(
    {
      ...(selectedDisease?.tagId && { addTagIds: [selectedDisease.tagId] }),
      originalOnly: false,
      includeReplies: true,
      startDate: getISOFormattedDateTimeString(firstDayOfMonth),
      endDate: getISOFormattedDateTimeString(lastDayOfMonth),
      sortBy: "score",
      sortOrder: "desc",
      maxResult: 1,
      pageNum: 0,
    },
    { enabled: !!selectedDisease }
  );

  const handleDateChange = (item) => {
    setSelectedDate(item.id);
    dispatch(
      settingsActions.set({
        influencers: {
          ...settings,
          disease: { ...settings?.disease, date: item.id },
        },
      })
    );
    setTimeout(() => {
      refetchDols();
    }, 200);
  };

  useEffect(() => {
    if (disease?.data && selectedDate) {
      setSelectedDisease(disease.data);
      logAnalyticEvent(
        "Landing - Top influencers",
        {
          page: "Disease",
          name: disease?.data?.primaryName,
          date: selectedDate,
        },
        auth?.id || queryParams?.u || null,
        auth?.displayName || queryParams?.n || null,
        auth?.email || queryParams?.e || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue ||
          queryParams?.t ||
          "MEDICAL",
        null
      );
    }
  }, [disease?.data, selectedDate]);

  useEffect(() => {
    if (
      selectedDisease
      // && dols?.data?.length > 0
    ) {
      refetchTweets();
    }
  }, [
    selectedDisease,
    // , dols?.data
  ]);

  const topInfluencers = dols?.data
    ?.sort((a, b) => b.mentions - a.mentions)
    ?.slice(0, 15);

  return view({
    signUpClick,
    disease: disease?.data,
    dols: topInfluencers,
    isLoading: loadingDols,
    isSuccess,
    handleDateChange,
    dateItems,
    selectedDate,
    tweet: tweets?.data?.[0],
    isLoadingTweets,
  });
};
