import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  Column,
  Row,
  Label,
  H3,
  H4,
  P,
  Icon,
  Footer,
  MainWrapper,
  Metadata,
  Spinner,
  RowToColumn,
  TweetCard,
  Dropdown,
  ListItem,
} from "../../components";
import { HeaderAndDrawer, Counter, Share } from "./components";
import { capitalize, idToText } from "../../utils";

const StyledLink = styled(Link)`
  width: 100%;
`;

export const view = ({
  signUpClick,
  diseases,
  loadingDiseases,
  isSuccess,
  dateItems,
  handleDateChange,
  selectedDate,
  tweet,
  isLoadingTweets,
}) => {
  return (
    <MainWrapper className="dashboard">
      <Metadata />
      <HeaderAndDrawer {...{ signUpClick }} />
      <Column marginTop="100">
        <Column paddingBottom="100">
          <Column center>
            <Column maxWidth="1200" paddingX>
              <Column gap="10">
                <H3 bold>Top diseases</H3>
                <P color="gray" fade="7">
                  Click a disease to see top influencers
                </P>
                <RowToColumn device="tablet" gap="20">
                  <Column maxWidth="200">
                    <Dropdown
                      expand
                      value={selectedDate}
                      // In case we have a value from in the redux store
                      // which is not in the dateItems array:
                      placeholder={
                        selectedDate
                          ? `${capitalize(selectedDate.split("-")?.[0])} ${
                              selectedDate.split("-")?.[1]
                            }`
                          : "Select a date"
                      }
                      items={dateItems}
                      onChange={handleDateChange}
                    />
                  </Column>
                  <Share title="Top diseases" />
                </RowToColumn>
              </Column>
              <RowToColumn gap="40" columnGap="10">
                <Column fit maxWidth="600">
                  {loadingDiseases && (
                    <Row marginY="40" expand center>
                      <Spinner />
                    </Row>
                  )}
                  {isSuccess && diseases?.length === 0 && (
                    <Row marginY="40" expand center>
                      <Label color="gray" fade="7">
                        No influencers found!
                      </Label>
                    </Row>
                  )}
                  {diseases?.length > 0 && (
                    <Column gap="10" marginTop="40">
                      {diseases?.map((disease, index) => (
                        <Row key={disease.id}>
                          <StyledLink
                            to={`/influencers/disease/${idToText(
                              disease.name
                            )}/${disease.diseaseId}`}
                          >
                            <ListItem expand middle hover>
                              <Row gap="10" middle expand>
                                <Counter number={index + 1} />
                                <H4 bold>{disease.name}</H4>
                                <Row fit />
                                <Previous
                                  current={disease.count}
                                  previous={disease.prevCount}
                                />
                                <Label color="gray" fade="7" bold noShrink>
                                  {disease.count}{" "}
                                  {disease.count === 1 ? "post" : "posts"}
                                </Label>
                              </Row>
                            </ListItem>
                          </StyledLink>
                        </Row>
                      ))}
                    </Column>
                  )}
                </Column>
                <Column marginTop="30" maxWidth="560">
                  {isLoadingTweets && (
                    <Row marginY="40" expand center>
                      <Spinner />
                    </Row>
                  )}
                  {tweet && (
                    <Link to={`/post/${tweet.externalId}`}>
                      <TweetCard
                        data={tweet}
                        title={`Top post from ${diseases?.[0]?.name}`}
                        subtitle={
                          tweet?.tAccount?.lastName &&
                          `Congratulations Dr. ${tweet.tAccount.lastName}. Keep up the good work!`
                        }
                      />
                    </Link>
                  )}
                </Column>
              </RowToColumn>
            </Column>
          </Column>
        </Column>
        <Footer />
      </Column>
    </MainWrapper>
  );
};

const Previous = ({ current, previous }) => {
  return (
    <>
      {current === previous && (
        <Row bkg="gray" fade="1" radius="4" paddingX="4" paddingY="2">
          <Label color="gray" fade="7">
            no change
          </Label>
        </Row>
      )}
      {current > previous && (
        <Row
          bkg="gray"
          fade="1"
          radius="4"
          paddingX="4"
          paddingY="2"
          middle
          gap="2"
          noShrink
        >
          <Label color="green" fade="6">
            + {current - previous}
          </Label>
          <Icon name="chevronUp" color="green" fade="6" />
        </Row>
      )}
      {current < previous && (
        <Row
          bkg="gray"
          fade="1"
          radius="4"
          paddingX="4"
          paddingY="2"
          middle
          gap="2"
          noShrink
        >
          <Label color="red" fade="6">
            - {previous - current}
          </Label>
          <Icon name="chevronDown" color="red" fade="6" />
        </Row>
      )}
    </>
  );
};
