import React from "react";

import {
  Row,
  Column,
  Button,
  Input,
  Label,
  Anchor,
  Dropdown,
  AutoComplete,
  AutoCompleteWithResponses,
  Card,
  CardBody,
  H3,
  Grid,
  Icon,
  Pill,
  Spinner,
} from "../../components";

const Header = ({ step, handleModalBack, history }) => (
  <Grid columns={3} always>
    <Row>
      {step === 2 && (
        <Anchor
          bold
          label="Back"
          icon="arrowLeft"
          onClick={() => handleModalBack()}
        />
      )}
    </Row>
    <Row center>
      <Label bold color="gray" fade="7">
        Step {step} of 3
      </Label>
    </Row>
    <Row right>
      {step === 3 && <Anchor bold label="Close" reverse icon="close" to="/" />}
    </Row>
  </Grid>
);

export const CreateUser = ({
  adminPage,
  checkoutEmail,
  plansList,
  handleUpdatePlan,
  selectedPlan,
  showEmail,

  data,
  errors,
  handleUpdateData,
  isSubmitPressed,
  inProgress = false,
  loading,
  emailSent,
  history,
  step,
  handleNext,
  handleModalBack,

  diseases,
  setSearchDisease,
  searchDisease,
  selectedDiseases,
  addSelectedDisease,
  addSelectedDiseases,
  removeSelectedDiseases,
  clearSelectedDiseases,
  diseasesInfiniteScroll,

  products,
  setSearchProduct,
  searchProduct,
  selectedProducts,
  addSelectedProduct,
  addSelectedProducts,
  removeSelectedProducts,
  clearSelectedProducts,
  productsInfiniteScroll,

  nextDisabled,
  resendEmail,
  pressedResend,
  // missingData,
}) => {
  return (
    <Card medium radius="30" border={adminPage} noOverflow>
      {step === 1 && (
        <>
          <Header {...{ step, handleModalBack, history }} />
          <CardBody center>
            {adminPage ? (
              <Row marginBottom="40">
                <H3 bold>Create a new User</H3>
              </Row>
            ) : (
              <>
                <H3 bold>Welcome to Medical.watch!</H3>
                <Row marginTop="10" marginBottom="40">
                  <Label color="gray" fade="8">
                    To help us provide you with relevant content, just answer a
                    few questions.
                  </Label>
                </Row>
              </>
            )}
            <Column gap="20" stretch maxWidth="400">
              <Column gap="10" stretch>
                <Label bold>First Name</Label>
                <Input
                  small
                  width="260"
                  placeholder="First Name"
                  value={data.firstName}
                  // errorMessage={isSubmitPressed && errors.firstName}
                  onChange={(e) =>
                    handleUpdateData({
                      field: "firstName",
                      value: e.target.value,
                    })
                  }
                />
              </Column>
              <Column gap="10">
                <Label bold>Last Name</Label>
                <Input
                  small
                  width="260"
                  placeholder="Last Name"
                  value={data.lastName}
                  // errorMessage={isSubmitPressed && errors.lastName}
                  onChange={(e) =>
                    handleUpdateData({
                      field: "lastName",
                      value: e.target.value,
                    })
                  }
                />
              </Column>
              <Column gap="10">
                <Label>
                  <Label bold>Role</Label>
                </Label>
                <Dropdown
                  expand
                  placeholder={
                    adminPage
                      ? "Select a role"
                      : "What best describes your role?"
                  }
                  value={data.userType}
                  items={[
                    { id: "HCP", label: "Physician" },
                    { id: "MARKETING", label: "Marketing" },
                    { id: "MEDICAL", label: "Medical Affairs" },
                    { id: "SALES", label: "Sales" },
                    { id: "OTHER", label: "Other" },
                  ]}
                  onChange={(e) =>
                    handleUpdateData({
                      field: "userType",
                      value: e.id,
                    })
                  }
                />
                {isSubmitPressed && errors.userType && (
                  <Label color="red">{errors.userType}</Label>
                )}
              </Column>
              {adminPage && (
                <Column gap="20">
                  <Column gap="10">
                    <Label>
                      <Label bold>Plan</Label>
                    </Label>
                    <Dropdown
                      expand
                      placeholder="Select a plan"
                      value={data.plan}
                      items={plansList}
                      onChange={handleUpdatePlan}
                    />
                  </Column>
                  <Column gap="10">
                    <Label>
                      <Label bold>Email</Label>
                    </Label>
                    <Input
                      small
                      width="260"
                      placeholder="Email"
                      value={data.email}
                      errorMessage={isSubmitPressed && errors.email}
                      onChange={(e) =>
                        handleUpdateData({
                          field: "email",
                          value: e.target.value,
                        })
                      }
                    />
                  </Column>
                </Column>
              )}
              {showEmail && (
                <Column gap="10">
                  <Label>
                    <Label bold>Email</Label>
                  </Label>
                  <Input
                    small
                    width="260"
                    placeholder="Email"
                    value={data.email}
                    errorMessage={isSubmitPressed && errors.email}
                    onChange={(e) =>
                      handleUpdateData({
                        field: "email",
                        value: e.target.value,
                      })
                    }
                  />
                </Column>
              )}

              {/* {missingData && (
                <Label color="red">
                  No disease or product selected, please go back and select one.
                </Label>
              )} */}

              <Row expand center marginTop="40">
                <Button
                  minWidth="240"
                  label="Next"
                  onClick={() => handleNext()}
                  disabled={
                    // missingData ||
                    nextDisabled
                  }
                />
              </Row>
            </Column>
          </CardBody>
        </>
      )}

      {step === 2 && (
        <>
          <Header {...{ step, handleModalBack, history }} />
          <CardBody center style={{ minHeight: "350px" }}>
            {adminPage ? (
              <H3 bold>Create a new User</H3>
            ) : (
              <H3 bold>Nice to meet you! </H3>
            )}
            <Row marginTop="10" marginBottom="40">
              {adminPage ? (
                <Label color="gray" fade="8">
                  Choose your product and disease
                </Label>
              ) : (
                <Label color="gray" fade="8">
                  {data?.userType === "HCP" || data?.userType === "OTHER"
                    ? "Select a disease"
                    : data?.userType === "MEDICAL"
                    ? "Choose your product and disease"
                    : "Choose your brand and indication"}
                </Label>
              )}
            </Row>
            <Column gap="20" stretch maxWidth="400">
              {(selectedPlan?.products === "-1" ||
                parseInt(selectedPlan?.products) > 1) && (
                <Column gap="10" stretch>
                  <Label bold>
                    <Label bold>
                      {data?.userType === "MARKETING" ||
                      data?.userType === "SALES"
                        ? "Brands"
                        : "Products"}
                    </Label>
                  </Label>
                  <AutoCompleteWithResponses
                    showOnEmptyTerm
                    displayTextFromSelected
                    searchResults={products}
                    placeholder="Select products"
                    setSearchTerm={setSearchProduct}
                    searchTerm={searchProduct}
                    selectedItems={selectedProducts}
                    addItem={addSelectedProducts}
                    removeItem={removeSelectedProducts}
                    clearSearchResults={clearSelectedProducts}
                    searchLoading={false}
                    hasClearButton
                    infiniteScroll={productsInfiniteScroll}
                    showSuggestion
                    hidePills
                    noResultsMessage={
                      <Column gap="5">
                        <Label>
                          Don't see your{" "}
                          {data?.userType === "MARKETING" ||
                          data?.userType === "SALES"
                            ? "brand"
                            : "product"}
                          ?
                        </Label>
                        <Anchor
                          bold
                          line
                          label="Let us know"
                          blank
                          to={
                            "mailto:hello@medical.watch?subject=Medical.watch new account &body=Hello, %0D%0AI'd like access to Medical.watch. But I don't see my product:"
                          }
                        />
                      </Column>
                    }
                  />
                  {selectedProducts.length > 0 && (
                    <Row multiline flexWrap marginTop="10">
                      {selectedProducts.map((item) => (
                        <Pill
                          key={item.id}
                          fade="2"
                          bkg="green"
                          label={item.label}
                          close
                          onClose={() => {
                            removeSelectedProducts(item);
                          }}
                        />
                      ))}
                    </Row>
                  )}
                </Column>
              )}
              {selectedPlan?.products === "1" && (
                <Column gap="10" stretch>
                  <Label bold>
                    {data?.userType === "MARKETING" ||
                    data?.userType === "SALES"
                      ? "Brand"
                      : "Product"}
                  </Label>
                  <AutoComplete
                    showOnEmptyTerm
                    displayTextFromSelected
                    searchResults={products}
                    placeholder="Select one"
                    setSearchTerm={setSearchProduct}
                    searchTerm={searchProduct}
                    selectedItems={selectedProducts}
                    addItem={addSelectedProduct}
                    clearSearchResults={clearSelectedProducts}
                    searchLoading={false}
                    hasClearButton
                    infiniteScroll={productsInfiniteScroll}
                    showSuggestion
                    noResultsMessage={
                      <Column gap="5">
                        <Label>
                          Don't see your{" "}
                          {data?.userType === "MARKETING" ||
                          data?.userType === "SALES"
                            ? "brand"
                            : "product"}
                          ?
                        </Label>
                        <Anchor
                          bold
                          line
                          label="Let us know"
                          blank
                          to={
                            "mailto:hello@medical.watch?subject=Medical.watch new account &body=Hello, %0D%0AI'd like access to Medical.watch. But I don't see my product:"
                          }
                        />
                      </Column>
                    }
                  />
                </Column>
              )}
              {(selectedPlan?.diseases === "-1" ||
                parseInt(selectedPlan?.diseases) > 1) && (
                <Column gap="10" stretch>
                  <Label bold>
                    {data?.userType === "MARKETING" ||
                    data?.userType === "SALES"
                      ? "Indications"
                      : "Diseases"}
                  </Label>
                  <AutoCompleteWithResponses
                    showOnEmptyTerm
                    displayTextFromSelected
                    searchResults={diseases}
                    placeholder="Select diseases"
                    setSearchTerm={setSearchDisease}
                    searchTerm={searchDisease}
                    selectedItems={selectedDiseases}
                    addItem={addSelectedDiseases}
                    removeItem={removeSelectedDiseases}
                    clearSearchResults={clearSelectedDiseases}
                    searchLoading={false}
                    hasClearButton
                    infiniteScroll={diseasesInfiniteScroll}
                    showSuggestion
                    hidePills
                    noResultsMessage={
                      <Column gap="5">
                        <Label>
                          Don't see your{" "}
                          {data?.userType === "MARKETING" ||
                          data?.userType === "SALES"
                            ? "indication"
                            : "disease"}
                          ?
                        </Label>
                        <Anchor
                          bold
                          line
                          label="Let us know"
                          blank
                          to={
                            "mailto:hello@medical.watch?subject=Medical.watch new account &body=Hello, %0D%0AI'd like access to Medical.watch. But I don't see my disease:"
                          }
                        />
                      </Column>
                    }
                  />
                  {selectedDiseases.length > 0 && (
                    <Row multiline flexWrap marginTop="10">
                      {selectedDiseases.map((item) => (
                        <Pill
                          key={item.id}
                          fade="2"
                          bkg="green"
                          label={item.label}
                          close
                          onClose={() => {
                            removeSelectedDiseases(item);
                          }}
                        />
                      ))}
                    </Row>
                  )}
                </Column>
              )}
              {selectedPlan?.diseases === "1" && (
                <Column gap="10" stretch>
                  <Label bold>
                    {data?.userType === "MEDICAL" ? "Disease" : "Indication"}
                  </Label>
                  <AutoComplete
                    showOnEmptyTerm
                    displayTextFromSelected
                    searchResults={diseases}
                    placeholder="Select one"
                    setSearchTerm={setSearchDisease}
                    searchTerm={searchDisease}
                    selectedItems={selectedDiseases}
                    addItem={addSelectedDisease}
                    clearSearchResults={clearSelectedDiseases}
                    searchLoading={false}
                    hasClearButton
                    infiniteScroll={diseasesInfiniteScroll}
                    showSuggestion
                    noResultsMessage={
                      <Column gap="5">
                        <Label>
                          Don't see your{" "}
                          {data?.userType === "MARKETING" ||
                          data?.userType === "SALES"
                            ? "indication"
                            : "disease"}
                          ?
                        </Label>
                        <Anchor
                          bold
                          line
                          label="Let us know"
                          blank
                          to={
                            "mailto:hello@medical.watch?subject=Medical.watch new account &body=Hello, %0D%0AI'd like access to Medical.watch. But I don't see my disease:"
                          }
                        />
                      </Column>
                    }
                  />
                </Column>
              )}
              {!adminPage && (
                <Column marginTop>
                  <Label>
                    {data?.userType === "HCP" || data?.userType === "OTHER"
                      ? "On Mondays, you'll get weekly highlights in your disease. You'll also get daily reports during medical congresses like ASCO."
                      : data?.userType === "MEDICAL"
                      ? "On Mondays, you'll get weekly highlights in your disease area. You'll also get daily digests when HCPs mention your product on social media."
                      : "On Mondays, you'll get weekly highlights in your indication. You'll also get daily digests when HCPs mention your brand on social media."}
                  </Label>
                </Column>
              )}
            </Column>
          </CardBody>

          <Row expand center gap="30" marginTop="40" marginBottom>
            <Button
              minWidth="240"
              label="Create Account"
              onClick={() => handleNext()}
              disabled={inProgress || nextDisabled || errors.email}
            />
            {adminPage && inProgress && (
              <Spinner />
            )}
          </Row>
        </>
      )}

      {step === 3 && (
        <>
          <Header {...{ step, handleModalBack, history }} />
          {adminPage ? (
            <CardBody center>
              <H3 bold>New User</H3>
              <Column gap="20" stretch maxWidth="400" marginTop center>
                <Label>
                  <Label bold>{emailSent?.email} </Label>
                  created successfully.
                </Label>
              </Column>
              <Row expand center marginTop="40">
                <Button
                  secondary
                  label="Add another user"
                  onClick={() => location.reload()}
                />
              </Row>
            </CardBody>
          ) : (
            <CardBody center>
              <H3 bold>Confirm your email</H3>
              <Column gap="20" stretch maxWidth="400" marginTop center>
                <Label>
                  Check your <Label bold>{emailSent?.email} </Label>
                  inbox (including the spam folder) for a Welcome email.
                </Label>
                <Label>
                  <Label bold>Tip:</Label> Add @medical.watch as a{" "}
                  <Anchor
                    inline
                    line
                    to="https://support.microsoft.com/en-us/office/safe-senders-in-outlook-com-470d4ee6-e3b6-402b-8cd9-a6f00eda7339"
                    blank
                    label="safe sender"
                  />{" "}
                  in Outlook so your reports aren't sent to the Junk folder.
                </Label>
                {pressedResend ? (
                  <Row marginTop="30" gap="5" middle>
                    <Label large>Email sent</Label>
                    <Icon name="check" color="green" size="30" />
                  </Row>
                ) : (
                  <>
                    <Row marginTop="30">
                      <Label>Didn’t get the email?</Label>
                    </Row>
                    <Row expand center>
                      <Button
                        minWidth="240"
                        label="Resend"
                        onClick={resendEmail}
                      />
                    </Row>
                  </>
                )}
              </Column>
            </CardBody>
          )}
        </>
      )}
    </Card>
  );
};
