import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ReactPhotoCollage } from "react-photo-collage";
import moment from "moment";
import YouTube from "react-youtube";
import AudioPlayer from "react-h5-audio-player";
import "./styles/react-h5-audio-player.css";

import {
  Column,
  Row,
  Label,
  H4,
  P,
  Avatar,
  AvatarGroup,
  Icon,
  RowToColumn,
  ModalImage,
  Anchor,
  Card,
  Circle,
  Button,
} from ".";
import { insightHighlight, tweetHighlight, formatTime } from "../utils";

import { Color } from "../utils/variables";

const YtImage = styled.img`
  max-width: 100%;
  max-height: ${(props) =>
    props.maxHeight ? `${props.maxHeight}px` : "300px"};
`;

const Video = styled.video`
  max-height: 500px;
`;

const ReactPhotoCollageWrapper = styled.div`
  width: 100%;
  margin: 0 0 28px 0;

  & > div {
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid ${Color("gray", "9")};
  }

  & > div > div {
    border: none;

    & + div {
      margin-top: 0 !important;
      border-top: 1px solid ${Color("gray", "9")};
    }

    & > div + div {
      margin-left: 0 !important;
      border-left: 1px solid ${Color("gray", "9")};
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  border-radius: 5px;
  border: 1px solid ${Color("gray", "9")};
  overflow: hidden;
  cursor: pointer;
`;

const LinkPreview = styled(Row)`
  overflow: hidden;
  max-width: 600px;
  border: 1px solid ${Color("gray", "9")};
  border-radius: 8px;

  a {
    display: flex;
  }
`;

const Clamp = styled(Row)`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.value};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Image = styled.div`
  flex-shrink: 0;
  width: 100px;
  min-height: 100px;
  height: 100%;
  ${(props) =>
    props.src &&
    css`
      background: url(${props.src}) center;
      background-size: cover;
    `};
`;

const Text = styled(P)`
  mark {
    background: ${Color("purple", "1")};
    color: ${Color("purple", "6")};
    font-family: "Oxigen-Bold";
    border-radius: 6px;
    padding: 1px 5px;
  }
`;

const BarWrapper = styled(Row)`
  position: relative;

  span {
    z-index: 1;
  }
`;

const Bar = styled(Row)`
  position: absolute;
  height: 100%;
  z-index: 0;
  left: 0;
`;

export const TweetVideos = ({ videos }) => {
  // Let's show only the first video as in some cases the same video shows several times
  return (
    <Column marginBottom>
      <Video src={videos[0].url} controls />
    </Column>
  );
};

export const TweetMedia = ({ medias, grid }) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [clickedUrl, setClickedUrl] = useState("");

  const photos = medias?.map((media) => {
    return {
      source:
        media.type === "photo" || media.type === "image"
          ? media.url
          : media.previewImageURL,
    };
  });

  let layout = [2, 2];
  if (photos.length === 2) {
    layout = [1, 1];
  }
  if (photos.length === 3) {
    layout = [1, 2];
  }

  const setting = {
    width: "100%",
    height: ["120px", "120px"],
    layout,
    photos,
    showNumOfRemainingPhotos: true,
  };

  return (
    <>
      {(photos.length === 1 || !grid) && (
        <Row flexWrap>
          {medias.map((media) => {
            const imageUrl =
              media.type === "photo" || media.type === "image"
                ? media.url
                : media.previewImageURL;
            return (
              <Row
                id={`tweet-media-${media?.externalId}`}
                key={media?.externalId}
                marginBottom="15"
                expand
              >
                <ImageWrapper
                  onClick={() => {
                    setShowImageModal(true);
                    setClickedUrl(imageUrl);
                  }}
                >
                  <img
                    src={imageUrl}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                    }}
                  />
                </ImageWrapper>
              </Row>
            );
          })}

          <ModalImage
            visible={showImageModal}
            toggleVisibility={setShowImageModal}
            url={clickedUrl}
          />
        </Row>
      )}

      {photos.length > 1 && grid && (
        <ReactPhotoCollageWrapper>
          <ReactPhotoCollage {...setting} />
        </ReactPhotoCollageWrapper>
      )}
    </>
  );
};

export const TweetCard = ({
  source,
  data,
  to,
  debug,
  details,
  keywords,
  hasQuote,
  isTweetView,
  videoRef,
  podcast,
  podcastRef,
  title,
  subtitle,
  insightTweet,
}) => {
  let content = data.content;
  const isRewteet = data.content.startsWith("RT @");
  const isReply =
    data.externalConversationId &&
    data.externalConversationId !== data.externalId;
  const isQuoted = data.tweetTweet?.referenceType === "QUOTED";
  const [toggleTags, setToggleTags] = useState(debug || false); // for testing and debugging
  const [text, setText] = useState(null);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [replyingToMultiple, setReplyingToMultiple] = useState([]);
  const isYT = data?.source === "YT";
  const isPC = data?.source === "PC";

  // Rules to show Link Preview
  // 1. No Images
  const hasImages = data?.medias?.length > 0;
  const filteredVideos = data?.medias?.filter(
    (a) => a.type === "video" || a.type === "VIDEO"
  );
  const hasVideos = filteredVideos?.length > 0 && filteredVideos[0]?.url;
  // 2. Has links (not it's own twitter link)
  // const twitterLink = data?.urls?.filter(
  //   (k) => k.url.indexOf("twitter.com") !== -1
  // );
  const notTwitterLinks = data?.urls?.filter(
    (k) => k.url.indexOf("twitter.com") === -1
  );
  let selectedLinks = [];
  if (notTwitterLinks?.length === 1 && !isYT) {
    selectedLinks = notTwitterLinks;
  } else if (notTwitterLinks?.length > 1 && !isYT) {
    selectedLinks = [notTwitterLinks[notTwitterLinks?.length - 1]];
  }

  // 3. Has a reply, quoted tweet or retweet
  const hasOtherTweet =
    data?.repliedTo?.length > 0 ||
    data?.quoted?.length > 0 ||
    data?.retweeted?.length > 0;
  // 4. Link is last tweet's line
  const lastWord = data?.content?.replace(/(\n)/g, " ")?.split(" ")?.pop();
  const isLastWordUrl = lastWord?.startsWith("http");
  // 5. Has more than one link
  // const hasOneLink = data?.content?.split("http").length === 2;

  const showPreview = isLastWordUrl && !hasImages && !hasOtherTweet;

  let shortenUrl = "";
  let originalUrl = "";
  if (selectedLinks[0]) {
    originalUrl = decodeURIComponent(selectedLinks[0].url);
    const cleanUrl = originalUrl.replace(
      /(http(s)?:?\/\/(?:www\.|(?!www)))/g,
      ""
    );
    shortenUrl =
      cleanUrl.length > 22 ? `${cleanUrl.substring(0, 22)}...` : cleanUrl;
  }

  useEffect(() => {
    if (content && content.startsWith("@")) {
      const array = content.split(" ");
      let people = [];
      for (const item of array) {
        if (item.startsWith("@")) {
          people.push(item);
        } else {
          break;
        }
      }

      setReplyingToMultiple(people);
      if (people.length > 3) {
        const string = people.join(" ");
        content = content.replace(string, "");
      }
    }
    if (isRewteet) {
      let tmpContent = content.substring(content.indexOf(":") + 1);
      const array = _.trim(tmpContent).split(" ");
      let people = [];
      for (const item of array) {
        if (item.startsWith("@")) {
          people.push(item);
        } else {
          break;
        }
      }

      if (people.length > 1) {
        const string = people.join(" ");
        content = content.replace(string, "");
      }
    }
  }, [data.content]);

  useEffect(() => {
    setText(
      tweetHighlight({
        text: content,
        urls: data.urls,
        keywords,
      })
    );
  }, [content, keywords]);

  let path = `/account/${data.tAccount.id}${source ? "?source=" + source : ""}`;
  if (path === window.location.pathname) {
    path = null;
  }

  const handleTruncate = (e) => {
    if (e.clamped && !showSeeMore) {
      setShowSeeMore(true);
    }
  };

  return (
    <Column
      stretch
      center
      key={data.externalId}
      onContextMenu={(e) => e.altKey && setToggleTags(!toggleTags)} // for testing and debugging
    >
      <Card
        className={details ? "" : "clickable"}
        border={!hasQuote && !insightTweet}
        paddingAll={hasQuote ? "0" : insightTweet ? "10" : "20"}
      >
        {title && (
          <Column
            bkg="gray"
            fade="1"
            marginTop="-20"
            marginX="-20"
            paddingX
            paddingY="10"
            marginBottom
            gap="2"
          >
            <Label bold color={subtitle ? "black" : "gray"} fade="7">
              {title}
            </Label>
            {subtitle && (
              <Label color="gray" fade="8">
                {subtitle}
              </Label>
            )}
          </Column>
        )}

        {!insightTweet && (
          <>
            {(data.userAvatar || data.tAccount.name) && (
              <Row gap="10" middle>
                <Link
                  id={`tweet-account-image-${data.tAccount.id}`}
                  className={path ? "" : "disabled"}
                  to={path}
                >
                  <AvatarGroup>
                    <Avatar
                      large={details}
                      imageUrl={data.tAccount.imageURL}
                      name={data.tAccount.name}
                    />
                    <Column gap="5">
                      <Row middle>
                        {details ? (
                          <H4 className="text" large bold>
                            {data.tAccount.name}
                          </H4>
                        ) : (
                          <Label className="text" bold>
                            {data.tAccount.name}
                          </Label>
                        )}
                      </Row>
                      {details ? (
                        <Row>
                          <Label>
                            {data.tAccount.institution?.displayLocation ||
                              data.tAccount.displayLocation}
                          </Label>
                        </Row>
                      ) : (
                        <Row>
                          <Label small color="gray" fade="0.6">
                            {data.tAccount.institution?.displayLocation ||
                              data.tAccount.displayLocation}
                          </Label>
                        </Row>
                      )}
                    </Column>
                  </AvatarGroup>
                </Link>
                {!isTweetView && isYT && (
                  <Row fit right>
                    <Button secondary small icon="play" label="Watch video" />
                  </Row>
                )}
              </Row>
            )}
          </>
        )}

        {!isTweetView && isPC && (
          <Column>
            {!data?.additionalInfo?.duration ? (
              <Row marginTop marginBottom="10">
                <Button secondary small icon="play" label="Listen to podcast" />
              </Row>
            ) : (
              <Row
                bkg="gray"
                fade="1"
                radius="5"
                expand
                paddingX
                paddingY="10"
                middle
                marginTop
                marginBottom="10"
              >
                <Icon name="play" color="gray" fade="8" size="40" />
                <Row gap="5" marginLeft>
                  <Label>0:00:00</Label>
                  <Label>/</Label>
                  <Label>{formatTime(data.additionalInfo.duration)}</Label>
                </Row>
              </Row>
            )}
          </Column>
        )}
        {data.sentiment !== undefined && debug && (
          <Row middle gap="10" marginTop="10">
            {data.sentiment === 0 && (
              <>
                <Circle radius="10" bkg="gray" />
                <Label>Neutral</Label>
              </>
            )}
            {data.sentiment > 0 && (
              <>
                <Circle radius="10" bkg="green" />
                <Label>Positive</Label>
              </>
            )}
            {data.sentiment < 0 && (
              <>
                <Circle radius="10" bkg="red" />
                <Label>Negative</Label>
              </>
            )}
          </Row>
        )}

        {isYT && isTweetView && (
          <Row marginY="10">
            <YouTube
              videoId={data.externalId.replace("YT_", "")}
              ref={videoRef}
            />
          </Row>
        )}

        {isPC && isTweetView && podcast?.audioUrl && (
          <Row marginY="10">
            <AudioPlayer
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showJumpControls={false}
              src={podcast?.audioUrl}
              ref={podcastRef}
            />
          </Row>
        )}

        {/* image for podcast */}
        {isPC && isTweetView && data?.urls?.[0] && (
          <Column marginBottom>
            <Row marginTop="20" center>
              <ImageWrapper maxHeight="300">
                <YtImage src={data.urls[0].url} />
              </ImageWrapper>
            </Row>
          </Column>
        )}

        {isPC && isTweetView && (
          <Column marginTop="10">
            {podcast?.title && (
              <P large bold>
                {podcast.title}
              </P>
            )}
            {podcast?.description && (
              <>
                {insightHighlight({
                  text: podcast.description,
                  excludeBreaks: true,
                  truncateLines: showMore ? 6 : 0,
                  handleTruncate,
                })}
                {showSeeMore && (
                  <Row marginTop="10">
                    <Anchor
                      reverse
                      icon={showMore ? "chevronDown" : "chevronUp"}
                      label={showMore ? "See more" : "See less"}
                      onClick={() => setShowMore(!showMore)}
                    />
                  </Row>
                )}
              </>
            )}
          </Column>
        )}

        {isPC && !isTweetView && (
          <Column marginTop="10">
            <P bold>{data.content.split("\n")[0]}</P>
            {insightHighlight({
              text: data.content.split("\n").slice(1).join("\n"),
              excludeBreaks: true,
              truncateLines: 3,
            })}
          </Column>
        )}
        {data && (
          <Column stretch marginBottom={insightTweet ? "-10" : "0"}>
            {isYT && (
              <Column marginBottom={insightTweet ? "0" : "20"}>
                {!isTweetView && data?.urls?.[0] && (
                  <Row marginTop="20">
                    <ImageWrapper>
                      <YtImage src={data.urls[0].url} />
                    </ImageWrapper>
                  </Row>
                )}

                {data?.content && (
                  <Row marginTop="20">
                    <H4 bold>{data.content.split("\n")[0]}</H4>
                  </Row>
                )}

                <Column className="description" marginTop="20">
                  {isTweetView ? (
                    <>
                      {insightHighlight({
                        text: data.content.split("\n").slice(1).join("\n"),
                        excludeBreaks: true,
                        truncateLines: showMore ? 6 : 0,
                        handleTruncate,
                      })}
                      {showSeeMore && (
                        <Row marginTop="10">
                          <Anchor
                            reverse
                            icon={showMore ? "chevronDown" : "chevronUp"}
                            label={showMore ? "See more" : "See less"}
                            onClick={() => setShowMore(!showMore)}
                          />
                        </Row>
                      )}
                    </>
                  ) : (
                    <>
                      {insightHighlight({
                        text: data.content.split("\n").slice(1).join("\n"),
                        excludeBreaks: true,
                        truncateLines: 3,
                      })}
                    </>
                  )}
                </Column>
              </Column>
            )}

            {isRewteet && (
              <Row gap="10" middle marginY="6">
                <Icon name="retweet" color="gray" fade="6" />
                <Label bold small color="gray" fade="6">
                  Repost
                </Label>
              </Row>
            )}
            {isReply && (
              <Row gap="10" middle marginY="6">
                <Row noShrink>
                  <Icon name="reply" color="gray" fade="6" />
                </Row>
                {replyingToMultiple.length > 3 ? (
                  <Label bold small color="gray" fade="6">
                    Replying to {replyingToMultiple[0]} {replyingToMultiple[1]}{" "}
                    and {replyingToMultiple.length - 2} others
                  </Label>
                ) : (
                  <Label bold small color="gray" fade="6">
                    Reply
                  </Label>
                )}
              </Row>
            )}
            {!isYT && !isPC && (
              <Row marginTop={insightTweet ? "0" : "10"}>
                {to ? (
                  <Link to={to}>
                    <Text large={details}>{text}</Text>
                  </Link>
                ) : (
                  <Text large={details}>{text}</Text>
                )}
              </Row>
            )}
            {showPreview && (
              <Column>
                {selectedLinks[0] && (
                  <>
                    {selectedLinks[0].ogTitle &&
                      selectedLinks[0].ogTitle !== "-1" && (
                        <LinkPreview
                          marginTop="10"
                          marginBottom="15"
                          middle
                          hover
                          style={{ maxWidth: "100%" }}
                        >
                          <Link to={{ pathname: originalUrl }} target="_blank">
                            <Row middle>
                              {selectedLinks[0].ogImage && (
                                <Image src={selectedLinks[0].ogImage} />
                              )}
                              <Column paddingAll gap="5">
                                <Clamp>
                                  <Label>{shortenUrl}</Label>
                                </Clamp>
                                <Clamp value={1}>
                                  <Label bold>{selectedLinks[0].ogTitle}</Label>
                                </Clamp>
                                {selectedLinks[0].ogDescription && (
                                  <Clamp value={2}>
                                    <Label small>
                                      {selectedLinks[0].ogDescription}
                                    </Label>
                                  </Clamp>
                                )}
                              </Column>
                            </Row>
                          </Link>
                        </LinkPreview>
                      )}
                    {/* if preview details not found */}
                    {(!selectedLinks[0].ogTitle ||
                      selectedLinks[0].ogTitle === "-1") && (
                      <Column marginBottom="10" marginTop="-5" left>
                        <P>
                          <Anchor
                            large
                            label={shortenUrl}
                            to={originalUrl}
                            blank
                          />
                        </P>
                      </Column>
                    )}
                  </>
                )}
              </Column>
            )}
            {data?.polls?.length > 0 && (
              <Column marginBottom>
                {data.polls.map((poll) => {
                  const largest = poll.options.reduce((prev, current) =>
                    prev.votes > current.votes ? prev : current
                  );
                  const totalVotes = poll.options
                    .map((option) => option.votes)
                    .reduce((a, b) => a + b, 0);
                  return (
                    <Column key={poll.externalId} gap="5" marginTop="10">
                      {poll.options.map((option) => {
                        const winner = largest.position === option.position;
                        const percentage = (
                          (option.votes / totalVotes) *
                          100
                        ).toFixed(1);
                        return (
                          <Row expand key={option.position} gap="10" middle>
                            <BarWrapper fit paddingX="10" paddingY="7" middle>
                              <Label bold={winner}>{option.label}</Label>
                              <Bar
                                bkg={winner ? "green" : "gray"}
                                fade="3"
                                radius="4"
                                style={{ width: `${percentage}%` }}
                              />
                            </BarWrapper>
                            <Row minWidth="46" right>
                              <Label bold={winner}>{percentage}%</Label>
                            </Row>
                          </Row>
                        );
                      })}

                      <Row gap="10" marginTop="10">
                        <Label color="gray" fade="6" xsmall>
                          {totalVotes} votes
                        </Label>
                        {poll.votingStatus === "closed" && (
                          <Row gap="10">
                            <Label color="gray" fade="6" xsmall>
                              ·
                            </Label>
                            <Label color="gray" fade="6" xsmall>
                              Closed on{" "}
                              {moment(poll.endsAt).format("MMM D, YYYY")}
                            </Label>
                          </Row>
                        )}
                        {poll.votingStatus === "open" && (
                          <Row gap="10">
                            <Label color="gray" fade="6" xsmall>
                              ·
                            </Label>
                            <Label color="gray" fade="6" xsmall>
                              Open until{" "}
                              {moment(poll.endsAt).format("MMM D, YYYY")}
                            </Label>
                          </Row>
                        )}
                      </Row>
                    </Column>
                  );
                })}
              </Column>
            )}
            {hasImages && (
              <Column marginTop="10" stretch>
                <TweetMedia
                  medias={data.medias.filter((a) => {
                    if (
                      a.type === "image" ||
                      a.type === "IMAGE" ||
                      a.type === "photo" ||
                      a.type === "PHOTO"
                    ) {
                      return a.url;
                    }
                    if (a.type === "animated_gif") {
                      return a.previewImageURL;
                    }
                  })}
                  grid
                />
                {hasVideos && (
                  <>
                    {isTweetView ? (
                      <TweetVideos
                        videos={data.medias.filter(
                          (a) => a.type === "video" || a.type === "VIDEO"
                        )}
                      />
                    ) : (
                      <Row marginBottom>
                        <ImageWrapper>
                          <img
                            style={{
                              maxWidth: "100%",
                              maxHeight: "300px",
                            }}
                            src={
                              data.medias.filter(
                                (a) => a.type === "video" || a.type === "VIDEO"
                              )[0]?.previewImageURL
                            }
                          />
                        </ImageWrapper>
                      </Row>
                    )}
                  </>
                )}
              </Column>
            )}

            {!insightTweet && (
              <RowToColumn device="mobileL" marginBottom="-10">
                {data.numReplies > 0 && (
                  <Row marginRight="30" marginBottom="10">
                    <Label
                      id="tweet-replies-number"
                      color="gray"
                      fade="6"
                      xsmall
                    >{`${data.numReplies}+ Replies`}</Label>
                  </Row>
                )}

                {data.numRetweets > 0 && (
                  <Row marginRight="30" marginBottom="10">
                    <Label
                      id="tweet-retweets-number"
                      color="gray"
                      fade="6"
                      xsmall
                    >{`${data.numRetweets}+ Reposts`}</Label>
                  </Row>
                )}
                {data.numLikes > 0 && (
                  <Row marginRight="30" marginBottom="10">
                    <Label
                      id="tweet-likes-number"
                      color="gray"
                      fade="6"
                      xsmall
                    >{`${data.numLikes}+ Likes`}</Label>
                  </Row>
                )}
                {data.numViews > 0 && (
                  <Row marginRight="30" marginBottom="10">
                    <Label
                      id="tweet-views-number"
                      color="gray"
                      fade="6"
                      xsmall
                    >{`${data.numViews}+ Views`}</Label>
                  </Row>
                )}
                {data.externalCreatedAt && !debug && (
                  <Row fit right marginBottom="10">
                    <Label id="tweet-date" color="gray" fade="6" xsmall>
                      {moment(data.externalCreatedAt)
                        .utc()
                        .format("MMM D, YYYY")}
                    </Label>
                  </Row>
                )}
                {data.externalCreatedAt && debug && (
                  <Row
                    fit
                    right
                    marginBottom="10"
                    middle
                    gap="10"
                    title={`Ingested On : ${new Date(
                      data?.createdAt
                    )?.toLocaleString("en-US", { timeZone: "UTC" })}`}
                  >
                    <Column right>
                      <Label color="gray" fade="6" xsmall>
                        {new Date(data.externalCreatedAt).toLocaleDateString(
                          "en-US",
                          { timeZone: "UTC" } // remove this if we want in local timezone
                        )}
                      </Label>
                      <Label color="gray" fade="3" xsmall>
                        {new Date(data.externalCreatedAt).toLocaleTimeString(
                          "en-US",
                          { timeZone: "UTC" } // remove this if we want in local timezone
                        )}
                      </Label>
                    </Column>
                  </Row>
                )}
              </RowToColumn>
            )}

            {debug && (
              <RowToColumn device="mobileL" marginBottom="-10">
                <Row fit right gap="10" marginBottom="10">
                  <Label color="gray" fade="3" xsmall>
                    Ingested On:
                  </Label>
                  <Label color="gray" fade="3" xsmall>
                    {new Date(data.createdAt).toLocaleDateString(
                      "en-US",
                      { timeZone: "UTC" } // remove this if we want in local timezone
                    )}
                  </Label>
                  <Label color="gray" fade="3" xsmall>
                    {new Date(data.createdAt).toLocaleTimeString(
                      "en-US",
                      { timeZone: "UTC" } // remove this if we want in local timezone
                    )}
                  </Label>
                </Row>
              </RowToColumn>
            )}
            {debug && data.lastAnalysis && (
              <RowToColumn device="mobileL" marginBottom="-10">
                <Row fit right gap="10" marginBottom="10">
                  <Label color="gray" fade="3" xsmall>
                    Last Analysis:
                  </Label>
                  <Label color="gray" fade="3" xsmall>
                    {new Date(data.lastAnalysis).toLocaleDateString(
                      "en-US",
                      { timeZone: "UTC" } // remove this if we want in local timezone
                    )}
                  </Label>
                  <Label color="gray" fade="3" xsmall>
                    {new Date(data.lastAnalysis).toLocaleTimeString(
                      "en-US",
                      { timeZone: "UTC" } // remove this if we want in local timezone
                    )}
                  </Label>
                </Row>
              </RowToColumn>
            )}
            {/* for testing and debugging */}
            {(debug || toggleTags) && data?.tagNames && (
              <Row marginTop>
                <Label xsmall color="green" fade="6">
                  {data?.tagNames.replaceAll(",", ", ")}
                </Label>
              </Row>
            )}
          </Column>
        )}
      </Card>
    </Column>
  );
};
