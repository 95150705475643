import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import {
  monitorActions,
  tagActions,
  diseaseActions,
  userActions,
} from "../../store/actions";
import { view } from "./WatchListDetailsView";
import { useGetRegions } from "../../store/queries/regions";
import { updateMonitor } from "../../store/sagas/monitorSagas";
import useDebounce from "../../utils/useDebounce";
import { logAnalyticEvent } from "../../utils";

export const WatchListDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, user } = useParams();
  const { data: initialRegions } = useGetRegions();

  const auth = useSelector((state) => state.auth.data);
  const initialPreferences = useSelector((state) => state.user.preferences);
  const initialMonitor = useSelector((state) => state.monitor.one?.data);
  const diseases = useSelector((state) => state.disease.all.data);
  const userPlans = useSelector((state) => state.plan.userPlans);

  const userId = user || auth.id;

  const [monitor, setMonitor] = useState({});
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [preferences, setPreferences] = useState({});

  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [searchDisease, setSearchDisease] = useState("");
  const debounceSearchDisease = useDebounce(searchDisease, 700);

  const cookies = useSelector((state) => state.settings.cookies);

  useEffect(() => {
    return () => {
      dispatch(monitorActions.oneClear());
    };
  }, []);

  useEffect(() => {
    if (initialMonitor?.id) {
      setMonitor(initialMonitor);
    }
  }, [initialMonitor]);

  useEffect(() => {
    if (auth.active) {
      if (id === "TopInfluencersEmail") {
        dispatch(
          userActions.requestPreferences({
            id: userId,
            name: id,
          })
        );
      } else {
        dispatch(monitorActions.oneRequest(id));
      }
    }
  }, [auth]);

  useEffect(() => {
    if (auth.active && initialRegions?.data?.length > 0) {
      const rgns = [
        {
          id: "ALL",
          name: "All Regions",
          label: "All Regions",
        },
      ];
      initialRegions.data.forEach((row) => {
        rgns.push({
          id: row.id,
          name: row.name,
          label: row.name,
        });
      });
      setRegions(rgns);
    }
  }, [initialRegions?.data]);

  useEffect(() => {
    if (initialPreferences.data?.preferenceName === "TopInfluencersEmail") {
      setPreferences(initialPreferences.data);
    } else {
      setPreferences({
        preferenceName: "TopInfluencersEmail",
        preferenceIntValue: 1,
      });
    }
  }, [initialPreferences.success]);

  // Regions
  useEffect(() => {
    // set selected rules, if any
    if (monitor.id) {
      if (monitor.monitorComponent.object1Type === "PRODUCT") {
        requestDiseases();
      }
      if (monitor.monitorRules?.length > 0) {
        const tagRules = [];
        const regionRules = [];
        _.forEach(monitor.monitorRules, (monitorRule) => {
          if (monitorRule.secondTagName) {
            tagRules.push({
              id: monitorRule.secondTagId,
              label: monitorRule.secondTagName,
            });
          }
          if (monitorRule.region) {
            regionRules.push({
              id: monitorRule.region,
              label:
                regions.find((k) => k.id === monitorRule.region)?.label ||
                monitorRule.region,
            });
          }
        });
        setSelectedDiseases(tagRules);
        setSelectedRegions(regionRules);
      } else {
        setSelectedRegions([]);
      }

      // check for cookies first before publishing event
      if (!auth?.active && cookies?.seen && !cookies?.accepted) {
        return;
      }
      // log event
      logAnalyticEvent(
        "Edit watchlist",
        {
          id: id,
          monitorType: monitor.monitorComponent.object1Type,
          monitorId: monitor.monitorComponent.object1Id,
          monitorName: monitor.monitorComponent.object1Name,
          notificationInterval: monitor.notificationInterval,
        },
        auth?.id || null,
        auth?.displayName || null,
        auth?.email || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || "MEDICAL",
        userPlans?.data?.[0]?.plan?.name || null
      );
    } else {
      // clear selection
      setSelectedDiseases([]);
      setSelectedRegions([]);
    }
  }, [monitor]);

  const requestDiseases = () => {
    dispatch(
      diseaseActions.request({
        ignore: false,
        ...(searchDisease !== "" && { term: searchDisease }),
        maxResult: -1,
      })
    );
  };

  const removeSelectedDiseases = (itemId) => {
    setSelectedDiseases(selectedDiseases?.filter((i) => i.id !== itemId));
    // delete rule
    dispatch(
      tagActions.tagMonitorRuleDelete({
        monitorObjectTagId: monitor.monitorComponent.object1TagId,
        tagId: monitor.monitorComponent.object1TagId,
        secondTagId: itemId,
        userId,
      })
    );
  };

  useEffect(() => {
    requestDiseases();
  }, [debounceSearchDisease]);

  const addSelectedDiseases = (item) => {
    const exists = selectedDiseases?.filter((t) => t.id === item.id);
    // same disease cannot be added twice, show relevant message
    if (exists.length > 0) return;
    setSelectedDiseases([
      ...selectedDiseases,
      { id: item.id, label: item.label },
    ]);

    // create rule
    dispatch(
      tagActions.tagMonitorRuleCreate({
        monitorObjectTagId: monitor.monitorComponent.object1TagId,
        tagId: monitor.monitorComponent.object1TagId,
        userId,
        secondTagId: item.tagId,
        categorizeForMonitor: true,
        displayInGraphs: true,
      })
    );

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // log event
    logAnalyticEvent(
      "Keyword rule edit",
      {
        id: id,
        monitorType: monitor.monitorComponent.object1Type,
        monitorId: monitor.monitorComponent.object1Id,
        monitorName: monitor.monitorComponent.object1Name,
        notificationInterval: monitor.notificationInterval,
        tagId: item.id,
        tagName: item.name,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const addSelectedRegions = (item) => {
    const exists = selectedRegions?.filter((t) => t.id === item.id);
    // same tag cannot be added twice, show relevant message
    if (exists.length > 0) return;
    setSelectedRegions([...selectedRegions, { id: item.id, label: item.name }]);
    // create rule
    dispatch(
      tagActions.tagMonitorRuleCreate({
        monitorObjectTagId: monitor.monitorComponent.object1TagId,
        tagId: monitor.monitorComponent.object1TagId,
        userId,
        region: item.id,
        categorizeForMonitor: true,
        displayInGraphs: true,
      })
    );

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // log event
    logAnalyticEvent(
      "Region rule edit",
      {
        id: id,
        monitorType: monitor.monitorComponent.object1Type,
        monitorId: monitor.monitorComponent.object1Id,
        monitorName: monitor.monitorComponent.object1Name,
        notificationInterval: monitor.notificationInterval,
        region: item.id,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const removeSelectedRegions = (itemId) => {
    setSelectedRegions(selectedRegions?.filter((i) => i.id !== itemId));
    // delete rule
    dispatch(
      tagActions.tagMonitorRuleDelete({
        monitorObjectTagId: monitor.monitorComponent.object1TagId,
        tagId: monitor.monitorComponent.object1TagId,
        userId,
        region: itemId,
      })
    );
  };

  const clearFilters = () => {
    selectedRegions.forEach((region) => {
      removeSelectedRegions(region.id);
    });
    setSelectedDiseases([]);
    setSelectedRegions([]);
    setRegionName([]);
  };

  const handleChangeInterval = async (e) => {
    if (id === "TopInfluencersEmail") {
      dispatch(
        userActions.updatePreferences({
          userId,
          preferenceName: id,
          preferenceIntValue: e.id,
          primary: true,
        })
      );
    } else {
      const newMonitor = { ...monitor, notificationInterval: e.id };
      await updateMonitor({
        id: newMonitor.id,
        visible: newMonitor.visible,
        notificationInterval: newMonitor.notificationInterval,
        userId,
      });

      // check for cookies first before publishing event
      if (!auth?.active && cookies?.seen && !cookies?.accepted) {
        return;
      }
      // log event
      logAnalyticEvent(
        "Email frequency change",
        {
          id: id,
          monitorType: monitor.monitorComponent.object1Type,
          monitorId: monitor.monitorComponent.object1Id,
          monitorName: monitor.monitorComponent.object1Name,
          notificationInterval: newMonitor.notificationInterval,
        },
        auth?.id || null,
        auth?.displayName || null,
        auth?.email || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || "MEDICAL",
        userPlans?.data?.[0]?.plan?.name || null
      );
    }
  };

  const handleDeleteMonitor = async (id) => {
    await updateMonitor({
      id,
      visible: false,
      notificationInterval: monitor.notificationInterval,
      userId,
    });

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // log event
    logAnalyticEvent(
      "Remove from watchlist",
      {
        id: id,
        monitorType: monitor.monitorComponent.object1Type,
        monitorId: monitor.monitorComponent.object1Id,
        monitorName: monitor.monitorComponent.object1Name,
        notificationInterval: monitor.notificationInterval,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );

    setTimeout(() => {
      history.goBack();
    }, 200);
  };

  return view({
    isTopInfluencers: id === "TopInfluencersEmail",
    preferences,
    monitor,
    handleChangeInterval,
    deleteModal,
    toggleDeleteModal,
    handleDeleteMonitor,

    regions,
    selectedRegions,
    addSelectedRegions,
    removeSelectedRegions,

    diseases: diseases?.map((item) => ({ ...item, label: item.primaryName })),
    searchDisease,
    setSearchDisease,
    selectedDiseases,
    addSelectedDiseases,
    removeSelectedDiseases,
    clearFilters,
  });
};
