import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";
import { objectToUrlEncoded } from "../../utils/constants";
import { MAX_RESULTS } from "../../utils/variables";

export function getTweets(data) {
  const {
    tagIds, // to OR keywords
    addTagIds, // to AND keywords
    externalId,
    term,
    originalOnly,
    includeReplies,
    withSentiment,
    sentiment,
    pageNum,
    startDate,
    endDate,
    sortBy,
    maxResult,
    regionName,
    source,
    withTags,
    includeDiseasesForConference,
    diseaseId,
    conferenceId,
  } = data;

  let tagIdList = "";
  if (tagIds?.length > 0) {
    tagIds.forEach((tagId) => {
      tagIdList += `tagId=${tagId}&`;
    });
  }
  let addTagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      addTagIdList += `addTagId=${tagId}&`;
    });
  }
  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  const params = objectToUrlEncoded({
    authorId: externalId,
    term,
    originalOnly,
    includeReplies,
    withSentiment,
    sentiment,
    source,
    startDate,
    endDate,
    pageNum,
    maxResult: maxResult || MAX_RESULTS,
    sortBy,
    sortOrder: "desc",
    withTags,
    includeDiseasesForConference,
  });

  let url = `${getApiPath(false)}`;
  if (diseaseId) {
    url += `/disease/${diseaseId}`;
  }
  if (conferenceId) {
    url += `/conference/${conferenceId}`;
  }
  url += `/tweets?${tagIdList}${addTagIdList}${regionList}${params}`;
  return axios.get(url, {});
}

export function useGetTweets(data, { enabled }) {
  return useQuery(
    [
      `tweets${data?.addTagIds ? `-${data.addTagIds.join("-")}` : ""}${
        data.diseaseId ? `-${data.diseaseId}` : ""
      }${data.conferenceId ? `-${data.conferenceId}` : ""}${
        data.externalId ? `-${data.externalId}` : ""
      }${data.startDate ? `-${data.startDate.split("T")[0]}` : ""}${
        data.endDate ? `-${data.endDate.split("T")[0]}` : ""
      }`,
    ],
    () => getTweets(data),
    {
      refetchOnMount: false,
      cacheTime: enabled ? 300000 : 0,
      enabled,
    }
  );
}

export function getTweet(id) {
  return axios.get(`${getApiPath(false)}/tweet/${id}`, {});
}

export function useGetTweet({ id, enabled }) {
  return useQuery([`tweet-${id}`], () => getTweet(id), {
    refetchOnMount: false,
    cacheTime: enabled ? 300000 : 0,
    enabled,
  });
}
