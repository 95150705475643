import { useEffect } from "react";
import { useSelector } from "react-redux";
import { view } from "./InsightDetailsView";
import { logAnalyticEvent, getUrlParams } from "../../utils";
import { useInfluencersByDisease } from "../../store/queries/influencers";
import useInfiniteScroll from "react-infinite-scroll-hook";

export const InsightDetails = ({ signUpClick }) => {
  const maxResult = 5;
  const queryParams = getUrlParams(location.search);
  const { id } = queryParams;
  const auth = useSelector((state) => state.auth.data);

  const {
    data: summaries,
    isSuccess,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfluencersByDisease(
    {
      objectId: id,
      maxResult,
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.data.length === maxResult ? pages.length : undefined;
      },
    }
  );

  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  const handleMoreSummaries = () => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  };

  const [summariesRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage,
    onLoadMore: handleMoreSummaries,
  });

  useEffect(() => {
    if (summaries?.pages.length > 0) {
      logAnalyticEvent(
        "Landing - Insights",
        {
          page: "Insights",
          name: summaries?.pages?.[0]?.data?.[0]?.name,
        },
        auth?.id || queryParams?.u || null,
        auth?.displayName || queryParams?.n || null,
        auth?.email || queryParams?.e || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue ||
          queryParams?.t ||
          "MEDICAL",
        null
      );
    }
  }, [summaries?.pages]);

  return view({
    signUpClick,
    summaries: summaries?.pages,
    loadingSummaries: isFetchingNextPage,
    isSuccess,
    hasNextPage,
    summariesRef,
  });
};
