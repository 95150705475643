import React from "react";

import { Header, MainWrapper, Content, Column } from "../../../components";
import { CreateUser } from "../../Signup/CreateUser";
import { Menu } from "../Menu";

export const view = ({
  plansList,
  handleUpdatePlan,
  selectedPlan,

  data,
  errors,
  handleUpdateData,
  isSubmitPressed,
  inProgress,
  loading,
  emailSent,
  history,
  step,
  handleNext,
  handleModalBack,

  diseases,
  setSearchDisease,
  searchDisease,
  selectedDiseases,
  addSelectedDisease,
  addSelectedDiseases,
  removeSelectedDiseases,
  clearSelectedDiseases,
  diseasesInfiniteScroll,

  products,
  setSearchProduct,
  searchProduct,
  selectedProducts,
  addSelectedProduct,
  addSelectedProducts,
  removeSelectedProducts,
  clearSelectedProducts,
  productsInfiniteScroll,

  nextDisabled,
  resendEmail,
  pressedResend,
  missingData,
}) => (
  <MainWrapper drawer>
    <Header
      drawer
      close
      bkg="white"
      // rightContent={
      //   <Row>
      //     {isNew && <Button label="Create" onClick={() => handleSave()} />}
      //     {!isNew && (
      //       <Row marginX>
      //         <Anchor
      //           label="Delete"
      //           color="red"
      //           onClick={() => toggleDeleteModal(true)}
      //         />
      //       </Row>
      //     )}
      //   </Row>
      // }
    ></Header>
    <Menu active="users" />

    <Content>
      <Column center middle>
        <CreateUser
          adminPage
          {...{
            plansList,
            handleUpdatePlan,
            selectedPlan,

            data,
            errors,
            handleUpdateData,
            isSubmitPressed,
            inProgress,
            loading,
            emailSent,
            history,
            step,
            handleNext,
            handleModalBack,

            diseases,
            setSearchDisease,
            searchDisease,
            selectedDiseases,
            addSelectedDisease,
            addSelectedDiseases,
            removeSelectedDiseases,
            clearSelectedDiseases,
            diseasesInfiniteScroll,

            products,
            setSearchProduct,
            searchProduct,
            selectedProducts,
            addSelectedProduct,
            addSelectedProducts,
            removeSelectedProducts,
            clearSelectedProducts,
            productsInfiniteScroll,

            nextDisabled,
            resendEmail,
            pressedResend,
            missingData,
          }}
        />
      </Column>
    </Content>
  </MainWrapper>
);
