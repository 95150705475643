import React from "react";
import moment from "moment";

import {
  MainWrapper,
  Header,
  Content,
  Toolbar,
  Card,
  H4,
  Input,
  Dropdown,
  Row,
  Label,
  Anchor,
  DateRange,
  Spinner,
  Switch,
  AutoComplete,
  Grid,
  Column,
  List,
  ListItem,
  Filters,
  TweetCard,
} from "../../../components";
import { LineChart } from "../../../components/LineChart";

import { Menu } from "../Menu";
import { Link } from "react-router-dom";

export const view = ({
  loading,
  tweetTypes,
  selectedTweetType,
  handleTweetTypeChange,
  withTagsOnly,
  setWithTagsOnly,
  activeFilters,

  sentiments,
  selectedSentiment,
  handleSentimentChange,

  sources,
  selectedSource,
  handleSourceChange,

  regions,
  selectedRegion,
  handleRegionChange,

  allAuthors,
  searchAuthor,
  selectedAuthor,
  handleSearchAuthor,
  addSelectedAuthor,
  clearAuthor,
  authorsInfinteScroll,

  tweetsRef,
  tweetsNextPage,
  tweets,
  tweetsCount,
  dates,
  handleDateChange,

  tags,
  searchTag,
  selectedTag,
  handleSearchTag,
  addSelectedTag,
  clearTag,
  tagsInfinteScroll,

  searchTerm,
  handleSearchTermChange,

  chartLoading,
  timeSegmentedSummary,
}) => {
  return (
    <MainWrapper drawer>
      <Header drawer />
      <Menu active="tweets" />

      <Content>
        <Column>
          <Column marginBottom>
            <Filters
              counter={`${tweetsCount} Posts`}
              datePicker={
                <Row gap="10" middle>
                  <Label>from </Label>
                  <DateRange
                    noBackground
                    period
                    initialDate={dates.start}
                    endDate={dates.end}
                    onChange={handleDateChange}
                    noFutureDates
                    allTime
                  />
                </Row>
              }
              {...{ activeFilters }}
              >
              <Grid columns={4}>
                <Column gap="10">
                  <Label bold>Search</Label>
                  <Input
                    expand
                    icon="search"
                    small
                    placeholder="search posts"
                    value={searchTerm}
                    onChange={(e) => {
                      handleSearchTermChange(e.target?.value || null);
                    }}
                  />
                  <Label small>
                    (for better results, please enter at least 4 characters)
                  </Label>
                </Column>
                <Column gap="10">
                  <Label bold>Type</Label>
                  <Dropdown
                    expand
                    right
                    select
                    value={selectedTweetType}
                    items={tweetTypes}
                    onChange={(e) => handleTweetTypeChange(e)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Source</Label>
                  <Dropdown
                    expand
                    select
                    value={selectedSource}
                    items={sources}
                    onChange={(e) => handleSourceChange(e)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Sentiment</Label>
                  <Dropdown
                    expand
                    select
                    value={selectedSentiment}
                    items={sentiments}
                    onChange={(e) => handleSentimentChange(e)}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Tag</Label>
                  <AutoComplete
                    showOnEmptyTerm
                    icon="search"
                    displayTextFromSelected
                    searchResults={tags}
                    setSearchTerm={handleSearchTag}
                    searchTerm={searchTag}
                    selectedItems={selectedTag}
                    addItem={addSelectedTag}
                    clearSearchResults={clearTag}
                    hasClearButton
                    infiniteScroll={tagsInfinteScroll}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Author</Label>
                  <AutoComplete
                    showOnEmptyTerm
                    icon="search"
                    displayTextFromSelected
                    searchResults={allAuthors}
                    setSearchTerm={handleSearchAuthor}
                    searchTerm={searchAuthor}
                    selectedItems={selectedAuthor}
                    addItem={addSelectedAuthor}
                    clearSearchResults={clearAuthor}
                    hasClearButton
                    infiniteScroll={authorsInfinteScroll}
                  />
                </Column>
                <Column gap="10">
                  <Label bold>Region</Label>
                  <Dropdown
                    expand
                    select
                    value={selectedRegion}
                    items={regions}
                    onChange={(e) => handleRegionChange(e)}
                  />
                </Column>
              </Grid>
            </Filters>
          </Column>
          <Toolbar>
            {dates.start && (
              <Label small color="gray" fade="5">
                {moment(dates.start).utcOffset(0, true).format("M/D/YYYY")}
                {" - "}
                {moment(dates.end).utcOffset(0, true).format("M/D/YYYY")}
              </Label>
            )}
          </Toolbar>
          <Row gap="70" right>
            <Switch
              label="With Tags Only"
              right={true}
              checked={withTagsOnly}
              onChange={setWithTagsOnly}
            />
          </Row>
          <Column marginBottom>
            {timeSegmentedSummary.length > 0 ? (
              <Card border>
                <LineChart
                  id={`lineChart`}
                  data={timeSegmentedSummary}
                  handleNameClick={() => {}}
                />
              </Card>
            ) : (
              <>
                {chartLoading && (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                )}
              </>
            )}
          </Column>
          <Column maxWidth="700">
            {tweets.map((t) => {
              const hasQuote = t?.quoted?.length > 0;
              return (
                <Column marginBottom key={t.externalId}>
                  <Link to={`/post/${t.externalId}?source=list`}>
                    <Card border={hasQuote} paddingAll={!hasQuote ? "0" : "20"}>
                      <TweetCard data={t} {...{ hasQuote }} debug sentiment />
                      {hasQuote && (
                        <Column marginTop="30">
                          <TweetCard
                            key={t.quoted[0].externalId}
                            data={t.quoted[0]}
                            debug
                            sentiment
                          />
                        </Column>
                      )}
                    </Card>
                    {/* {t?.urls
                        ?.filter((k) => k.url.indexOf("twitter.com") === -1)
                        .map((url, i) => {
                          const newUrl = decodeURIComponent(url.url);
                          const cleanUrl = newUrl.replace(
                            /(http(s)?:?\/\/(?:www\.|(?!www)))/g,
                            ""
                          );
                          return (
                            <Column
                              marginTop="-5"
                              marginBottom="15"
                              left
                              key={`${t.externalId}-${i}`}
                            >
                              <Anchor
                                label={
                                  cleanUrl.length > 22
                                    ? `${cleanUrl.substring(0, 22)}...`
                                    : cleanUrl
                                }
                                to={newUrl}
                                blank
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </Column>
                          );
                        })}

                      {t?.medias?.length > 0 && (
                        <Row marginTop="10">
                          <TweetMedia medias={t.medias} grid />
                        </Row>
                      )}
                      {t?.quoted?.length > 0 && (
                        <Column stretch>
                          <Row flexWrap expand>
                            {t?.quoted.map((i) => (
                              <TweetCard key={i.externalId} data={i} />
                            ))}
                          </Row>
                        </Column>
                      )}
                    </TweetCard> */}
                  </Link>
                </Column>
              );
            })}
          </Column>
          {loading || tweetsNextPage ? (
            <Row expand paddingAll="40" center ref={tweetsRef}>
              <Spinner />
            </Row>
          ) : (
            <>
              {tweets?.length === 0 && (
                <List center>
                  <ListItem>No Highlights found</ListItem>
                </List>
              )}
            </>
          )}
        </Column>
      </Content>
    </MainWrapper>
  );
};
