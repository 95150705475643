import React from "react";
import styled from "styled-components";

import {
  Column,
  Row,
  Label,
  H4,
  P,
  Footer,
  MainWrapper,
  Metadata,
  Spinner,
  RowToColumn,
  Anchor,
} from "../../components";
import { Color } from "../../utils/variables";
import { HeaderAndDrawer, InsightsCard } from "./components";

const ButtonWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color("gray", "4")};
  background-color: white;
  color: black;
  border-radius: 4px;
  transition: all 0.3s ease;
  width: 44px;
  height: 44px;
  cursor: pointer;

  &:hover {
    background-color: ${Color("gray", "1")} !important;
  }

  svg {
    fill: black !important;
    width: 20px !important;
    height: 20px !important;
  }
`;

export const view = ({
  signUpClick,
  summaries,
  loadingSummaries,
  isSuccess,
  hasNextPage,
  summariesRef,
}) => {
  return (
    <MainWrapper className="dashboard">
      <Metadata />
      <HeaderAndDrawer {...{ signUpClick, activeUrl: "disease_insights" }} />
      <Column marginTop="100">
        <Column paddingBottom="100">
          <Column center>
            <Column maxWidth="700" paddingX>
              <Anchor
                bold
                icon="arrowLeft"
                to="/disease_insights"
                label={`Back ${
                  summaries?.[0]?.data?.[0]?.name
                    ? `to ${summaries[0].data[0].name}`
                    : ""
                }`}
              />
              <Column gap="10">
                {summaries?.[0]?.data?.[0]?.name && (
                  <H4 bold>{summaries[0].data[0].name}</H4>
                )}
                <P color="gray" fade="7">
                  Analysis of top news from HCPs written by our research team at
                  Medical.watch
                </P>
              </Column>
              <RowToColumn>
                <Column stretch>
                  {summaries?.length > 0 && (
                    <Column gap="30" marginTop="40">
                      {summaries?.map((page, index) => (
                        <Column gap="30" key={`${page}-${index}`}>
                          {page?.data?.map((summary) => (
                            <InsightsCard data={summary} key={summary.id} />
                          ))}
                        </Column>
                      ))}
                    </Column>
                  )}

                  {loadingSummaries || hasNextPage ? (
                    <Row expand marginY="40" center ref={summariesRef}>
                      <Spinner />
                    </Row>
                  ) : (
                    <>
                      {summaries?.length === 0 && isSuccess && (
                        <Row marginY="40" expand center>
                          <Label color="gray" fade="7">
                            No influencers found!
                          </Label>
                        </Row>
                      )}
                    </>
                  )}
                </Column>
              </RowToColumn>
            </Column>
          </Column>
        </Column>
        <Footer />
      </Column>
    </MainWrapper>
  );
};
