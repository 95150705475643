import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";

import {
  userActions,
  productActions,
  diseaseActions,
  planActions,
} from "../../../store/actions";
import { view } from "./NewUserView";
import { isValidEmail, logAnalyticEvent, isReady } from "../../../utils";
import useDebounce from "../../../utils/useDebounce";
import { MAX_RESULTS } from "../../../utils/variables";
import { createMonitor } from "../../../store/sagas/monitorSagas";
import { getUserByEmail } from "../../../store/queries/user";

const errorsMessage = {
  firstName: "Required field",
  lastName: "Required field",
  email: "Invalid email, please retry",
  userType: "Please select a role",
};
export const NewUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // --> mapStateToProp
  const plans = useSelector((state) => state.plan.all);
  const basePlans = useSelector((state) => state.plan.basePlans);
  const user = useSelector((state) => state.user.one);
  const initialProducts = useSelector((state) => state.product.tags);
  const initialDiseases = useSelector((state) => state.disease.tags);
  // <-- mapStateToProp

  // --> STATE
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userType: "",
    plan: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [step, setStep] = useState(1);
  const [monitors, setMonitors] = useState([]);

  const [products, setProducts] = useState([]);
  const [pageProducts, setPageProducts] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const debounceSearchProduct = useDebounce(searchProduct, 700);

  const [diseases, setDiseases] = useState([]);
  const [pageDiseases, setPageDiseases] = useState(0);
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [searchDisease, setSearchDisease] = useState("");
  const debounceSearchDisease = useDebounce(searchDisease, 700);

  const [nextDisabled, setNextDisabled] = useState(true);
  const [pressedResend, setPressedResend] = useState(false);
  const [plansList, setPlansList] = useState([]);
  const [defaultPlan, setDefaultPlan] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState([]);
  // <-- STATE

  useEffect(() => {
    dispatch(planActions.request());
    dispatch(planActions.basePlansRequest());

    return () => {
      dispatch(userActions.sendEmailClear());
      dispatch(userActions.clear({}));
    };
  }, []);

  useEffect(() => {
    setPageProducts(0);
    requestProducts(0);
  }, [debounceSearchProduct]);

  useEffect(() => {
    if (isReady(plans) && plans.data.length > 0) {
      const defaultPlan = plans.data.find((p) => p.default);
      setDefaultPlan(defaultPlan);
      // TODO: we are only changing from free to enterprise by now
      // free: true || default: true
      const templans = plans?.data
        ?.filter((a) => a.free || a.default)
        ?.map((p) => ({
          id: p.id,
          label: p.name,
        }));
      setPlansList(templans);
    }
  }, [plans]);

  useEffect(() => {
    if (isReady(basePlans) && defaultPlan) {
      const plan = basePlans?.data?.find(
        (item) => item.name === defaultPlan.name
      );
      plan.id = defaultPlan.id;
      handleSelectedPlan(plan);
    }
  }, [defaultPlan, basePlans?.data]);

  useEffect(() => {
    let newProducts = initialProducts.data.map((tag) => {
      return {
        ...tag,
        label: tag.preferredTagName || tag.name,
        id: tag.productId || tag.preferredTagId || tag.id,
        suggestion: !!tag.preferredTagId,
      };
    });

    newProducts = newProducts.filter((t) => t.productId || t.suggestion);
    setProducts(newProducts);
  }, [initialProducts.data]);

  useEffect(() => {
    setPageDiseases(0);
    requestDiseases(0);
  }, [debounceSearchDisease]);

  useEffect(() => {
    let newDiseases = initialDiseases.data.map((tag) => {
      return {
        ...tag,
        label: tag.preferredTagName || tag.name,
        id: tag.diseaseId || tag.preferredTagId || tag.id,
        suggestion: !!tag.preferredTagId,
      };
    });
    newDiseases = newDiseases.filter((t) => t.diseaseId || t.suggestion);
    setDiseases(newDiseases);
  }, [initialDiseases.data]);

  useEffect(() => {
    if (user.success && user.data?.id) {
      (async () => {
        setInProgress(true);
        // create monitors
        for (var m in monitors) {
          await createMonitor(monitors[m], user.data?.id);
        }
        setInProgress(false);
        console.log(`monitors ${monitors.length} created`);
        setStep(3);

        // log event
        logAnalyticEvent(
          "Sign up admin - Confirm email",
          {},
          user.data?.id,
          user.data?.displayName,
          user.data?.email,
          user.data?.createdAt,
          user.data?.preferences?.filter(
            (p) => p.preferenceName === "UserType"
          )?.[0]?.preferenceStringValue || "MEDICAL",
          basePlans?.data?.find(
            (item) => item.id === data.plan
          )?.name || null,
        );
      })();
    }
  }, [user.success]);

  const requestProducts = (pageNum) => {
    dispatch(
      productActions.tagsRequest({
        ignore: false,
        maxResult: MAX_RESULTS,
        // term: searchProduct || "a",
        ...(searchProduct !== "" && { term: searchProduct }),
        pageNum,
      })
    );
  };

  const handleMoreProducts = () => {
    if (!initialProducts.fetching) {
      const productsNewPage = pageProducts + 1;
      setPageProducts(productsNewPage);
      requestProducts(productsNewPage);
    }
  };

  const addSelectedProduct = (item) => {
    setSelectedProducts([
      {
        id: item.productId,
        label: item.label,
      },
    ]);
  };

  const addSelectedProducts = (item) => {
    if (item) {
      setSelectedProducts([
        ...selectedProducts,
        {
          id: item.productId,
          label: item.label,
        },
      ]);
    }
  };

  const removeSelectedProducts = (item) => {
    const slectedItems = selectedProducts.filter((i) => i.id !== item.id);
    setSelectedProducts(slectedItems);
  };

  const clearSelectedProducts = () => {
    setSelectedProducts([]);
    setSearchProduct("");
  };

  const requestDiseases = (pageNum) => {
    dispatch(
      diseaseActions.tagsRequest({
        ignore: false,
        maxResult: MAX_RESULTS,
        ...(searchDisease !== "" && { term: searchDisease }),
        pageNum,
      })
    );
  };

  const handleMoreDiseases = () => {
    if (!initialDiseases.fetching) {
      const diseasesNewPage = pageDiseases + 1;
      setPageDiseases(diseasesNewPage);
      requestDiseases(diseasesNewPage);
    }
  };

  const addSelectedDisease = (item) => {
    setSelectedDiseases([
      {
        id: item.diseaseId,
        label: item.label,
      },
    ]);
  };

  const addSelectedDiseases = (item) => {
    if (item) {
      setSelectedDiseases([
        ...selectedDiseases,
        {
          id: item.diseaseId,
          label: item.label,
        },
      ]);
    }
  };

  const removeSelectedDiseases = (item) => {
    const slectedItems = selectedDiseases.filter((i) => i.id !== item.id);
    setSelectedDiseases(slectedItems);
  };

  const clearSelectedDiseases = () => {
    setSelectedDiseases([]);
    setSearchDisease("");
  };

  useEffect(() => {
    isNextDisabled();
  }, [selectedProducts, selectedDiseases, data, step]);

  useEffect(() => {
    if (user.error?.data?.message) {
      setErrors([user.error?.data?.message]);
    }
    setInProgress(false);
  }, [user.error]);

  const checkErrors = () => {
    let newErrors = {};
    for (const item in errorsMessage) {
      if (item === "email") {
        if (isValidEmail(data.email)) {
          delete newErrors.email;
        } else {
          newErrors.email = errorsMessage.email;
        }
      } else {
        if (data[item] === "") {
          newErrors[item] = errorsMessage[item];
        } else {
          delete newErrors[item];
        }
      }
    }
    setErrors(newErrors);
  };

  const handleSelectedPlan = (plan) => {
    setSelectedPlan({
      diseases: plan?.planFeatures?.filter(
        (item) => item.name === "DISEASE"
      )?.[0]?.value,
      products: plan?.planFeatures?.filter(
        (item) => item.name === "PRODUCT"
      )?.[0]?.value,
    });

    setData((oldData) => ({
      ...oldData,
      plan: plan.id,
    }));
  };

  const handleUpdateData = ({ field, value }) => {
    setData((oldData) => ({
      ...oldData,
      [field]: value,
    }));
    if (field === "email") {
      if (isValidEmail(value)) {
        setErrors((oldErrors) => {
          const newErrors = { ...oldErrors };
          delete newErrors.email;
          return newErrors;
        });
      } else {
        setErrors((oldErrors) => ({
          ...oldErrors,
          email: errorsMessage.email,
        }));
      }
    }
  };

  const debounceSubmit = debounce(() => {
    var aiChatValue = 0;
    // enable AIChat for all users except sales and eisai users
    if (data.userType != "SALES" && data.email.indexOf("@eisai") < 0) {
      aiChatValue = 1;
    }
    setInProgress(true);
    dispatch(
      userActions.create({
        firstName: data.firstName,
        lastName: data.lastName,
        displayName: `${data.firstName} ${data.lastName}`,
        email: data.email,
        preferences: [
          {
            preferenceName: "UserType",
            preferenceStringValue: data.userType,
            primary: true,
          },
          {
            preferenceName: "AIChat",
            preferenceIntValue: aiChatValue,
            primary: true,
          },
        ],
        active: true,
        password: "Test1234",
        mwRole: "USER",
        ...(data.plan && {
          userPlans: [
            {
              planId: data.plan,
            },
          ],
        }),
      })
    );
  }, 1000);

  const submit = () => {
    setIsSubmitPressed(true);
    if (isEmpty(errors)) {
      debounceSubmit();
    }
  };

  const resendEmail = () => {
    if (data.email) {
      dispatch(
        userActions.resendSignup({
          email: data.email,
        })
      );

      setPressedResend(true);
    }
  };

  const handleModalBack = () => {
    if (step === 2) {
      clearSelectedDiseases();
      clearSelectedProducts();
    }
    setStep(step - 1);
  };

  const handleNext = () => {
    if (step === 1) {
      if (data.email) {
        getUserByEmail(data.email).then((userResp) => {
          if (userResp.data?.id) {
            setIsSubmitPressed(true);
            setErrors((oldErrors) => ({
              ...oldErrors,
              email: "This email already exists",
            }));
          } else {
            // log event
            logAnalyticEvent(
              "Sign up admin - User info", 
              {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                type: data.userType,
              },
              null,
              `${data.firstName} ${data.lastName}`,
              data.email,
              null,
              data.userType,
              basePlans?.data?.find(
                (item) => item.id === data.plan
              )?.name || null,
            );
            // user not found, so we can proceed with step2
            setStep(2);
          }
        });
      }
    }

    if (step === 2) {
      const tempMonitors = [];

      selectedProducts.forEach((p) => {
        tempMonitors.push({
          monitorComponent: {
            object1Type: "PRODUCT",
            object1Id: p.id,
          },
          notificationIntervalId: "2",
          notificationInterval: "24",
          notificationType: "EMAIL",
          visible: true,
        });
      });

      selectedDiseases.forEach((d) => {
        tempMonitors.push({
          monitorComponent: {
            object1Type: "DISEASE",
            object1Id: d.id,
          },
          notificationIntervalId: "1",
          notificationInterval: "168",
          notificationType: "EMAIL",
          visible: true,
        });
      });

      setMonitors(tempMonitors);

      // log event
      logAnalyticEvent(
        "Sign up admin - Monitors",
        {
          email: data.email,
          diseases: selectedDiseases.map((a) => a.label).join(","),
          products: selectedProducts.map((b) => b.label).join(","),
        },
        null,
        `${data.firstName} ${data.lastName}`,
        data.email,
        null,
        data.userType,
        basePlans?.data?.find(
          (item) => item.id === data.plan
        )?.name || null,
      );
      submit();
    }
  };

  const isNextDisabled = () => {
    if (step === 2) {
      if (selectedDiseases.length > 0 && isValidEmail(data.email)) {
        setNextDisabled(false);
        return;
      }
    }
    if (
      step === 1 &&
      data.firstName.length > 1 &&
      data.lastName.length > 1 &&
      // data.email.length > 1 &&
      isValidEmail(data.email) &&
      data.userType !== ""
    ) {
      setNextDisabled(false);
      return;
    }
    setNextDisabled(true);
  };

  const handleUpdatePlan = (plan) => {
    if (basePlans?.data?.length > 0) {
      const basePlan = basePlans?.data?.find(
        (item) => item.name === plan.label
      );
      basePlan.id = plan.id;
      if (basePlan) {
        handleSelectedPlan(basePlan);
      }
    }
  };

  return view({
    data,
    errors,
    handleUpdateData,
    submit,
    isSubmitPressed,
    inProgress,
    loading: user.fetching,
    emailSent: user.data,
    history,
    step,
    handleNext,
    handleModalBack,

    diseases,
    setSearchDisease,
    searchDisease,
    selectedDiseases,
    addSelectedDisease,
    addSelectedDiseases,
    removeSelectedDiseases,
    clearSelectedDiseases,
    diseasesInfiniteScroll: {
      loading: initialDiseases.fetching,
      hasNextPage: !initialDiseases.isLastPage,
      onLoadMore: handleMoreDiseases,
    },

    products,
    setSearchProduct,
    searchProduct,
    selectedProducts,
    addSelectedProduct,
    addSelectedProducts,
    removeSelectedProducts,
    clearSelectedProducts,
    productsInfiniteScroll: {
      loading: initialProducts.fetching,
      hasNextPage: !initialProducts.isLastPage,
      onLoadMore: handleMoreProducts,
    },

    nextDisabled,
    resendEmail,
    pressedResend,
    missingData:
      !monitors?.[0]?.monitorComponent?.object1Type &&
      !monitors?.[0]?.monitorComponent?.object1Id,

    plansList,
    handleUpdatePlan,
    selectedPlan,
  });
};
