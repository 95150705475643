import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import {
  Row,
  Column,
  MainWrapper,
  Content,
  TextLogo,
  Metadata,
  H2,
  H3,
  RowToColumn,
  Button,
  Input,
  Label,
  Anchor,
  Footer,
} from "../../components";
import { Color, device } from "../../utils/variables";
import { isValidEmail, getUrlParams, logAnalyticEvent } from "../../utils";
import { getUserByEmail } from "../../store/queries/user";

const Wrapper = styled(Column)`
  position: relative;
  width: 100vw;
  max-width: 100% !important;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
`;

const LeftSide = styled(Column)`
  margin: 0;
  width: 100%;
  max-width: 640px;
  padding: 20px;

  @media ${device.tablet} {
    padding: 40px;
    height: 850px;
    width: 55%;
  }
`;

const RightSide = styled(Column)`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: ${Color("green", 5)};
  width: 45%;
  height: 850px;
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
`;

const Image = styled(Column)`
  background: url("/images/home/phone.png") left center / cover no-repeat;
  height: 100%;
  width: 100%;
`;

const Form = styled(Column)`
  display: flex;
`;

export const Signup5 = ({}) => {
  const [queryParams] = useState(getUrlParams(location.search));
  const [source] = useState(queryParams?.source);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const submit = (e) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      logAnalyticEvent(
        "Subscribe",
        {
          page: "Signup5",
          email,
          ...(source && {source: source}),
        },
        null,
        null,
        email
      );
      getUserByEmail(email).then((userResp) => {
        if (userResp.data?.id) {
          setError(
            "This email already exists. Please use the 'Sign in' link below"
          );
        } else {
          // user not found, so we can proceed with signup
          window.location.href = "/signup?email=" + encodeURIComponent(email);
        }
      });
    } else {
      setError("Invalid email");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  useEffect(() => {
    logAnalyticEvent("Landing", {
      page: "Signup5",
      ...(source && {source: source}),
    });
  }, []);

  return (
    <MainWrapper noHeader>
      <Metadata />
      <Content paddingAll="0">
        <Wrapper middle>
          <Form onSubmit={(e) => submit(e)}>
            <LeftSide gap="20" middle>
              <TextLogo large />
              <Column gap="30">
                <H2 bold>
                  Discover what physicians are saying on social media
                </H2>
                <H3 color="gray" fade="8">
                  Get oncology/hematology highlights in your inbox. Never miss
                  practice-changing news!
                </H3>
                <RowToColumn gap="20" columnGap="30" device="laptopL">
                  <Input
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                    errorMessage={error}
                  />
                  <Row noShrink>
                    <Button
                      type="submit"
                      large
                      label="Subscribe for free"
                      onClick={(e) => submit(e)}
                    />
                  </Row>
                </RowToColumn>
                <Row gap="10">
                  <Label color="gray" fade="8" large>
                    Existing user?
                  </Label>
                  <Anchor bold to="/login" large label="Sign in" />
                </Row>
              </Column>
            </LeftSide>
            <RightSide>
              <Image />
            </RightSide>
          </Form>
          <Footer />
        </Wrapper>
      </Content>
    </MainWrapper>
  );
};
