import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import {
  Row,
  Column,
  MainWrapper,
  Metadata,
  H2,
  H3,
  H4,
  RowToColumn,
  Button,
  Input,
  Header,
  P,
  Label,
  Anchor,
  Grid,
  Footer,
} from "../../components";
import { isValidEmail, getUrlParams, logAnalyticEvent } from "../../utils";

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Image1 = styled(Column)`
  background: url("/images/home/home2_1.jpg") left center / cover no-repeat;
  height: 100%;
  width: 100%;
`;

export const Signup2 = ({}) => {
  const [queryParams] = useState(getUrlParams(location.search));
  const [source] = useState(queryParams?.source);
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");

  const auth = useSelector((state) => state.auth.data);
  const cookies = useSelector((state) => state.settings.cookies);

  const submit = (e) => {
    e.preventDefault();
    if (isValidEmail(email1, true) || isValidEmail(email2, true)) {
      const email = isValidEmail(email1, true) ? email1 : email2;
      logAnalyticEvent("Landing", { 
        page: "Signup2", 
        email,
        ...(source && {source: source}),
      });
      window.location.href = "/signup?email=" + encodeURIComponent(email);
    } else {
      if (!isValidEmail(email1, true) && email1 !== "") {
        setError1("Invalid email");
      }
      if (!isValidEmail(email2, true) && email2 !== "") {
        setError2("Invalid email");
      }
    }
  };

  const handleEmailChange = (e, field) => {
    field === 1 ? setEmail1(e.target.value) : setEmail2(e.target.value);
    setError1("");
    setError2("");
  };

  useEffect(() => {
    logAnalyticEvent("Landing", {
      page: "Signup2",
      ...(source && {source: source}),
    });
  }, []);

  return (
    <MainWrapper>
      <Metadata />
      <Header
        centerContent={
          <Row expand center>
            <img
              id="mw-logo"
              width="200px"
              src="/medicalwatch.png"
              alt="Medical.watch"
            />
          </Row>
        }
      />

      <Column paddingTop="63">
        <Form onSubmit={(e) => submit(e)}>
          <Column bkg="gray" fade="1" center>
            <RowToColumn maxWidth="1020" gap="30">
              <Column paddingAll="20" gap="30">
                <H2 bold>
                  See what physicians are saying about your brand on social
                  media
                </H2>
                <H4 color="gray" fade="8">
                  Get brand activity reports in your inbox. Find doctors for
                  partnership opportunities.
                </H4>
                <RowToColumn gap="20" columnGap="26">
                  <Input
                    small
                    placeholder="Enter email"
                    value={email1}
                    onChange={(e) => handleEmailChange(e, 1)}
                    errorMessage={error1}
                  />
                  <Row noShrink marginTop="4">
                    <Button type="submit" label="Subscribe for free" />
                  </Row>
                </RowToColumn>
                <Row gap="10">
                  <Label color="gray" fade="8" large>
                    Existing user?
                  </Label>
                  <Anchor bold to="/login" large label="Sign in" />
                </Row>
              </Column>
              <Image1 />
            </RowToColumn>
          </Column>
          <Column center paddingY="60" paddingX>
            <Column maxWidth="1020" gap="20" center>
              <H2 bold center>
                What to expect
              </H2>
              <H4 color="gray" fade="8">
                Here's what's hitting your inbox in Medical.watch
              </H4>
              <Grid columns="3" gap="30" marginTop="10">
                <Column gap="20">
                  <img src="/images/home/home2_2.jpg" />
                  <H3 bold>Brand activity reports</H3>
                  <P>
                    Discover what physicians say about your brand. Influence
                    your late adopters.
                  </P>
                </Column>
                <Column gap="20">
                  <img src="/images/home/home2_3.jpg" />
                  <H3 bold>Daily congress highlights</H3>
                  <P>
                    Get daily reports for medical congresses. Never miss
                    practice changing news!
                  </P>
                </Column>
                <Column gap="20">
                  <img src="/images/home/home2_4.jpg" />
                  <H3 bold>Weekly disease reports</H3>
                  <P>
                    See who's driving online discussions. Reach out for
                    scientific collaborations.
                  </P>
                </Column>
              </Grid>
            </Column>
          </Column>
          <Column center paddingY="60" paddingX bkg="green" fade="6">
            <Column maxWidth="600" gap="20" center color="white">
              <H2 bold center>
                Medical.watch
              </H2>
              <H4 center>Get the latest updates from social media</H4>
              <RowToColumn
                gap="20"
                columnGap="26"
                marginTop="30"
                device="tablet"
              >
                <Input
                  small
                  transparent
                  placeholder="Enter email"
                  value={email2}
                  onChange={(e) => handleEmailChange(e, 2)}
                  errorMessage={error2}
                />
                <Row noShrink marginTop="4">
                  <Button secondary type="submit" label="Subscribe for free" />
                </Row>
              </RowToColumn>
            </Column>
          </Column>
        </Form>
        <Footer />
      </Column>
    </MainWrapper>
  );
};
