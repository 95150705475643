import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { uniqBy } from "lodash";
import useInfiniteScroll from "react-infinite-scroll-hook";

import useDebounce from "../../../utils/useDebounce";
import { MAX_RESULTS } from "../../../utils/variables";

import {
  tagActions,
  diseaseActions,
  productActions,
  conferenceActions,
  commonActions,
  settingsActions,
} from "../../../store/actions";
import {
  cancelTagRequests,
  cancelDiseaseRequests,
  cancelProductRequests,
  cancelConferenceRequests,
  cancelCommonRequests,
} from "../../../store/sagas";

import { view } from "./TagsView";

export const AdminTags = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // --> mapStateToProp
  const tags = useSelector((state) => state.tag.adminSummary);
  const products = useSelector((state) => state.product.all.data);
  const diseases = useSelector((state) => state.disease.all.data);
  const conferences = useSelector((state) => state.conference.all.data);
  const meaningState = useSelector((state) => state.common.meanings);
  const tagSettings = useSelector((state) => state.settings?.tag);
  // <-- mapStateToProp

  // --> STATE
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm, 700);

  const [meaningItems, setMeaningItems] = useState([
    {
      id: "ALL",
      label: "All",
    },
  ]);
  const [selectedMeaningId, setSelectedMeaningId] = useState(
    tagSettings?.meaning || "ALL"
  );

  const [statusItems] = useState([
    { id: "ALL", label: "All" },
    { id: "ACTIVE", label: "Active" },
    { id: "PREFERRED", label: "Preferred" },
    { id: "MERGED", label: "Merged" },
    { id: "BLACKLISTED", label: "Blacklisted" },
    { id: "REVIEWED", label: "Reviewed" },
    { id: "NEEDSREVIEWED", label: "Needs Review" },
  ]);
  const [selectedStatusId, setSelectedStatusId] = useState(
    tagSettings?.status || "ALL"
  );

  const [includeStats, setIncludeStats] = useState(false);
  const [loading, setLoading] = useState(false);
  // <-- STATE

  const requestTags = () => {
    let ignore = null;
    let preferred = null;
    let reviewed = null;
    switch (selectedStatusId) {
      case "ACTIVE": // active (non-blacklisted & non-whitelisted)
        ignore = false;
        break;
      case "PREFERRED": // active, but only substitutes
        ignore = false;
        preferred = true;
        break;
      case "MERGED": // whitelisted
        ignore = false;
        preferred = false;
        break;
      case "BLACKLISTED": // blacklisted
        ignore = true;
        break;
      case "REVIEWED": // reviewed
        reviewed = true;
        break;
      case "NEEDSREVIEWED": // needs reviewed
        reviewed = false;
        break;
    }

    dispatch(
      tagActions.adminSummaryRequest({
        ...(selectedMeaningId !== "ALL" && { meaning: selectedMeaningId }),
        ...(searchTerm !== "" && { term: searchTerm }),
        ignore,
        preferred,
        reviewed,
        withStats: includeStats,
      })
    );
  };

  const handleStatusChange = (item) => {
    setSelectedStatusId(item.id);
    dispatch(
      settingsActions.set({
        tag: { ...tagSettings, status: item.id },
      })
    );
  };

  const handleMeaningChange = (item) => {
    setSelectedMeaningId(item.id);
    dispatch(
      settingsActions.set({
        tag: { ...tagSettings, meaning: item.id },
      })
    );
  };

  // --> EFFECT
  useEffect(() => {
    return () => {
      dispatch(conferenceActions.clearAll());
      dispatch(tagActions.clearTags());

      cancelTagRequests.abort();
      cancelDiseaseRequests.abort();
      cancelProductRequests.abort();
      cancelConferenceRequests.abort();
      cancelCommonRequests.abort();
    };
  }, []);

  useEffect(() => {
    // get meanings only if not found
    if (Object.keys(meaningState)?.length == 0)
      dispatch(commonActions.requestMeanings());
    dispatch(diseaseActions.request({ maxResult: -1 }));
    dispatch(productActions.request({ maxResult: -1 }));
    dispatch(conferenceActions.request({ maxResult: -1 }));
  }, [dispatch]);

  useEffect(() => {
    if (meaningState?.length > 0) {
      const newMeanings = [];
      for (const key in meaningState) {
        newMeanings.push({
          id: meaningState[key].enumStringValue,
          label: meaningState[key].enumValueDisplayName,
        });
      }
      setMeaningItems([{ id: "ALL", label: "All" }, ...newMeanings]);
    }
  }, [meaningState]);

  useEffect(() => {
    requestTags();
  }, [debounceTerm, includeStats, selectedStatusId, selectedMeaningId]);

  useEffect(() => {
    setLoading(tags.fetching);
  }, [tags.fetching]);
  // <-- EFFECT

  const getActiveFilters = () => {
    let activeFilters = [];
    if (searchTerm) {
      activeFilters.push(`Search: ${searchTerm}`);
    }
    if (selectedStatusId !== "ALL") {
      activeFilters.push(
        `Status: ${statusItems.find((a) => selectedStatusId === a.id)?.label}`
      );
    }
    if (selectedMeaningId !== "ALL") {
      activeFilters.push(
        `Meaning: ${
          meaningItems.find((a) => selectedMeaningId === a.id)?.label
        }`
      );
    }

    return activeFilters;
  };

  return view({
    history,
    loading,
    tags: tags.data || [],
    tagsCount: tags.data?.length /*tagsCount.data*/ || 0,
    activeFilters: getActiveFilters(),

    searchTerm,
    setSearchTerm,

    meaningItems,
    selectedMeaningId,
    handleMeaningChange,

    selectedStatusId,
    handleStatusChange,
    statusItems,

    includeStats,
    setIncludeStats,

    diseases,
    products,
    conferences,
  });
};
