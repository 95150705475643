import React from "react";
import styled from "styled-components";

import { Icon } from "./Icon";
import { Label } from "./Typography";
import { Row, Column } from "./Layout";
import { Color } from "../utils/variables";
import PropTypes from "prop-types";

export const Collapsible = (props) => {
  const [isListVisible, setListVisibility] = React.useState(
    props.open || false
  );

  React.useEffect(() => {
    setListVisibility(props.open);
  }, [props.open]);

  const toggleList = React.useCallback(() => {
    setListVisibility(!isListVisible);
    props.onChange && props.onChange(!isListVisible);
  });

  return (
    <Container {...props} className={isListVisible ? "visible" : ""}>
      {props.render ? (
        props.render({ toggleList, isListVisible })
      ) : (
        <CollapsibleButton
          noBorder={props.noBorder}
          paddingX={props.noBorder ? "0" : "10"}
          paddingY="10"
          radius="10"
        >
          <Button onClick={() => toggleList()}>
            <Row noShrink>
              <Icon name="arrowDropDown" className="icon" />
            </Row>
            <Column>
              <Label bold={props.noBorder} large={!props.small}>
                {props.label}
              </Label>
            </Column>
          </Button>
          <Row fit right marginLeft="5">
            {props.rightAction}
          </Row>
        </CollapsibleButton>
      )}
      {isListVisible && <Card {...props}>{props.children}</Card>}
    </Container>
  );
};

const CollapsibleButton = styled(Row)`
  border: ${(props) =>
    props.noBorder ? "none" : `1px solid ${Color("gray", "3")}`};
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${Label} {
    padding-left: ${(props) => (props.small ? "5px" : "10px")};
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  pointer-events: none;
  opacity: 0;
  height: 0;
  background: ${Color("gray", "0.5")};
  border-radius: 0 0 10px 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${(props) => props.expand && "width: 100%"};

  &.visible {
    ${Card} {
      opacity: 1;
      pointer-events: all;
      height: 100%;
      border: 1px solid ${Color("gray", "3")};
      margin-top: -1px;
    }

    .icon {
      transform: rotate(180deg);
    }

    ${CollapsibleButton} {
      border-radius: 10px 10px 0 0;
      overflow: visible;
    }
  }
`;

Collapsible.propTypes = {
  label: PropTypes.string,
  render: PropTypes.func,
  rightAction: PropTypes.node,
  children: PropTypes.node,
  small: PropTypes.bool,
};
