import { useEffect } from "react";
import { useSelector } from "react-redux";
import { view } from "./CongressDetailsView";
import { useParams } from "react-router-dom";
import {
  logAnalyticEvent,
  getUrlParams,
  getISOFormattedDateTimeString,
} from "../../utils";
import {
  useGetConferenceDols,
  useGetConference,
} from "../../store/queries/conference";
import { useGetTweets } from "../../store/queries/tweets";
import moment from "moment";

export const CongressDetails = ({ signUpClick }) => {
  const queryParams = getUrlParams(location.search);
  const { id } = useParams();
  const auth = useSelector((state) => state.auth.data);

  const { data: conference, isLoading: loadingConference } =
    useGetConference(id, true);

  const {
    data: influencers,
    isLoading,
    isSuccess,
  } = useGetConferenceDols({
    id,
    startDate: getISOFormattedDateTimeString(moment().startOf("year")),
    endDate: getISOFormattedDateTimeString(
      moment().subtract(1, "day").endOf("day")._d
    ),
    maxResult: 10,
  });

  const { data: tweets, isLoading: isLoadingTweets } = useGetTweets(
    {
      ...(conference?.data?.tagId && {
        addTagIds: [conference.data.tagId, conference.data.parentTagId],
      }),
      // externalId: influencers?.data?.[0]?.account?.externalId,
      ...(auth?.id && { userId: auth.id }),
      maxResult: 1,
      pageNum: 0,
      sortBy: "score",
      sortOrder: "desc",
      includeDiseasesForConference: true,
      originalOnly: false,
      includeReplies: true,
      startDate: getISOFormattedDateTimeString(
        conference?.data?.monitorStartTime
      ),
      endDate: getISOFormattedDateTimeString(
        moment(conference?.data?.monitorEndTime)?.add(7, "day")?._d
      ),
    },
    {
      enabled: !!conference,
    }
  );

  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (conference?.data) {
      logAnalyticEvent(
        "Landing - Top influencers",
        {
          page: "Conference",
          name: conference?.data?.primaryName,
        },
        auth?.id || queryParams?.u || null,
        auth?.displayName || queryParams?.n || null,
        auth?.email || queryParams?.e || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue ||
          queryParams?.t ||
          "MEDICAL",
        null
      );
    }
  }, [conference?.data]);

  const topInfluencers = influencers?.data?.slice(0, 15);

  return view({
    signUpClick,
    influencers: topInfluencers,
    isLoading,
    isSuccess,
    tweet: tweets?.data?.[0],
    isLoadingTweets,
    conference: conference?.data,
  });
};
