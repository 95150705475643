import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { view } from "./AiChatView";
import useKeyPress from "../../utils/useKeyPress";
import { logAnalyticEvent } from "../../utils";
import {
  useSendChat,
  useGetPreviousChats,
  useGetPreviousChat,
} from "../../store/queries/ai";

// const defaultParams = {
//   chatsize: 6,
//   score: 0.45,
//   maxresults: 6,
// };

export const AiChat = () => {
  const history = useHistory();
  const { id } = useParams();
  const scrollRef = useRef(null);
  const bodyRef = useRef(null);
  const enterPress = useKeyPress("Enter");

  const auth = useSelector((state) => state.auth.data);
  const initialExamples = useSelector((state) => state.ai.chat);
  const userPlans = useSelector((state) => state.plan.userPlans);
  const monitors = useSelector((state) => state.monitor.auth?.data || []);

  const [chatId, setChatId] = useState(id);
  const [data, setData] = useState([]);
  const [question, setQuestion] = useState("");
  const [waitForQuestion, setWaitForQuestion] = useState("");
  const [showAll, setShowAll] = useState(false);
  // const [regenerate, setRegenerate] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [formattedChat, setFormattedChat] = useState(null);

  const [examples, setExamples] = useState(initialExamples);
  // const [params, setParams] = useState(defaultParams);

  const {
    mutateAsync: chat,
    isLoading,
    isSuccess,
  } = useSendChat({
    question,
    chatId,
    // params: auth?.mwRole === "ADMIN" ? params : null,
  });
  const { data: chats } = useGetPreviousChats();
  const {
    data: previousChat,
    isSuccess: prevSuccess,
    refetch: refetchPreviousChat,
  } = useGetPreviousChat({
    chatId: chatId,
  });

  const sendChat = async () => {
    const resp = await chat({
      question,
      chatId,
      // params,
    });
    if (resp.data?.chat_id) {
      // const previousData = [...data];
      setChatId(resp.data.chat_id);
      setWaitForQuestion("");
      setQuestion("");
      if (!id) {
        setData([resp.data]);
        history.push(`/aiChat/${resp.data.chat_id}`);
      } else if (id !== resp.data.chat_id.toString()) {
        window.location.href = `/aiChat/${resp.data.chat_id}`;
      } else {
        // setData([resp.data]);
        // setData([...previousData, resp.data]);
        refetchPreviousChat();
      }
    }
  };

  useEffect(() => {
    // if previous page is 'aiChat', do not push amplitude event
    if (document.referrer && document.referrer?.indexOf("aiChat") < 0) {
      logAnalyticEvent(
        "AI Chat",
        {},
        auth?.id || null,
        auth?.displayName || null,
        auth?.email || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || "MEDICAL",
        userPlans?.data?.[0]?.plan?.name || null
      );
    }
  }, []);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTo(0, 0);
    }
  }, [id]);

  useEffect(() => {
    const tempMonitors = monitors
      .filter((m) => m.visible && m.monitorComponent.object1Type === "DISEASE")
      .map((m) => m.monitorComponent.object1Name);

    if (tempMonitors.length > 0) {
      const firstRandomMonitor =
        tempMonitors[Math.floor(Math.random() * tempMonitors.length)];

      let secondRandomMonitor = firstRandomMonitor;
      if (tempMonitors.length > 1) {
        const remainingMonitors = tempMonitors.filter(
          (m) => m !== firstRandomMonitor
        );
        secondRandomMonitor =
          remainingMonitors[
            Math.floor(Math.random() * remainingMonitors.length)
          ];
      }

      const tempExamples = [...initialExamples];
      tempExamples[1].text = tempExamples[1].text.replace(
        "Acute Lymphocytic Leukemia",
        firstRandomMonitor
      );
      tempExamples[2].text = tempExamples[2].text.replace(
        "Hepatocellular Carcinoma",
        secondRandomMonitor
      );

      setExamples(tempExamples);
    }
  }, [monitors]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [waitForQuestion]);

  useEffect(() => {
    if (enterPress && question) {
      handleSubmitNewChat();
    }
  }, [enterPress]);

  const handleSubmitNewChat = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (question) {
      sendChat();

      logAnalyticEvent(
        "AI Chat - User Prompt",
        {
          prompt: question,
          ...(chatId && { chatId }),
        },
        auth?.id || null,
        auth?.displayName || null,
        auth?.email || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue || "MEDICAL",
        userPlans?.data?.[0]?.plan?.name || null
      );
    }

    if (!waitForQuestion) {
      setWaitForQuestion(question);
      setQuestion("");
    }
  };

  const handleRegenerate = () => {
    setQuestion("Give me more details");
    setTimeout(() => {
      setWaitForQuestion("Give me more details");
      sendChat();
    }, 200);

    logAnalyticEvent(
      "AI Chat - Regenerate",
      {},
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const handleExampleClick = (id) => {
    const selectedExample = initialExamples.find(
      (item) => item.id === id
    )?.text;
    if (selectedExample) {
      setWaitForQuestion(selectedExample);
      setQuestion(selectedExample);
      setChatId(null);
      setTimeout(() => {
        sendChat();
      }, 200);
    }

    logAnalyticEvent(
      "AI Chat - Example",
      {
        prompt: selectedExample,
      },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  const handleSessionClick = (chatId) => {
    window.location.href = `/aiChat/${chatId}`;

    logAnalyticEvent(
      "AI Chat - Previous Chat",
      { chatId },
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  // event handler for 'Copy To Clipboard' link click
  const handleCopyToClipboard = () => {
    setShowToast(true);

    logAnalyticEvent(
      "AI Chat - Copy To Clipboard",
      {},
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  // event handler for 'New Chat' button click
  const handleNewChatClick = () => {
    logAnalyticEvent(
      "AI Chat - New Chat",
      {},
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );

    window.location.href = "/aiChat";
  };

  useEffect(() => {
    if (scrollRef && chat.success) {
      setTimeout(() => {
        scrollRef.current.scrollIntoView({
          alignToTop: true,
          behavior: "smooth",
        });
      }, 200);
    }
  }, [chat.success]);

  useEffect(() => {
    if (previousChat?.data?.[0]?.chatHistory) {
      const tempFormattedChat = previousChat?.data?.[0]?.chatHistory.reduce(
        (acc, chat, index, array) => {
          if (index % 2 === 0) {
            // Process pairs of messages
            const userMessage = chat.replace(/\([^)]*\)/g, "");
            const aiMessage = array[index + 1];
            if (aiMessage) {
              // Make sure we have both messages
              acc.push({
                question: userMessage.replace("User message:", "").trim(),
                response: aiMessage.replace("AI message:", "").trim(),
              });
            }
          }
          return acc;
        },
        []
      );
      setFormattedChat(tempFormattedChat);
    }
    if (previousChat?.data?.[0]?.chatIterationsLeft) {
      console.log(
        "chatIterationsLeft :>> ",
        previousChat?.data?.[0]?.chatIterationsLeft
      );
    }
    if (previousChat?.data?.[0]?.chatIterationsLeft === 0) {
      // Let's start a new conversation when we have no interactions left
      setChatId(null);
    }
  }, [previousChat]);

  useEffect(() => {
    if (prevSuccess && previousChat?.data?.length === 0) {
      history.push("/aiChat");
    }
  }, [prevSuccess]);

  return view({
    isAdmin: auth?.mwRole === "ADMIN",
    data: data || [],
    previousData: formattedChat || [],
    username: auth.userName,
    examples,
    previousChats: chats?.data?.toReversed() || [],
    handleExampleClick,
    question,
    setQuestion,
    waitForQuestion,
    handleSubmitNewChat,
    handleSessionClick,
    scrollRef,
    showAll,
    setShowAll,
    // regenerate,
    handleRegenerate,
    isFetching: isLoading,
    isSuccess,

    showToast,
    setShowToast,

    handleCopyToClipboard,
    handleNewChatClick,

    // params,
    // setParams,

    bodyRef,
    chatIterationsLeft: previousChat?.data?.[0]?.chatIterationsLeft,
  });
};
