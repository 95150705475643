import { useQueryClient, useMutation, useQuery } from "react-query";
import axios from "axios";
import { objectToUrlEncoded } from "../../utils/constants";
import { getApiPath } from "../../utils/variables";
import { authHeader } from "../store";

export function getInsight(data) {
  const { objectId, summaryId, summaryDate } = data;
  const params = objectToUrlEncoded({
    objectId,
    summaryId,
    summaryDate,
  });
  return axios.get(`${getApiPath(false)}/aiSummaries/objectSummary?${params}`);
}
export function useGetInsight({ data }) {
  return useQuery([`aiInsight-${data.summaryId}`], () => getInsight(data), {
    refetchOnMount: false,
    cacheTime: 300000,
  });
}

export function getInsights(data) {
  const {
    summaryId,
    objectId,
    startDateLessThan,
    endDateGreaterThan,
    pageNum,
    maxResult,
  } = data;
  const params = objectToUrlEncoded({
    summaryId,
    objectId,
    startDateLessThan,
    endDateGreaterThan,
    pageNum,
    maxResult,
  });

  try {
    return axios.get(
      `${getApiPath(false)}/aiSummaries/objectSummary?${params}`
    );
  } catch (error) {
    // error handling
  }
}
export function useGetInsights({ data, enabled }) {
  return useQuery([`aiInsights-${data?.objectId}`], () => getInsights(data), {
    refetchOnMount: false,
    cacheTime: enabled ? 300000 : 0,
    enabled,
  });
}

export function regenerate(id) {
  return axios.put(
    `${getApiPath()}/aiSummaries/regenerate?id=${id}`,
    {},
    { headers: authHeader() }
  );
}
export function useRegenerate() {
  const queryClient = useQueryClient();
  return useMutation((id) => regenerate(id), {
    onSuccess: (resp) => {
      queryClient.invalidateQueries(`aiInsight-${resp.data.id}`);
    },
  });
}

export function updateSummaryTitle({ id, title }) {
  const params = objectToUrlEncoded({
    id,
  });
  const data = `title=${title}`;

  return axios.put(`${getApiPath()}/aiSummaries/updateTitle?${params}`, data, {
    headers: authHeader({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  });
}
export function useUpdateSummaryTitle() {
  const queryClient = useQueryClient();
  return useMutation(({ id, title }) => updateSummaryTitle({ id, title }), {
    onSuccess: (resp) => {
      queryClient.invalidateQueries(`aiInsight-${resp.data.id}`);
    },
  });
}
